import React, { Component } from 'react'

import { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'
import { ClubData } from '@soccerwatch/common'

import './waitScreenContainer.scss'
import ModeContainer from './ModeContainer'
import WaitScreen from './waitScreen'

type EventListContainerPropsType = RouteComponentProps & {
  clubs: { home: ClubData; guest: ClubData } | undefined
  videoInfo: any
  videoState: { videoIsLive: boolean; videoIsEnd: boolean }
  handleTagMode: (state: undefined | 'remote' | 'live') => void
  score: { home: number; guest: number }
  timerInformation: { timer: string; minutes: number; seconds: number }
}

class EventListContainer extends Component<EventListContainerPropsType> {
  constructor(props: EventListContainerPropsType) {
    super(props)
  }

  render() {
    return (
      <>
        <div>
          <WaitScreen
            clubs={this.props.clubs}
            videoInfo={this.props.videoInfo}
            videoState={this.props.videoState}
            score={this.props.score}
            timerInformation={this.props.timerInformation}
          />
        </div>
        <div className='waitScreen-sidebar'>
          <ModeContainer
            videoState={this.props.videoState}
            handleTagMode={this.props.handleTagMode}
            videoInfo={this.props.videoInfo}
          />
        </div>
      </>
    )
  }
}

export default withRouter(EventListContainer)
