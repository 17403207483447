import React from 'react'
import checkCircleIcon from '../../../img/icons/check_circle.svg'

import './stepper.scss'

type StepperStateType = {
  activeStep: number
  maxAchievedStep: number
}

type StepperPropsType = {
  activeStep?: number
  userMayChooseFreely?: boolean
  config?: Array<{ text: string; isValid?: boolean; icon?: string }> | null
  setActiveStep?: (value: number) => void
  isNew: boolean
}

export default class Stepper extends React.Component<StepperPropsType, StepperStateType> {
  constructor(props: StepperPropsType) {
    super(props)

    this.state = {
      activeStep: this.props.activeStep ?? 0,
      maxAchievedStep:
        this.props.userMayChooseFreely && this.props.config
          ? this.props.config.length - 1
          : this.props.activeStep ?? 0
    }
  }

  componentDidMount() {
    if (this.props.setActiveStep) {
      this.props.setActiveStep(0)
    }
  }

  componentDidUpdate() {
    if (this.props.activeStep !== this.state.activeStep && this.props.activeStep !== undefined) {
      this.updateState(this.props.activeStep)
    }
  }

  updateState = (newActiveStep: number, resetMax = false) => {
    this.setState({
      activeStep: newActiveStep,
      maxAchievedStep: resetMax ? newActiveStep : Math.max(this.state.maxAchievedStep, newActiveStep)
    })
  }

  setActiveStep = (value: number) => {
    const stepMayBeLeft = this.props.config?.[this.state.activeStep].isValid ?? true
    if (value <= this.state.maxAchievedStep + 1 && stepMayBeLeft) {
      this.updateState(value)
      this.props.setActiveStep?.(value)
    }
  }

  stepper() {
    const config = this.props.config
      ? this.props.config
      : [{ text: 'Text 1' }, { text: 'Text 2' }, { text: 'Text 3' }, { text: 'Text 4' }]

    const items = config.map((itm, index) => {
      const lastItem = config[index - 1]
      const isClickable =
        index <= this.state.maxAchievedStep + 1 &&
        index !== this.state.activeStep &&
        // User may not move to current step if prev step has not been completed
        (lastItem?.isValid ?? true)

      const number = index + 1
      return (
        <div
          key={index}
          className={`item ${index === this.state.activeStep ? 'item-active' : ''} ${
            isClickable && 'isClickable'
          }`}
          onClick={() => this.setActiveStep(index)}
        >
          {!this.props.isNew ? (
            <div className={`item-icon`}>
              <img src={itm.icon} />
            </div>
          ) : (
            <div className={`item-icon ${index < this.state.activeStep && 'item-icon-check'}`}>
              <img src={index < this.state.activeStep ? checkCircleIcon : itm.icon} />
            </div>
          )}
          <div className='content'>
            <div className='step'>Step {number}</div>
            <div className='infoText'>{itm.text}</div>
          </div>
        </div>
      )
    })

    return <div className={`stepper ${!this.props.isNew && 'stepper-edit'}`}>{items}</div>
  }

  render() {
    return this.stepper()
  }
}
