import { Container } from 'unstated-typescript'

type BrowserInfoState = {
  objectedCookies: boolean
}

/**
 * from: https://github.com/cheton/is-electron/blob/master/index.js
 **/
function isElectron() {
  // Renderer process
  if (
    typeof window !== 'undefined' &&
    typeof window.process === 'object' &&
    // @ts-ignore
    window.process?.type === 'renderer'
  ) {
    return true
  }

  // Main process
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
    return true
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  return (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.indexOf('Electron') >= 0
  )
}

export class BrowserInfoContainer extends Container<BrowserInfoState> {
  isEdge = false
  isOpera = false
  isFirefox = false
  isChrome = false
  isSafari = false
  constructor() {
    super()

    this.state = {
      objectedCookies: false
    }

    this.isEdge =
      // @ts-ignore
      navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveBlob || !!navigator.msSaveOrOpenBlob)
    // @ts-ignore
    this.isOpera = !!window.opera || navigator.userAgent.indexOf('OPR/') !== -1
    // @ts-ignore
    this.isFirefox =
      navigator.userAgent.toLowerCase().indexOf('firefox') > -1 &&
      'netscape' in window &&
      / rv:/.test(navigator.userAgent)
    this.isChrome =
      // @ts-ignore
      (!this.isOpera && !this.isEdge && !!navigator.webkitGetUserMedia) ||
      isElectron() ||
      navigator.userAgent.toLowerCase().indexOf('chrome/') !== -1

    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    if (this.isSafari && !this.isChrome && navigator.userAgent.indexOf('CriOS') !== -1) {
      this.isSafari = false
      this.isChrome = true
    }
  }
}

const BrowserInfo = new BrowserInfoContainer()
export default BrowserInfo
