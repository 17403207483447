import React from 'react'

import './Checkbox.scss'

type staigeCheckboxPropTypes = {
  label?: string | any
  handleClick?: (value: boolean) => void
  checked?: boolean
}

type staigeCheckboxStateTypes = {
  checked: boolean
}

export default class StaigeCheckbox extends React.Component<
  staigeCheckboxPropTypes,
  staigeCheckboxStateTypes
> {
  constructor(props: staigeCheckboxPropTypes) {
    super(props)

    this.state = {
      checked: false
    }
  }

  componentDidMount(): void {
    if (this.props.checked !== undefined) {
      this.setState({
        checked: this.props.checked
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<staigeCheckboxPropTypes>): void {
    if (this.props.checked !== undefined && prevProps !== undefined) {
      if (this.props.checked !== prevProps.checked) {
        this.setState({
          checked: this.props.checked
        })
      }
    }
  }

  handleCheck = () => {
    const { handleClick } = this.props
    if (handleClick) {
      handleClick(!this.state.checked)
    }
    this.setState({
      checked: !this.state.checked
    })
  }

  render() {
    const { label } = this.props
    const { checked } = this.state

    return (
      <div className={`staige-checkbox container`}>
        <input
          type='checkbox'
          checked={checked}
          onClick={() => this.handleCheck()}
          readOnly={this.props.checked === undefined}
        />
        <label>{label}</label>
      </div>
    )
  }
}
