import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { postSignedUrlGeneric } from '../api/api-mediaupload'

const parseFileContent = async (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async function (readerEvent) {
      const content = readerEvent.target?.result
      if (content) {
        return resolve(content.toString())
      } else {
        console.warn('No file content!')
        return reject(new Error('No file content!'))
      }
    }
  })

const getSignedUrlGeneric = async (body: { folderName: string; fileName: string; contentType: string }) => {
  // Typo in `generic` is intended due to a typo in the route
  return await postSignedUrlGeneric(body)
}

const uploadFileToSignedUrl = async (
  file: File,
  signedUrl: string,
  onProgressCallback: (percentage: number | null, wasSuccessful?: boolean) => void
) => {
  return await new Promise<boolean>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', signedUrl, true)
    xhr.onload = () => {
      const status = xhr.status
      if (status === 200) {
        resolve(true)
        onProgressCallback(null, true)
      } else {
        reject(false)
      }
    }
    xhr.upload.onprogress = function (event) {
      const percent = Math.round((100 * event.loaded) / event.total)
      onProgressCallback(percent)
    }
    xhr.setRequestHeader('Content-Type', file.type)
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
    xhr.send(file)
  })
}

/**
 * Uploads a file (e.g. an image or a video) and returns
 * a URL that points to said file
 *
 * @param file
 * @param onProgress - Callback to be called each time the
 * upload fires a progress event. Receives the progress in
 * percent.
 * @return URL of uploaded file
 */
export const uploadFileToServer = async (
  file: File,
  onProgress?: (percentage: number) => void,
  folderName?: string
): Promise<string> => {
  try {
    const contentType = file.type
    const randomFileName = uuidv4()

    const { signedUrl } = await getSignedUrlGeneric({
      contentType,
      fileName: randomFileName,
      folderName: folderName || 'digitalEnabler'
    })

    const actualUrl = signedUrl.split('?')[0]

    const wasSuccessful = await uploadFileToSignedUrl(file, signedUrl, (percentage) => {
      if (percentage !== null && onProgress) {
        onProgress(percentage)
      }
    })

    if (wasSuccessful) {
      return actualUrl
    } else {
      throw new Error('Some error occurred during file upload')
    }
  } catch (error) {
    throw new Error(`File upload failed: ${error}`)
  }
}

export const useUploadedFileURLDirekt = async (file?: File) => {
  if (file) {
    return await uploadFileToServer(file)
  }
}

export const useUploadedFileURL = (file?: File) => {
  const [url, setURL] = useState('')
  const [progressInPercent, setProgressInPercent] = useState(100)

  useEffect(() => {
    if (file) {
      const uploadHasFinishedRef = { current: false }
      setProgressInPercent(0)

      parseFileContent(file).then((fileContent) => {
        if (!uploadHasFinishedRef.current) {
          setURL(fileContent)
        }
      })

      uploadFileToServer(file, setProgressInPercent).then((fileUrl) => {
        uploadHasFinishedRef.current = true
        setProgressInPercent(100)
        setURL(fileUrl)
      })
    }
  }, [file])

  return { url, progressInPercent }
}

export const useUploadedFileURLClass = async (
  setProgressInPercent: (value: number) => void,
  file?: File,
  folderName?: string
) => {
  if (file) {
    const uploadHasFinishedRef = { current: false }
    let url = null
    setProgressInPercent(0)

    parseFileContent(file).then((fileContent) => {
      if (!uploadHasFinishedRef.current) {
        url = fileContent
      }
    })

    await uploadFileToServer(file, setProgressInPercent, folderName).then((fileUrl) => {
      uploadHasFinishedRef.current = true
      setProgressInPercent(100)
      url = fileUrl
    })

    return url
  }

  return null
}
