import discovery from '@soccerwatch/discovery'
export default discovery.init(process.env.REACT_APP_Environment || 'dev', true)

/**
 * Ensures that container.state.discovery exists (is truthy) by
 * throwing an error otherwise. This also lets TypeScript know
 * that discovery will exist after this function was called (or
 * execution will have been stopped).
 *
 * @param container
 */
export function assertContainerHasInitializedDiscovery(container: {
  state: { discovery?: typeof discovery }
}): asserts container is {
  state: { discovery: typeof discovery }
} {
  if (!container.state.discovery) {
    throw new Error('Discovery has not been initialized')
  }
}
