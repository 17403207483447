import * as Discovery from '@soccerwatch/discovery'
import * as Helper from '../helper/GlobalHelper'
import mobiscroll from '@mobiscroll/react'

import { Tag, AiTagState } from '@soccerwatch/common'

export const postTag = async (body: any, matchId: string, callAs: string) => {
  try {
    return await Helper.apiPost<Tag>(`${Discovery?.API_TAG}/Tag/${matchId}?callAs=${callAs}`, body)
  } catch (error: any) {
    const msg = error.response.status
      ? 'Tag konnte nicht gesetzt werden, Fehler: ' + error.response.status
      : error?.message
    await mobiscroll.toast({
      color: 'error',
      message: msg,
      duration: 8000
    })
    return await Helper.apiPost<Tag>(`${Discovery?.API_TAG}/Tag/${matchId}?callAs=${callAs}`, body)
  }
}

export const postTagById = async (body: any, matchId: string, tagId: string, callAs: string) => {
  return await Helper.apiPost<Tag>(`${Discovery?.API_TAG}/Tag/${matchId}/${tagId}?callAs=${callAs}`, body)
}

export const deleteTagById = async (videoId: string, tagId: string, callAs: string) => {
  return Helper.apiDelete<any>(`${Discovery?.API_TAG}/Tag/${videoId}/${tagId}?callAs=${callAs}`)
}

export const postAiTagConfirmDenied = async (
  body: { state: AiTagState.confirmed | AiTagState.denied | AiTagState.unconfirmed; clubALeft?: boolean },
  matchId: string,
  tagId: string,
  callAs: string
) => {
  return await Helper.apiPost<Tag>(
    `${Discovery?.API_TAG}/AiTagConfirmDenied/${matchId}/${tagId}?callAs=${callAs}`,
    body
  )
}
