import React, { useCallback, useEffect, useState } from 'react'
import i18n from 'i18next'
import moment from 'moment'
import scheduleIcon from '../../../img/icons/schedule.svg'
import localActivityIcon from '../../../img/icons/local_activity.svg'
import plusIcon from '../../../img/icons/plus.svg'
import playIcon from '../../../img/icons/play.svg'
import StaigeButton from '../../../components/styles/Button'
import StaigeBadge from '../../../components/styles/Badge'
import TimeMachine from '../../../helper/TimeHelper'
import paywallIcon from '../../../img/icons/local_activity.svg'
import StaigeDropdown from '../../../components/styles/Dropdown'
import StaigeCheckbox from '../../../components/styles/Checkbox'
import StaigeSwitch from '../../../components/styles/Switch'
import TimeContainer from '../../../components/TimeContainer'

import {
  Eventcalendar,
  localeDe,
  MbscCalendarEvent,
  MbscEventcalendarView,
  MbscEventClickEvent,
  MbscEventCreatedEvent,
  MbscEventUpdatedEvent,
  MbscPageChangeEvent,
  CalendarNav,
  CalendarPrev,
  CalendarNext,
  CalendarToday
} from '@mobiscroll/react5'

import { VideoData } from '@soccerwatch/common'
import { useHistory } from 'react-router'
import { VideoContainer } from '../../../services/videoContainerContracts'
import { checkLanguages } from '../../helper/checkLanguages/checkLanguages'
import { getRecordingStatus, RecordingStatus } from '../../../helper/RecordingStatus'
import { TeamContainer } from '../../../services/teamContainer'
import { ContractContainer } from '../../../services/contractContainer'

import './calender.scss'

let aisw_videolist_tempDate = undefined

const getIsMobile = () => window.innerWidth <= 768

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(getIsMobile())

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile())
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return isMobile
}

const Wait = (timeout: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout)
  })
}
interface CalendarProps {
  videos: Array<VideoData>
  videoContainer: VideoContainer
  contractContainer: ContractContainer
  teamContainer: TeamContainer
  onEventSelect: () => void
  isClubTagger: boolean
  contractBar: boolean
}

export const Calendar: React.FC<CalendarProps> = (props: CalendarProps) => {
  const [changeEvent, setChangeEvent] = useState<
    | undefined
    | {
        id: string // = RowKey
        duration: number
        expectedStartTime: string
        oldData?: unknown
      }
  >(undefined)
  const [myEvents, setEvents] = useState<MbscCalendarEvent[]>([])
  const [date, setDate] = useState<any>(moment().toISOString())

  const [myFilterTreams, setFilterTreams] = useState<string[]>([])
  const [myFilterClubs, setFilterClubs] = useState<string[]>([])
  const [myFilterRec, setFilterRec] = useState<string[]>([])
  const [myFilterPaywall, setFilterPaywall] = useState<boolean>(false)

  const history = useHistory()
  const renderCardInfo = async () => {
    let filter = props.videos.map((video) => {
      const start = moment(video.expectedStartTime)
      const end = moment(video.expectedStartTime).add(video.durationMin, 'minutes')
      const vs = video.clubAName + ' vs ' + video.clubBName

      const important = getRecordingStatus(video) === RecordingStatus.IMPORTED
      let color = undefined

      if (important) {
        // freigabe
        color = 'rgb(203 129 128)'
      } else if (!video.active) {
        // canceld
        color = '#c1c1c1'
      } else if (video.contentType === 'training') {
        // training
        color = '#ffad16'
      }

      const title = vs

      let TeamAAge, TeamBAge
      props.teamContainer.state.teams.map((team) => {
        if (team.RowKey === video.clubATeamId) {
          TeamAAge = team.age
        }
        if (team.RowKey === video.clubBTeamId) {
          TeamBAge = team.age
        }
      })

      TeamAAge = TeamAAge ? TeamAAge : ''
      TeamBAge = TeamBAge ? TeamBAge : ''

      return {
        id: video.RowKey,
        start: start,
        end: end,
        durationMin: video.durationMin,
        privateActive: video.privateActive,
        eventStart: video.expectedStartTime,
        contentType: video.contentType,
        title: title,
        color: color,
        location: video.address,
        original: { location: video.address, paywall: video?.paywall ? video.paywall : false },
        teamAAge: video.teamAAge,
        teamBAge: video.teamBAge,
        teams: TeamAAge + ' vs ' + TeamBAge,
        clubAId: video.clubAId,
        clubBId: video.clubBId,
        editable: false,
        isRec: video.active && video.gridStream !== undefined && video.gridStream !== '',
        scoreA: video.scoreA,
        scoreB: video.scoreB,
        clubAName: video.clubAName,
        clubBName: video.clubBName,
        active: video.active,
        state: video.state,
        contractId: video.contractId
      }
    })

    filter.sort((i, n) => {
      return Number(n.id) - Number(i.id)
    })

    // Filter
    if (myFilterClubs.length > 0) {
      filter = filter.filter((i) => {
        return myFilterClubs.indexOf(i.clubAName) !== -1 || myFilterClubs.indexOf(i.clubBName) !== -1
      })
    }

    if (myFilterTreams.length > 0) {
      filter = filter.filter((i: any) => {
        return myFilterTreams.indexOf(i.teamAAge) !== -1 || myFilterTreams.indexOf(i.teamBAge) !== -1
      })
    }

    if (myFilterRec.length > 0) {
      filter = filter.filter((i: any) => {
        if (myFilterRec[0] === 'Event') return true
        return myFilterRec[0].toLocaleLowerCase() === i.contentType
      })
    }

    if (myFilterPaywall) {
      filter = filter.filter((i: any) => {
        return myFilterPaywall === i.original.waywall
      })
    }

    setEvents(filter)
  }

  useEffect(() => {
    renderCardInfo()
  }, [
    props.videos,
    JSON.stringify(myFilterClubs),
    JSON.stringify(myFilterTreams),
    JSON.stringify(myFilterRec),
    myFilterPaywall
  ])

  const onEventClick = useCallback(
    (event: MbscEventClickEvent) => {
      if (!changeEvent) {
        const eventStart = new Date(event.event.eventStart)
        const now = new Date().getTime()
        const beforeRec = new TimeMachine(eventStart).subtract(15, 'min').getTime()
        const isEventBeginn = beforeRec > now

        if (isEventBeginn) {
          history.push({
            pathname: '/recordingplan/edit/' + event.event.id,
            search: '',
            state: '?date=' + moment(date).toISOString() + '&view=' + view
          })
        } else {
          history.push({
            pathname: '/recordingplan/video/' + event.event.id,
            search: '',
            state: '?date=' + moment(date).toISOString() + '&view=' + view
          })
        }
      }
      event.domEvent.stopPropagation()
      return
    },
    [changeEvent, myEvents, date]
  )

  const onEventUpdated = useCallback(
    (event: MbscEventUpdatedEvent) => {
      const start = moment(event.event.start)
      const end = moment(event.event.end)
      const duration = moment.duration(end.diff(start)).asMinutes()

      if (event.event.id === undefined) {
        throw new Error('Event ID is undefined')
      }

      setChangeEvent({
        id: event.event.id.toString(),
        duration: duration,
        expectedStartTime: start.toISOString(),
        oldData: event.oldEvent
      })
      const newEvents = myEvents.map((obj) => {
        if (obj.id === 'new') {
          return { ...event.event, editable: true, color: '#fff' }
        } else if (obj.id === event.event.id) {
          return { ...event.event, editable: true, color: '#fff' }
        }
        return { ...obj, editable: false }
      })
      newEvents.sort((i, n) => {
        return Number(n.id) - Number(i.id)
      })
      setEvents(newEvents)
      return
    },
    [changeEvent, myEvents]
  )

  const onEventCreated = useCallback(
    (event: MbscEventCreatedEvent) => {
      let start = moment(event.event.start)
      let end = moment(event.event.end)
      const duration = moment.duration(end.diff(start)).asMinutes()

      if (moment().isAfter(event.event.start)) {
        // Recording must not be created in the past - when setting an event, it will be moved 30min after the current time.
        start = moment().add(20, 'minutes')
        end = moment().add(60, 'minutes')
        event.event.start = moment().add(30, 'minutes')
        event.event.end = moment().add(90, 'minutes')
      }

      if (event.event.id === undefined) {
        throw new Error('Event ID is undefined')
      }

      setChangeEvent({
        id: event.event.id.toString(),
        duration: duration,
        expectedStartTime: start.toISOString()
      })

      const newEvents = myEvents.map((obj) => {
        return { ...obj, editable: false }
      })
      const newEvent = event.event
      newEvent.id = 'new'
      newEvents.push({ ...newEvent, editable: true, color: '#fff' })
      newEvents.sort((i, n) => {
        return Number(n.id) - Number(i.id)
      })
      setEvents(newEvents)
      return
    },
    [changeEvent, myEvents]
  )

  const [view, setView] = React.useState('day')
  const [calView, setCalView] = React.useState<MbscEventcalendarView>({
    schedule: { type: 'day', allDay: false }
  })

  const changeView = async (event: any) => {
    let view = {}
    switch (event.target.value) {
      case 'month':
        if (getIsMobile()) {
          view = {
            calendar: { type: 'month' },
            agenda: { type: 'month' }
          }
        } else {
          view = {
            calendar: { type: 'month', labels: 'all' }
          }
        }
        break
      case 'week':
        view = {
          calendar: { type: 'week' },
          agenda: { type: 'week' }
        }
        break
      case 'day':
        view = {
          schedule: { type: 'day', allDay: false }
        }
        break
    }

    setView(event.target.value)
    setCalView(view)
  }

  useEffect(() => {
    const event = { target: { value: 'month' } }
    changeView(event)
  }, [useIsMobile()])

  const onPageChange = async (event: MbscPageChangeEvent) => {
    const lastChangeTemp = new Date().getTime()
    aisw_videolist_tempDate = lastChangeTemp
    await Wait(500)
    if (lastChangeTemp !== aisw_videolist_tempDate) {
      return
    }
    if (view === 'month') {
      await props.videoContainer.setVideoListByTimestamp(moment(event.month).toString(), true)
    } else {
      await props.videoContainer.setVideoListByTimestamp(event.firstDay.toString())
    }
  }

  const setUrlParameter = (event: any) => {
    history.push({
      search: '?date=' + moment(event.date).toISOString() + '&view=' + view
    })
    setDate(event.date)
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const date = queryParams.get('date')
    const view = queryParams.get('view')

    if (date) {
      setDate(moment(date))
    } else {
      setDate(moment())
    }

    if (view) {
      const event = { target: { value: view } }
      changeView(event)
    } else {
      const event = { target: { value: 'month' } }
      changeView(event)
    }
  }, [])

  useEffect(() => {
    history.listen(() => {
      const queryParams = new URLSearchParams(window.location.search)
      const date = queryParams.get('date')
      if (date) {
        setDate(moment(date))
      } else {
        setDate(moment())
      }
    })
  }, [history])

  const handleDeActivate = async (id: string, contractId: string, active: boolean) => {
    const currentContract = props.contractContainer.getCurrentContract()
    const state = active ? { state: 'done', active: false } : { state: 'created', active: true }
    const modifiedVideo = {
      ...state,
      RowKey: id,
      contractId: contractId || currentContract?.RowKey
    }
    //@ts-ignore
    await props.videoContainer.updateVideo(modifiedVideo)
    await renderCardInfo()
  }

  const customRenderHeader = () => {
    const teams: Array<string> = []
    const clubs: Array<string> = []

    props.videoContainer.getVideosForCurrentContract().map((video) => {
      if (clubs.indexOf(video.clubAName) === -1) {
        clubs.push(video.clubAName)
      }
      if (clubs.indexOf(video.clubBName) === -1) {
        clubs.push(video.clubBName)
      }

      if (video.teamAAge && teams.indexOf(video.teamAAge) === -1) {
        teams.push(video.teamAAge)
      }
      if (video.teamBAge && teams.indexOf(video.teamBAge) === -1) {
        teams.push(video.teamBAge)
      }
    })

    const divClubs = clubs.map((club, index) => {
      return <div key={index}>{club}</div>
    })
    const divTeams = teams.map((club, index) => {
      return <div key={index}>{club}</div>
    })

    return (
      <React.Fragment>
        <div style={{ width: '100%' }}>
          <div className='calendar-header'>
            <div className='header-leftBar'>
              <CalendarNav />
            </div>
            <div className='header-buttonBar'>
              <div className='buttonBar-days'>
                <CalendarPrev className='cal-header-prev' />
                <CalendarToday />
                <CalendarNext className='cal-header-next' />
              </div>
            </div>
          </div>

          <div className='calendar-filterBar'>
            <StaigeDropdown
              label={i18n.t('calendar.filter.allClubs')}
              style='ghost'
              size={'small'}
              handleOptions={(value) => {
                const newV = [...value]
                setFilterClubs(newV)
              }}
              multiple
            >
              {divClubs}
            </StaigeDropdown>

            <StaigeDropdown
              label={i18n.t('calendar.filter.allTeams')}
              style='ghost'
              size={'small'}
              multiple
              handleOptions={(value) => {
                const newV = [...value]
                setFilterTreams(newV)
              }}
            >
              {divTeams}
            </StaigeDropdown>

            <StaigeDropdown
              label={i18n.t('calendar.filter.allEvents')}
              style='ghost'
              size={'small'}
              handleOptions={(value) => {
                const newV = [...value]
                setFilterRec(newV)
              }}
            >
              <div>Event</div>
              <div>Training</div>
            </StaigeDropdown>

            <StaigeCheckbox label='Paywall' handleClick={(value) => setFilterPaywall(value)} />
          </div>

          {props.videoContainer.state.loadingData || props.contractContainer.state.loadingData ? (
            <div className='progressbar'>
              <div />
            </div>
          ) : null}
        </div>
      </React.Fragment>
    )
  }

  const customRenderScheduleEventContent = (data: any) => {
    return (
      <>
        <div
          className='my-title'
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '12px'
          }}
        >
          <div>{data.title}</div>
          <div style={{ fontSize: '10px' }}>{data.original.teams}</div>
        </div>
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {data.original.location}
        </div>
        {data?.original?.original?.paywall !== undefined ? (
          <div className='my-custom-field'>
            {data.original.original.paywall ? 'Paywall Video' : undefined}
          </div>
        ) : undefined}
      </>
    )
  }

  const dateBetweenCheck = (from: any, to: any, check: any) => {
    let fDate, lDate, cDate
    fDate = Date.parse(from)
    lDate = Date.parse(to)
    cDate = Date.parse(check)

    if (cDate <= lDate && cDate >= fDate) {
      return true
    }
    return false
  }

  const customRenderLabel = (data: any) => {
    const imgErr = function addDefaultSrc(ev: any) {
      ev.target.src = 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
    }

    const eventStart = new Date(data.original.eventStart).getTime()
    const now = new Date().getTime()
    const isFuture = eventStart > now
    const isTraining = data.original.contentType === 'training'
    const isActive = data.original.active
    let color = {
      colorBar: isActive ? '#26BD83' : 'rgba(246, 246, 246, 0.16)',
      event: isTraining ? '#F6F6F6' : '#672CBA'
    }

    const isLive = dateBetweenCheck(
      new Date(data.original.eventStart),
      new Date(eventStart + data.original.durationMin * 60000),
      new Date()
    )

    return (
      <div className='calendar-label'>
        {isFuture ? <div className='label-colorBar' style={{ background: color.colorBar }} /> : null}
        <div className={`lable-event ${!isFuture && 'lable-eventPast'}`} style={{ background: color.event }}>
          <div className='event-teams'>
            <div className='teams-team' id={data.original.id + '-a'}>
              <img
                onError={imgErr}
                src={
                  'https://storage.googleapis.com/sw-sc-de-assets/clubLogo/club-' +
                  data.original.clubAId +
                  '.png'
                }
              />
            </div>
            <div className='teams-team' id={data.original.id + '-b'}>
              <img
                onError={imgErr}
                src={
                  'https://storage.googleapis.com/sw-sc-de-assets/clubLogo/club-' +
                  data.original.clubBId +
                  '.png'
                }
              />
            </div>
          </div>
          <div className='event-info'>
            <div className='info-age'>{data.original.teamAAge}</div>
            <div className='info-time'>{data.start}</div>
            {isLive && isActive ? <div className='info-live'></div> : null}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='recordingPlan-calendar'>
        <Eventcalendar
          selectedDate={date}
          onSelectedDateChange={setUrlParameter}
          theme='ios'
          themeVariant='dark'
          locale={checkLanguages() === 'de' ? localeDe : undefined}
          data={myEvents}
          view={calView}
          dragToMove={false}
          dragToResize={false}
          renderHeader={customRenderHeader}
          renderScheduleEventContent={customRenderScheduleEventContent}
          renderLabel={customRenderLabel}
          onEventCreated={onEventCreated}
          onEventUpdated={onEventUpdated}
          onEventClick={onEventClick}
          onPageChange={onPageChange}
          onEventUpdate={(event) => {
            if (moment().isBefore(event.event.start)) {
              // 10 minutes before start of recording not changeable
              return moment().isBefore(moment(event.oldEvent.start).subtract(10, 'minutes'))
            } else {
              return false
            }
          }}
        />

        <div className='calendar-legende'>
          <div className='legende-item'>
            <div className='item-dot' />
            <div className='item-title'>{i18n.t('calendar.legende.match')}</div>
          </div>
          <div className='legende-item legende-item-neutral'>
            <div className='item-dot' />
            <div className='item-title'>{i18n.t('calendar.legende.training')}</div>
          </div>
          <div className='legende-item legende-item-green'>
            <div className='item-dot' />
            <div className='item-title'>{i18n.t('calendar.legende.eventRec')}</div>
          </div>
        </div>
      </div>
      <div className='recordingPlan-sideContent'>
        <TimeContainer
          videoContainer={props.videoContainer}
          contractContainer={props.contractContainer}
          selectedDate={date}
        />
        <div className='sideContent-events'>
          <div className='events-new'>
            <StaigeButton
              label={i18n.t('calendar.createNewEvent')}
              style='primary'
              icon={plusIcon}
              iconMargin='0 0 0 -4px'
              onClick={() => {
                history.push({
                  pathname: '/recordingplan/new',
                  search: '',
                  state: '?date=' + moment(date).toISOString() + '&view=' + view
                })
              }}
            />
          </div>
          <Eventcalendar
            selectedDate={date}
            onSelectedDateChange={setUrlParameter}
            theme='ios'
            themeVariant='dark'
            locale={checkLanguages() === 'de' ? localeDe : undefined}
            data={myEvents}
            view={{ agenda: { type: 'month' } }}
            dragToMove={false}
            dragToResize={false}
            renderEvent={(data: any) => {
              const eventStart = new Date(data.original.eventStart)
              const now = new Date().getTime()
              const afterRec = new TimeMachine(eventStart).add(data.original.durationMin, 'min').getTime()
              const beforeRec = new TimeMachine(eventStart).subtract(15, 'min').getTime()
              const isFuture = afterRec > now
              const isEventBeginn = beforeRec > now
              const imgErr = function addDefaultSrc(ev: any) {
                ev.target.src = 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
              }

              const currentContract = props.contractContainer.getCurrentContract()
              const currentContractMatchNotAllow = currentContract?.RowKey !== data.original.contractId
              const contractNotSet =
                (currentContractMatchNotAllow && data.original.contractId === '') ||
                data.original.contractId === undefined

              return (
                <div
                  className='events-event'
                  style={!isFuture ? { opacity: 0.32 } : {}}
                  onClick={() => {
                    if (isEventBeginn) {
                      history.push({
                        pathname: '/recordingplan/edit/' + data.original.id,
                        search: '',
                        state: '?date=' + moment(date).toISOString() + '&view=' + view
                      })
                    } else {
                      history.push({
                        pathname: '/recordingplan/video/' + data.original.id,
                        search: '',
                        state: '?date=' + moment(date).toISOString() + '&view=' + view
                      })
                    }
                  }}
                >
                  <div className='event-top'>
                    <div className='top-left'>
                      <div className='left-time'>
                        <img src={scheduleIcon} />
                        <div className='time-text'>
                          {data.start}-{data.end}
                        </div>
                        {data.original.original.paywall ? (
                          <img src={localActivityIcon} style={{ marginLeft: 8 }} />
                        ) : null}
                      </div>
                      {data.original.paywall && (
                        <div className='left-paywall'>
                          <img src={paywallIcon} />
                        </div>
                      )}
                    </div>
                    <div className='top-right'>
                      {!isFuture ? (
                        <>
                          <img src={playIcon} />
                          <div className='right-text'>
                            {data.original.isRec
                              ? i18n.t('calendar.event.rec')
                              : i18n.t('calendar.event.notRec')}
                          </div>
                        </>
                      ) : null}

                      {isEventBeginn ? (
                        <StaigeSwitch
                          disabled={currentContractMatchNotAllow && !contractNotSet}
                          checked={data.original.active}
                          size='small'
                          stopPropagation
                          onClick={() =>
                            handleDeActivate(data.original.id, data.original.contractId, data.original.active)
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className='event-main'>
                    <div className='main-teams'>
                      <div className='teams-team' id={data.original.id + '-a'}>
                        <img
                          onError={imgErr}
                          src={
                            'https://storage.googleapis.com/sw-sc-de-assets/clubLogo/club-' +
                            data.original.clubAId +
                            '.png'
                          }
                        />
                      </div>
                      <div className='teams-team' id={data.original.id + '-b'}>
                        <img
                          onError={imgErr}
                          src={
                            'https://storage.googleapis.com/sw-sc-de-assets/clubLogo/club-' +
                            data.original.clubBId +
                            '.png'
                          }
                        />
                      </div>
                    </div>
                    {isFuture && (
                      <div className='main-title'>
                        {data.original.clubAName} vs {data.original.clubBName}{' '}
                      </div>
                    )}
                    {!isFuture && (
                      <div className='main-title'>
                        {data.original.clubAName} {data.original.scoreA || 0}-{data.original.scoreB || 0}{' '}
                        {data.original.clubBName}
                      </div>
                    )}
                    <div className='main-badges'>
                      <StaigeBadge
                        active={data.original.contentType === 'broadcast'}
                        color='violet'
                        title={'event'}
                      />
                      <StaigeBadge
                        active={data.original.contentType === 'training'}
                        color='white'
                        title={'training'}
                      />
                      <StaigeBadge
                        active={data.original.contentType === 'test'}
                        color='white'
                        title={'training'}
                      />
                      <StaigeBadge
                        active={data.original.teamAAge !== '' && data.original.teamAAge !== undefined}
                        title={data.original.teamAAge}
                      />
                    </div>
                  </div>
                </div>
              )
            }}
          />
        </div>
      </div>
    </>
  )
}
