import { MediaResourceType } from '@soccerwatch/common'

export default {
  general: {
    save: 'Save',
    cancel: 'Cancel',
    back: 'Back',
    continue: 'Continue',
    create: 'Create',
    description: 'Description',
    title: 'Title',
    delete: 'Delete',
    edit: 'Edit',
    yes: 'Yes',
    no: 'No',
    new: 'New',
    name: 'Name',
    done: 'Done',
    revert: 'Revert',
    confirm: 'Confirm',
    refresh: 'Refresh',
    close: 'Close',
    search: 'Search',
    mail: 'E-Mail',
    private: 'Private',
    public: 'Public',
    unknown: 'Unknown',
    adTypes: {
      [MediaResourceType.IMAGE]: 'Looping ticker',
      [MediaResourceType.STATIC]: 'Fixed location',
      [MediaResourceType.VIDEO]: 'Video',
      [MediaResourceType.LOTTY]: 'Lotty'
    },
    or: 'or',
    set: 'set',
    contract: 'Contract',
    day: {
      mo: 'Monday',
      tu: 'Tuesday',
      we: 'Wednesday',
      th: 'Thursday',
      fr: 'Friday',
      sa: 'Saturday',
      su: 'Sunday'
    }
  },
  sidebar: {
    calendar: 'Calendar',
    sponsors: 'Sponsors',
    cameras: 'Cameras',
    administration: 'Administration',
    staigeApps: 'Staige Apps',
    help: 'Help',
    faq: 'FAQ'
  },
  fieldMask: {
    headerTitleLoad: 'Back to the camera',
    headerTitleNew: 'Create new fieldmask',
    headerTitleEdit: 'Edit fieldmask',
    inputFieldMaskTitle: 'Name of the fieldmask',
    fieldmaskContainerTitle: 'Set up fieldmask',
    lenses: 'Lenses',
    beforCorner: 'Previous corner',
    nextCorner: 'Next corner',
    interactiveText:
      'Please mark the selected corner on the player. To do this, simply click on the spot where the corner of the playing field is located.'
  },
  homePosition: {
    inputHomePositionTitle: 'Name of the home position',
    infoText: 'Get a live view and setup the home position',
    headerTitleEdit: 'Edit home position',
    containerTitle: 'Setup home position',
    subtext:
      'Please mark the position on the corresponding lens to which the camera should align itself as soon as the Ai is deactivated.',
    fov: 'Field of View'
  },
  camera: {
    headerTitleLoad: 'Camera loading -',
    headerTitle: 'Camera',
    err: 'Error',
    errText: 'No data found',
    fieldmask: 'Fieldmasks',
    addFieldmaskBtn: 'Create fieldmask',
    fieldmaskDefault: 'Default',
    fieldmaskOptinal: 'Optional',
    homePosition: 'Home Position',
    cameraInfoTitle: 'Camera',
    errorFree: 'error-free',
    check: 'check',
    camQuest: 'To optimize the camera, please contact Club Support',
    deleteModal: {
      title: 'Delete the field mask',
      text: 'Are you sure you want to delete the field mask?'
    }
  },
  cameraList: {
    headerTitle: 'Cameras',
    btnAddCam: 'Add camera',
    noAllowText: 'You have not yet set up any cameras.',
    noAllowText2: 'Add one to get started!'
  },
  sponsors: {
    headerTitle: 'Sponsors',
    newAdTitle: 'Create advertising',
    editAdTitle: 'Edit advertising',
    createButton: 'Create advertising',
    adCreate: 'Created on',
    badges: {
      clubBased: 'Club based',
      gameBased: 'Game based'
    },
    activate: 'Activate',
    mainSettings: {
      title: 'Banner',
      bannerUpload: 'Banner Upload',
      infoText01: 'Recommended format: 800x90px',
      infoText02: 'File formats:',
      checkBoxText: 'Add description',
      commentPlaceholder: 'Winterrabatt...'
    },
    prevVideo: {
      title: 'Banner ad preview',
      loopInfoText: 'The banner moves like a ticker throughout the event.',
      fixedInfoText: 'The banner remains in place during the entire event.'
    },
    basedType: {
      title: 'Advertising assignment',
      gameBased: 'Game based',
      gameBasedInfo: 'Advertising is available at all events of the selected clubs.',
      clubBased: 'Club based',
      clubBasedInfo: 'The advertisement will be played at all events of the selected clubs.'
    },
    deleteModal: {
      title: 'Deleting the sponsor',
      text: 'Are you sure you want to delete the sponsor?'
    }
  },
  calendar: {
    pageTitle: 'Calendar',
    filter: {
      allClubs: 'All Clubs',
      allTeams: 'All Teams',
      allEvents: 'All Events'
    },
    createNewEvent: 'New Event',
    event: {
      rec: 'Recorded',
      notRec: 'Not recorded'
    },
    legende: {
      match: 'Match',
      training: 'Training',
      eventRec: 'Event is recorded'
    }
  },
  matchEditor: {
    stepCamera: {
      stepperTitel: 'Camera',
      title: 'Select camera',
      cameraList: {
        title: 'Camera'
      },
      fieldmask: {
        title: 'Fieldmask',
        createNewFieldmask: 'Create new field mask'
      }
    },
    stepEvent: {
      stepperTitel: 'Event',
      title: 'Event configuration',
      date: {
        title: 'When does the event take place?'
      },
      settings: {
        title: 'What kind of event is this?',
        eventType: 'Event type',
        duration: 'Recording length in minutes',
        durationInfo: 'How long does the game last, including half-time and any stoppage time?',
        visibilityEvent: {
          title: 'Visibilität des Events',
          tooltipPublic1: 'Öffentlich: Stream wird auf ',
          tooltipPublic2: ' angekündigt und gestreamt.',
          tooltipPrivat1: 'Privat: Der Stream auf ',
          tooltipPrivat2: ' kann nur über einen privaten Link aufgerufen werden.'
        },
        paywall: {
          title: 'Paywall',
          agb1: 'I hereby confirm that',
          agb2: 'General Terms and Conditions'
        },
        cameraMode: {
          title: 'What is the best way to stream the event?'
        },
        audio: 'Allow audio comments',
        audioTooltip:
          'If audio commentaries are permitted, livestreams and highlights can be commented on by activated users.',
        scoreboard: 'Allow Scoreboard',
        scoreboardTooltip: 'The score is displayed.'
      }
    },
    stepTeams: {
      stepperTitel: 'Teams',
      title: 'Teams lineup'
    },
    stepAds: {
      stepperTitel: 'Advertising',
      title: 'Assign advertising',
      adsWrapper: {
        title: 'Club-based advertising',
        tooltip:
          'This advertisement will be played at all matches of this team. This setting can be changed on the sponsor page.'
      },
      createNewAd: 'Create new sponsor'
    },
    newEvent: 'New Event',
    changeModal: {
      confirmText: 'Leave event',
      title: 'Unsaved changes',
      text: 'Are you sure you want to leave the event? The changes you have made will not be saved.'
    }
  },

  filteredSelect: {
    pleaseSelect: 'Please select...',
    noMatch: 'Create new Entry...',
    noneChoice: 'None'
  },
  notFound: {
    title: '404 - Not Found',
    message: 'Could not find {{nameOfItem}}'
  },
  pageNav: {
    control: 'Control',
    planer: 'Planer',
    shop: 'Shop'
  },
  home: {
    title: 'Menu',
    buttonTitle: {
      cameracontrol: 'Cameracontrol',
      recordingplan: 'Recording',
      administration: 'Administration',
      cameramonitor: 'Camera',
      shop: 'Store',
      logout: 'Account',
      adManagement: 'Advertising'
    }
  },
  login: {
    button: {
      btnLogin: 'Log in',
      btnLogout: 'Log out',
      btnSignin: 'Register',
      signup: 'No soccerwatch.tv account ?',
      login: 'You have a soccerwatch.tv account ?',
      pwReset: 'Password forgotten?',
      loginWithProvider: 'Log in with {{authProviderName}}'
    },
    toast: {
      logoutSuccessful: 'Logged out successfully!',
      loginSuccess: 'Login successful',
      loginErr: 'Login failed'
    },
    input: {
      email: 'Email',
      password: 'Password'
    }
  },
  editPaywall: {
    title: 'Edit paywall',
    name: 'Name',
    standard: 'Standard',
    description: 'Description',
    price: 'Price',
    currency: 'Currency',
    paywallNotFound: 'Paywall could not be found.'
  },
  editUser: {
    title: 'Edit user',
    username: 'Username',
    name: 'Name',
    role: 'Role',
    isActivated: 'Activated',
    userNotFound: 'User could not be found.',
    roles: {
      admin: 'Admin'
    }
  },
  editAdvertisement: {
    isValidErrMsg: 'Please check the data again',
    title: 'Edit sponsor',
    adsAreLoading: 'Loading sponsors...',
    noAdsYet: 'You have not created any sponsors yet.',
    generalInformation: 'General',
    colors: 'Color picker',
    enabledFor: 'Enabled for',
    instreamOptions: 'Customize Lotty sponsor',
    videoOptions: 'Customize pre-roll sponsor',
    imageOptions: 'Customize banner sponsor',
    video: 'Preroll video',
    lotty: 'Lotty sponsor',
    image: 'Banner Upload',
    imageTooltip:
      'Format note: 800 x 90 px is recommended, but any other format can be uploaded.\n\nFile formats: .jpeg, .png and .gif, 65 kb max.',
    titles: {
      clubs: 'Clubs',
      adManagement: 'Sponsors',
      paywallManagement: 'Paywall management',
      userManagement: 'User management',
      normalPreview: 'Preview of the actual ad',
      previewInPlayer: 'Preview within a video',
      bannerTyp: 'TYPE OF BANNER DISPLAY',
      tooltip: 'Loop ticker: banner is animated in a ticker. Fixed position: banner is fixed.'
    },
    labels: {
      name: 'Name of the sponsor',
      loop: 'Looping Ticker',
      fixed: 'Fixed Location',
      comment: 'Comment (optional)',
      startDate: 'Show from',
      endDate: 'Show until',
      owner: 'Ownder',
      isActive: 'Active',
      isActiveTooltip:
        'Active: When creating a game, the banner can be selected and will be played afterwards.\n\nInactive: the banner can no longer be selected',
      isActiveLotty: 'Animated',
      isMainSponsor: 'Main sponsor',
      backgroundColor: 'Background color',
      backgroundLottyColor: 'Background color',
      foregroundColor: 'Foreground color',
      instreamTextColor: 'Font color',
      instreamButtonColor: 'Button background color',
      instreamButtonTextColor: 'Button font color',
      cityIDs: 'Enabled cities',
      clubIDs: 'Enabled clubs',
      matchIDs: 'Enabled games',
      cupIDs: 'Enabled cups',
      regionIDs: 'Enabled regions',
      link: 'Link to website (sponsor)',
      hasGlobalPreroll: 'Global',
      validFrom: 'Valid from',
      validUntil: 'Valid until',
      instreamTextA: 'First line',
      instreamTextB: 'Second line',
      instreamButtonText: 'Button text',
      instreamLogo: `Advertiser's logo`,
      instreamBackgroundImage: 'Background image',
      imageDimensionErrorMessage: 'Please choose a {{width}} x {{height}} px image'
    },
    errorMessages: {
      textTooLong: '{{textName}} may have no more than {{maxLength}} characters, including whitespace!',
      wrongVideoDimensions: 'Please make sure your video has {{width}} x {{height}} px',
      wrongVideoDuration: 'The video should last from {{min}} up to {{max}} seconds.',
      invalidVideoFile: 'Please upload a valid video!',
      fileTooLarge: 'Your file may not be larger than {{maxFileSize}}',
      linkInvalid: 'Please enter a valid HTTP or HTTPS link',
      nameInvalid: 'Please enter a valid name',
      invalidDate: 'Please enter a valid date'
    }
  },
  recording: {
    stopRecTitle: 'Stop recording',
    stopRecModal: {
      confirmText: 'Stop recording',
      title: 'Stop recording',
      text: 'Stopping the recording means that the live broadcast of the event will be stopped. Do you really want to continue?'
    },
    open: 'open',
    embedLink: 'Embed link',
    embedCopy: 'Code Kopieren',
    embedCopyToast: 'Code on the clipboard',
    staigeLink: 'StaigeTV',
    panorama: 'Panorama',
    openPanorama: 'open',
    tagtool: 'Tag Tool',
    free: 'Free',
    freecard: 'Free tickets',
    freecards: 'Show free tickets',
    ticket: 'Ticket',
    paywall: 'Paywall',
    informationAboutImportedVideo:
      'This video was imported automatically and will not be broadcast publicly. Please' +
      ' confirm the event to publish it or decline it to ensure that it will not be recorded.',
    informationOtherContract:
      'This recording was created by another contract, so you cannot edit this recording.',
    informationNotSetContract:
      'This recording was not assigned to a contract, as soon as you activate / deactivate / edit the recording, you thereby claim this recording.',
    confirmFromImporter: 'Confirm event',
    declineFromImporter: 'Decline event',
    cancelVideo: 'Cancel event',
    revokeCancellation: 'Broadcast',
    activate: 'Activate',
    deactivate: 'Deactivate',
    instantStop: 'Stop recording immediately',
    instantStopMessage: 'The recording is finished, this can take up to 1 min.',
    videoWillNotBeRecordedOrStreamed: 'Video will not be broadcasted',
    videoWillBeRecordedAndStreamed: 'Video will be broadcasted',
    embedCode: {
      title: 'Embed Code',
      diebarOption: 'With sidebar'
    }
  },
  stepper: {
    stepText: 'Step',
    ofText: 'of'
  },
  recordingPlan: {
    headerTitleNew: 'Back to the calendar',
    headerTitleEdit: 'Edit Broadcast',
    classes: {
      first: '1st Team',
      second: '2nd Team',
      third: '3rd Team',
      four: '4rd Team',
      five: '5rd Team',
      a: 'A-Juniors',
      b: 'B-Juniors',
      c: 'C-Juniors',
      d: 'D-Juniors',
      e: 'E-Juniors',
      f: 'F-Juniors',
      g: 'G-Juniors / Bambini',
      senior30: 'Seniors 30+',
      senior40: 'Seniors 40+',
      senior50: 'Seniors 50+'
    },
    gender: {
      men: 'Men',
      women: 'Women',
      mixed: 'Mixed'
    },
    details: {
      stepTitle: 'Recording',
      title: 'Create recording',
      cameraInputText: 'Camera',
      fieldInputText: 'Fieldmask',
      fieldInputTooltip:
        'Different field masks can be created under Camera > Field Mask, e.g. for different sports or different field dimensions.',
      contractInputText: 'Contract',
      contractInputTooltip:
        'Choose the Contract from which to deduct the needed streaming minutes of this match. Can only be set on new Recordings.',
      inputDate: {
        title: 'Date',
        placeholder: 'Select date...'
      },
      inputTime: {
        title: 'Start time',
        placeholder: 'Select time...',
        tooltip:
          'Note: The exposure starts exactly at the set time. If the camera is to be switched on earlier (warm-up program, etc.), please take this into account.'
      },
      inputEnd: {
        title: 'End'
      },
      // TODO: Change this one: It's quite hard to understand and not realy valid english
      minutesBeforText: 'Drag slider to set how many minutes before the start time to begin broadcasting',
      durationText: 'Broadcast length',
      durationTooltip:
        'How long does the game last, including half-time and, if applicable, injury time? Are there any special features such as extra time or penalty shootouts?',
      durationValueText: '{{duration}} MIN',
      minutesBeforValueText: '{{minutesBefor}} minutes befor ({{clock}})',
      infoTextDisabledTimeChange: 'Start time no longer changeable 10 minutes before start of recording'
    },
    advanced: {
      stepTitle: 'Stream',
      title: 'Event',
      durationTooltip:
        'Public: Stream will be announced and streamed on staige.tv. Private: Stream on staige.tv can only be accessed via a private link.',
      duration: {
        private: 'Private',
        public: 'Public',
        special: 'Special'
      },
      cameraControl: 'Camera work',
      cameraWorkSwitches: {
        cameraWork: 'Guided stream',
        cameraVRWork: 'VR Stream',
        cameraFullWork: 'Full field',
        cameraHorseWork: 'Horses',
        cameraScoutingWork: 'Scouting Feed'
      },
      streamTypes: {
        autoStream: {
          title: 'Camera Man',
          description:
            'AI stream, camera focus follows the game action automatically (standard for livestreams).'
        },
        trainingStream: {
          title: 'VR Stream',
          description:
            'VR stream, manual camera control. User defines the camera focus (standard for training).'
        },
        analyseStream: {
          title: 'Scouting',
          description: 'Zoomed out AI stream, camera focus automatically follows the action when zoomed out.'
        },
        vrStream: {
          title: '180° Full Field',
          description: 'Permanent view of the entire playing field.'
        }
      },
      activeLinkTitle: 'Recording Display',
      activeLinkSwitches: {
        coachingtool: 'Coachingtool',
        coachingtoolDesc: 'only available in the coaching tool',
        platform: 'Platform',
        platformDesc: 'secret link'
      },
      audioTitle: 'Audio Commentators',
      audioTooltip: 'Sind Audiokommentare erlaubt, können Livestream und Highlights  kommentiert werden.',
      audioSwitches: {
        disabledAudioComments: 'Commentator not allow',
        audioCommentsEnabled: 'Audio-comments allowed',
        audioCommentsDisabled: 'Audio-comments not allowed'
      },
      scoreboardTooltip: '',
      scoreboardSwitches: {
        scoreboardEnabled: 'Show scoreboard',
        scoreboardDisabled: 'Do not show scoreboard'
      },
      connection: {
        title: 'Connection',
        errMsg: `the string must start with 'rtmp://', 'gs://', 's3://', 'srt://'`,
        bufferSecsTitle: 'Buffer sec.'
      }
    },
    teams: {
      stepTitle: 'Teams',
      title: 'Team selection',
      homeSelectTitle: 'Home Club',
      guestSelectTitle: 'Guest Club',
      teamSelect: {
        filterEmptyText: 'data will be loaded',
        filterPlaceholderText: 'Search for team...'
      },
      clubHomeInfo: 'Select a home team',
      clubHomeInfoSet: 'Home Team set',
      clubGuestInfo: 'Select a guest team',
      clubGuestInfoSet: 'Guest Team set',
      teamNameHome: 'Home Team',
      teamNameGuest: 'Guest Team',
      needNewTeam: '...my Team is not listed',
      newTeam: '...create new Team',
      gender: 'Gender',
      ageclass: 'Ageclass',
      lableHomeclub: 'Home team',
      lableGuestclub: 'Guest team',
      teamPlaceholder: 'Select team',
      clubPlaceholder: 'Select club',
      placeholder: {
        selectFirstClub: 'Please select first Club...',
        selectBothClub: 'Please select first Homeclub & Guestclub',
        select: 'Please select...'
      },
      competitionLabel: 'Competition',
      season: 'Season'
    },
    paywall: {
      stepTitle: 'Paywall',
      title: 'Priceoption',
      infoText: 'set the price for the audience.',
      formFree: {
        title: 'Free',
        contentText: 'Free'
      },
      formPay: {
        title: 'Pay per viewer',
        contentText: 'Price per viewer',
        defaultTitleText: 'Single ticket',
        defaultDescriptionText: 'Unlock this game.',
        vat: 'VAT included',
        infoPopup: {
          headText: 'More information',
          contentText:
            'Staige erhebt pro gezahltem Einzelzugang (Pay-per-View) grundsätzlich eine Servicepauschale von 1,50 € netto, sofern sich keine anderen vorrangig vereinbarten Konditionen aus Eurem Vertragsverhältnis mit Staige oder unseren internationalen Lizenzpartnern (u.a.. AUT: RS Digital) ergeben. Servicepauschale wird bei Gutschrift der Nettoerlöse in Abzug gebracht. Nettoerlöse bezeichnet die tatsächlich erzielten Erlöse aus dem Pay-per-View-Angebot abzüglich der für die Zahlungsabwicklung über digitale Zahlungssysteme anfallenden Kosten sowie der gesetzlichen Umsatzsteuer. Tatsächlich erzielt sind Erlöse, die Staige durch die Anbieter der digitalen Zahlungssysteme auf dem jeweiligen Konto gutgeschrieben wurden und dadurch zur Auszahlung an den Verein tatsächlich zur Verfügung stehen.'
        },
        infoButton: 'included 1,50€ fee?'
      },
      formFreeTicket: {
        contentText: 'Generate free tickets',
        ticket: 'Tickets',
        defaultTitleText: 'Free ticket',
        defaultDescriptionText: 'Free ticket',
        tooltip:
          'The generated free ticket codes will be shown to you later in the created recording in the calendar.'
      }
    },
    sponsors: {
      stepTitle: 'Sponsors',
      tooManyOfTypeErrorMessage:
        'You have already reached the maximum number ({{maxAmount}}) of allowed $t(general.adTypes.{{type}}) sponsors.',
      loadingAds: 'Loading ads...',
      noAdsYet: "Please create an ad first. You'll then be able to add it to this game.",
      remainingAmountOfAdsTitle: 'Remaining number of selectable ads per type',
      matchSponsor: 'Spielsponsor',
      clubSponsor: 'Vereinssponsor'
    },
    toastText: {
      camWorkNotSet: 'camera work not selected!',
      errDate: 'Date / Time missing!',
      succsessSave: 'Changes saved successfully',
      errSave: 'Something went wrong.',
      succdessNew: 'Recording created successfully',
      underTenMin:
        'The recording was not made 15 minutes before the start. There may be slight delays at the start of the stream, but the game will be broadcast in full.',
      startIsAfter:
        'The scheduled start of this recording must not be in the past / the start time has been adjusted',
      noContract: 'Something went wrong. Please try again later.',
      outOfStreamingMinutes: 'Not enough streaming minutes left. Please deactivate planed recordings.'
    }
  },
  videoList: {
    calendarToday: 'Today',
    edit: 'Edit',
    infoTextNoData: 'No further data found.',
    infoTextNoSearchFound: 'No data found for this search request.',
    btnNewBroadcast: 'Add new broadcast',
    searchText: 'Search',
    searrchPlaceholder: 'Camera ID, Video ID or Arena...',
    canceled: 'deactivated'
  },
  adMaterialsOverview: {
    newAd: 'Create new ad'
  },
  cameraMonito: {
    analysis: 'Camera',
    moreCameras: 'More cameras',
    speedTest: {
      title: 'Upload Speed',
      speed: 'Internet speed',
      errorMessage: 'Could not be determined',
      unknown: 'unknown',
      mbps: 'Mbit',
      startLabel: 'Start speedtest',
      infoText: 'Your connection is fast enough to feature live streaming and VoD Recording',
      infoTextNot: 'Your connection is NOT fast enough to feature live streaming and VoD Recording'
    },
    claimCamButton: 'Add New Camera',
    camId: 'CAMERA ID',
    speedTestBox: {
      headerText: 'Speedtest',
      infoText: 'Check your curent upload'
    },
    setupStream: {
      headerText: 'Define fieldmask',
      infoText: 'Get a live view and setup the pitch'
    },
    support: {
      headerText: 'Support',
      infoText: 'Request technical support'
    }
  },
  claimCamera: {
    title: 'Claim camera',
    invalidQrCode: `The QR code doesn't seem to be from Staige. Instead, it contains: `,
    welcomeMessage:
      'We are happy that you have chosen a Staige camera system. Now link your Staige camera to the Backstaige app. Scan the QR code that came with your camera. Alternatively, you can enter the 20-digit code manually.',
    scanQrCode: 'Scan QR code',
    uploadImage: 'Upload image',
    enterTokenManually: 'Enter 20-digit code',
    check: 'Check',
    tokenWasValidMessage: 'Your token was valid and the camera was added to your account!',
    tokenWasInvalidMessage: 'Your token was not valid!',
    goToCamera: 'Go to camera',
    tryAgain: 'Try again',
    claimAnotherCamera: 'Claim another camera',
    noQrCodeFound: 'Es konnte kein QR-Code erkannt werden.',
    agbAcceptText: ' I have read and agree to the ',
    agbAcceptBtnText: 'terms of use',
    agbAcceptTextEnd: '',
    agbTitle: 'Terms of use „Backstaige“'
  },
  matchfieldCalibration: {
    defaultMasktext: 'Defaultmask',
    recorded: 'recorded',
    matchfield: 'Matchfield Recording',
    camera: 'Camera',
    // updateCamView: 'Update Camera view',
    camView: 'Camera view',
    recording: 'Recording',
    maskFieldButtonText: 'Edit current Fieldmask',
    select: 'Select matchfield',
    recordingFrom: 'Matchfield recording',
    working: 'Currently generating',
    unavailable: 'Not available',

    fieldSet: 'Matchfields',
    plusNewField: '+ New Matchfield',
    newField: 'New Matchfield',
    newFieldPlaceholder: 'e.g. turffield',
    ul: 'upper left',
    ur: 'upper right',
    ll: 'lower left',
    lr: 'lower right',
    selectCorner1: 'Plese select the ',
    selectCorner2: ' corner',
    confirmNew: 'This will render the previous recording inaccasable. Are you sure?',
    saveError: 'There was an error while saving the Fieldset. Please try again in a moment.',
    fieldExists: 'Feldname {{ fieldName }} already exists',
    connectionClosedTitle: 'The Camera closed the connection.',
    connectionClosedText:
      'This most likely happend due to a timeout or another client connecting to the camera.',
    connectionClosedButton: 'Ok',
    connectionFailedTitle: 'Could not connect to the Camera.',
    connectionFailedText: 'Please make sure the Camera is online and reachable.',
    deviceBusyTitle: 'The Camera is currently busy recording.',
    deviceBusyText: 'Please try again after the recording finished.',
    connectionErrorTitle: 'An Error occured',
    connectionErrorText: 'Please try to reconnect. Should the Error persits, please contact support.',
    cameraUploading:
      'Your Camera is currently uploading match footage. This may lead to impairments regarding the quality and speed of the video connection.'
  },
  browserUnsuported: {
    maintext: 'Your current Browser is not yet supported!',
    subtext: 'Please use a recent version of either Chrome or Safari to use this feature.'
  },
  support: {
    headText: 'Support',
    noResultText: 'No match found.',
    searchText: 'Search'
  },
  tagging: {
    siteMode: {
      text01: 'The game has already started and our AI has already marked some events automatically.',
      text02:
        'In order to assign the events to the right team, please select the position of your team according to the scene of todays kick-off.'
    },
    waitScreen: {
      welcomeTitle: 'Welcome to the game',
      day: 'Day',
      days: 'Days',
      ai: 'AI',
      live: 'LIVE',
      modeTitle: 'Please choose how you want to tag the game:',
      modeField: {
        title: 'On the field',
        infoText:
          'Optimized user interface for quick tagging of game events. Tags are saved in the real time of the game.',
        btnText: 'Start tagging!',
        startInfo01: 'The staige camera "',
        startInfo02: '" is switched on only at ',
        startInfo03: ' clock'
      },
      modeLive: {
        title: 'Remote',
        infoText: 'Remotely mark game events using live game playback.',
        btnText: 'Start remote tagging!'
      }
    },
    liveTaggingPossibleText: 'live tagging now possible',
    lastTagContainer: {
      lastSetTagText: 'Last set Tag'
    },
    eventContainer: {
      taggingBtn: 'taggen',
      eventList: 'eventlist',
      showInsert: 'Show overlay',

      timeMenu: {
        timer: 'Timer',
        start: 'Start',
        end: 'End',
        endTimeout: 'End Timeout',
        playTime: 'Game times',

        quickStart: 'Continue time',
        quickEnd: 'Pause time',

        shotClock: 'Shotclock',
        timeControl: 'Time control',
        timeCorrection: 'Time correction'
      },
      popup: {
        infoText: 'Do you really want to reset the time of this event?'
      },
      buttonOverlay: {
        back: 'Back',
        more: 'More'
      }
    },
    clubInfo: {
      home: 'Home',
      guest: 'Guest'
    },
    insert: {
      showBy: 'Overlay appears at',
      title: 'Type of overlay',
      name: 'Insert',
      buttons: {
        full: 'Full range',
        bottom: 'Lower range',
        insert: 'Show now',
        insertTemp: 'Fade in at'
      },
      input: {
        title: 'Upload'
      },
      slider: {
        title: 'Visibility duration',
        sec: 'Seconds'
      }
    },
    tagList: {
      eventListMenu: {
        gameTime: 'Game Events',
        timeEvents: 'Time Events',
        all: 'All',
        home: 'Home',
        guest: 'Guest'
      },
      buttons: {
        events: 'Events',
        time: 'Time',
        edit: 'Edit'
      },
      deleteWarningText: 'Do you really want to delete this event?',
      infoStarText: 'Highlight',
      infoCog: 'Tagged about scoreboard'
    },
    checkAiTagList: {
      title: 'Sind diese KI Events richtig?'
    },
    overlay: {
      aiEvent: {
        text: 'Hey! Did you just miss a ',
        text1: ' from ',
        text2: '?'
      },
      teamSide: {
        text: 'On which side does the home team start?',
        infoText:
          'This information helps us to assign the events detected by our AI to the correct team during the game.',
        btn: {
          left: 'Left',
          right: 'Right'
        }
      }
    }
  },
  forms: {
    club: {
      title: 'Create Club',
      searchName: 'Search Name',
      color: 'Color',
      selectColor: 'Select Color...',
      thumbnail: 'Thumbnail',
      league: 'League',
      zip: 'Zip',
      city: 'City',
      location: 'Address',
      locationPlaceholder: 'Street + Nr',
      hasSystem: 'has System',
      hasCategory: 'has Category',
      commercialReady: 'commercial Ready',
      newClub: 'Create new Club...'
    },
    team: {
      title: 'Create Team',
      age: 'Age',
      competitions: 'Competitions',
      aliasList: 'Alias List',
      dId: 'DID',
      dfbLink: 'Dfb Link',
      club: 'Club',
      newTeam: 'Create new Team'
    },
    city: {
      title: 'Create City',
      state: 'State',
      stateShort: 'State Short',
      country: 'Country',
      newCity: 'Create New City'
    },
    competition: {
      competition: 'competition',
      competitions: 'competitions'
    },
    licenseTypes: {
      AdManagerAccess: 'Ad Manager',
      CameraLicense: 'Camera Manager',
      TrainerSubscription: 'Trainer',
      ContractOwnerLicense: 'Contract Owner',
      CommentatorLicense: 'Commentator'
    },
    contract: {
      title: 'Contract Details',
      text1: 'To complete the setup, please enter the name of your organisation.',
      text2: 'Then select your club to get started.'
    }
  },
  administration: {
    title: 'Management',
    userManagement: {
      menuTitle: 'User Management',
      menuSupTitle: 'Add new users and manage their access and rights for the different Staige products.',
      chooseAnotherContract: 'Choose another Contract',
      infoText: 'Link users to your camera and assign rights to them',
      userEdit: {
        addUser: 'Add user',
        user: 'User management',
        mail: {
          placeholder: 'example@staige.tv...',
          infoText: 'The added user must have an account on staige.tv',
          errorMessage: 'The user could not be found'
        },
        newUserInput: {
          lable: 'E-Mail',
          placeholder: 'max.mustermann@mail.de',
          helperText: 'The added user must have an account on staige.tv'
        },
        userBoxNew: 'Grants rights to the new user'
      },
      userRole: {
        ContractOwnerLicense: 'Admin',
        CameraLicense: 'RecordingAdministrator',
        TrainerSubscription: 'Trainer',
        ClubTaggerLicense: 'Tagger',
        AdManagerAccess: 'AdManager',
        ClubStreamerLicense: 'Streamer'
      },
      userRoleTranslate: {
        contractOwner: 'Admin',
        cameraController: 'RecordingAdministrator',
        trainer: 'Trainer',
        clubTagger: 'Tagger',
        adManager: 'AdManager',
        ClubStreamerLicense: 'Streamer'
      },
      userRoleDesc: {
        ContractOwnerLicense: 'Access to all functions. Can add new users and manage rights',
        CameraLicense: 'May create and activate recordings',
        TrainerSubscription: 'Has access to Staige Coach and all recordings',
        ClubTaggerLicense: 'May add markers in the video',
        AdManagerAccess: 'May edit advertising',
        ClubStreamerLicense: 'May create streams with additional content'
      }
    },
    contractStramingAbo: {
      menuTitle: 'Subscription Management',
      menuSupTitle: 'Manage your subscription and get an overview of the remaining streaming time.',
      infoBox: {
        hour: 'hr',
        min: 'min',
        free: 'free',
        freeInfoText: 'Recording time that has not yet been scheduled or used',
        recorded: 'recorded',
        recordedInfoText: 'Past records'
      },
      contact: {
        infoText: 'You need more recording time?',
        mailToButton: 'Support Contact'
      },
      helpContainer: {
        title: 'Do you have any questions?',
        infoText: 'Contact our Club Support!'
      },
      aboBox: {
        headerTitleLeft: 'My subscription',
        infoBarTitle: 'Recording time',
        triggerTitle: 'Events',
        upgradeBtn: 'Upgrade',
        upcomingRecordings: 'Upcoming events',
        pastRecordings: 'Past events',
        planRecording: 'Plan event',
        badges: {
          match: 'Match',
          training: 'Training'
        },
        abo: 'Subscription',
        standardLTE: {
          title: 'Standard LTE',
          info: 'With the standard contingent, you can stream a total of 4 matches per month live on our staige.tv platform. The matches you want to broadcast must be activated in the match planning calendar.',
          info1:
            'Do you have more to show? With an LTE flat rate or a LAN connection, you can record and stream up to ten games per month (1,300 minutes). Please get in touch at clubsupport@staige.com.',
          aboType: 'Monthly'
        },
        proLTE: {
          title: 'PRO LTE (10 Matches)',
          info: 'With the PRO LTE package or your LTE flat rate, you can record up to ten matches per month (1,300 minutes) and stream them live on our staige.tv platform.',
          info1:
            'Do you have any questions about your contingent? Please contact us at clubsupport@staige.com.',
          aboType: 'Monthly'
        },
        lan: {
          title: 'LAN (10 Matches)',
          info: 'Thanks to your LAN connection, you can record up to ten games per month (1,300 minutes) and stream them live on our staige.tv platform.',
          info1:
            'Do you have any questions about your contingent? Please contact us at clubsupport@staige.com.',
          aboType: 'Monthly'
        },
        flex: {
          title: 'FLEXIBLE CONTINGENT',
          info: 'As agreed, you can use the quota shown below to record matches and stream them live on our platform staige.tv.',
          info1:
            'Do you have any questions about your contingent? Please contact us at clubsupport@staige.com.',
          aboType: 'Monthly'
        },
        monthly: {
          aboType: 'Monthly'
        },
        yearly: {
          aboType: 'Yearly'
        },
        free: {
          aboType: 'Freely available'
        }
      }
    }
  }
}
