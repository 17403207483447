import React from 'react'
import HeaderWrapper from '../../helper/PageWrapper'
import i18n from 'i18next'

export const NotFound = ({ nameOfItem, ...headerWrapperProps }: { nameOfItem?: string; linkTo: string }) => {
  return (
    <HeaderWrapper {...headerWrapperProps} headerTitle={i18n.t('notFound.title')}>
      <p
        style={{
          textAlign: 'center'
        }}
      >
        {i18n.t('notFound.message', { nameOfItem })}
      </p>
    </HeaderWrapper>
  )
}
