import { Competition } from '@soccerwatch/common'
import * as Discovery from '@soccerwatch/discovery'
import * as Helper from '../helper/GlobalHelper'
import { CityData } from '../types/CityData'

export const postCompetition = async (body: Partial<Competition>) => {
  return await Helper.apiPost<Competition>(`${Discovery.API_CITY}/competition`, body)
}

export const getListCities = async () => {
  return await Helper.apiList<CityData>(Discovery.API_CITY + '/cities/')
}

export const postCity = async (body: CityData) => {
  return await Helper.apiPost<CityData>(Discovery.API_CITY + '/city/', body)
}
