import React from 'react'
import './loadingSpinner.scss'

type LoadingSpinnerProps = {
  className?: string
}

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  return (
    <div className={`loader ${props.className}`}>
      <div className='inner-spinn one' />
      <div className='inner-spinn two' />
      <div className='inner-spinn three' />
    </div>
  )
}

export default LoadingSpinner
