import React, { useCallback, useMemo } from 'react'
import i18n from 'i18next'
import StaigeSwitch from '../../../components/styles/Switch'
import callToActionIcon from '../../../img/icons/callToAction.svg'
import StaigeTooltip from '../../../components/styles/Tooltip'

import { AdvertisementContainer } from '../../../services/advertisementContainer'
import { CameraData, MediaResourceType, InstreamAd, InstreamTypes } from '@soccerwatch/common'
import { Link } from 'react-router-dom'

import './SponsorStep.scss'

const maxSelectablePerType: Record<MediaResourceType, number> = {
  [MediaResourceType.IMAGE]: 3,
  [MediaResourceType.STATIC]: 3,
  [MediaResourceType.VIDEO]: 2,
  [MediaResourceType.LOTTY]: 3,
  [MediaResourceType.CLUB]: 0
}

export const SponsorsStep = (props: {
  adMaterials: InstreamAd[]
  selectedAdMaterials: Array<InstreamAd>
  setSelectedAdMaterials: (newMaterials: Array<InstreamAd>) => void
  advertisementContainer: AdvertisementContainer
  camera?: CameraData
}) => {
  const { loadingData } = props.advertisementContainer.state
  const { adMaterials } = props
  const sortedAdMaterials = useMemo(() => {
    // Sort materials by title alphabetically ascending
    return [...adMaterials].sort((a, b) => a.name.localeCompare(b.name))
  }, [adMaterials])

  const filterAdMaterials = useMemo(() => {
    let tempSortedAdMaterialsAd: Array<InstreamAd> = []
    let tempSortedAdMaterialsClubAd: Array<InstreamAd> = []

    sortedAdMaterials.map((ad) => {
      if (props.camera && props.camera.ownerClubId) {
        if (ad.enabledClubs) {
          if (ad.enabledClubs.indexOf(props.camera.ownerClubId) === -1) {
            tempSortedAdMaterialsAd.push(ad)
          } else {
            tempSortedAdMaterialsClubAd.push(ad)
          }
        } else {
          tempSortedAdMaterialsAd.push(ad)
        }
      }
    })
    return {
      filterAdMaterialAd: tempSortedAdMaterialsAd,
      filterAdMaterialClubAd: tempSortedAdMaterialsClubAd
    }
  }, [adMaterials])

  const isMaterialSelected = (adMaterial: InstreamAd): boolean =>
    props.selectedAdMaterials.map((material) => material.RowKey).includes(adMaterial.RowKey)

  const setMaterialIsIncluded = useCallback(
    (shouldBeIncluded: boolean, adMaterial: InstreamAd) => {
      const isAlreadyIncluded = isMaterialSelected(adMaterial)
      if (isAlreadyIncluded === shouldBeIncluded) {
        // Nothing to do
      } else if (shouldBeIncluded) {
        props.setSelectedAdMaterials([...props.selectedAdMaterials, adMaterial])
      } else {
        props.setSelectedAdMaterials(
          props.selectedAdMaterials.filter((currMaterial) => currMaterial.RowKey !== adMaterial.RowKey)
        )
      }
    },
    [props.selectedAdMaterials, props.setSelectedAdMaterials]
  )

  const selectedPerType = useMemo(() => {
    return props.selectedAdMaterials.reduce((result, adMaterial) => {
      if (adMaterial) {
        return {
          ...result,
          [adMaterial.instreamType]: (result[adMaterial.instreamType] ?? 0) + 1
        }
      } else {
        return result
      }
    }, {} as Record<MediaResourceType, number | undefined>)
  }, [props.selectedAdMaterials, adMaterials])

  const toggleMaterialSelected = useCallback(
    (adMaterial: InstreamAd) => {
      setMaterialIsIncluded(!isMaterialSelected(adMaterial), adMaterial)
    },
    [props.selectedAdMaterials, setMaterialIsIncluded]
  )

  const renderItem = (adMaterial: any, index: number, isSelected: boolean, isToggleable: boolean) => {
    return (
      <div className='sponsor-list' style={!isToggleable ? { opacity: '0.64' } : {}} key={index}>
        <div className='list-item'>
          <div className='item-img'>
            <img src={adMaterial.mediaInstream} />
          </div>
          <div className='item-content'>
            <div className='content-title'>{adMaterial.name}</div>
            <div className='content-subTitle'>
              <div className='subTitle-container'>
                <div className='container-text'>
                  <img src={callToActionIcon} />
                </div>
                <div className='container-text'>
                  {adMaterial.instreamType === InstreamTypes.IMAGE && i18n.t('editAdvertisement.labels.loop')}
                  {adMaterial.instreamType === InstreamTypes.STATIC &&
                    i18n.t('editAdvertisement.labels.fixed')}
                </div>
              </div>
            </div>
          </div>
          <div className='item-toggle'>
            <StaigeSwitch
              size='medium'
              checked={isSelected}
              onClick={() => {
                if (isToggleable) {
                  toggleMaterialSelected(adMaterial)
                }
              }}
              disabled={!isToggleable}
            />
          </div>
        </div>
      </div>
    )
  }

  const sponsorList = useMemo(() => {
    return filterAdMaterials.filterAdMaterialAd.map((adMaterial, index) => {
      const isSelected = isMaterialSelected(adMaterial)
      const isRTLLuxembourgCamera =
        (props.camera?.systemOwner?.toLowerCase().includes('rtl luxembourg') ||
          props.camera?.systemOwner?.toLowerCase().includes('luxembourg rtl')) ??
        false

      const isToggleable =
        isSelected ||
        // RTL Luxembourg is the only club that wants this limit.
        // TODO: Change this once the role system has been re-done!
        !isRTLLuxembourgCamera ||
        (selectedPerType[adMaterial.instreamType] ?? 0) < maxSelectablePerType[adMaterial.instreamType]

      return (
        <div key={index} className='sponsor-container'>
          {renderItem(adMaterial, index, isSelected, isToggleable)}
        </div>
      )
    })
  }, [sortedAdMaterials, setMaterialIsIncluded, props.selectedAdMaterials, loadingData])

  const sponsorClubList = useMemo(() => {
    return filterAdMaterials.filterAdMaterialClubAd.map((adMaterial, index) => {
      return (
        <div key={index} className='sponsor-container'>
          <div className='container-textInfo'>
            <div className='textInfo-t'>{i18n.t('matchEditor.stepAds.adsWrapper.title')}</div>
            <StaigeTooltip
              title={i18n.t('matchEditor.stepAds.adsWrapper.title')}
              text={i18n.t('matchEditor.stepAds.adsWrapper.tooltip')}
              style={{ fontSize: 14, marginLeft: 4 }}
            />
          </div>
          {renderItem(adMaterial, index, true, false)}
        </div>
      )
    })
  }, [sortedAdMaterials, setMaterialIsIncluded, props.selectedAdMaterials, loadingData])

  return (
    <div className='main-sponsor'>
      {sponsorList}
      {sponsorClubList}
      <div className='sponsor-addLink'>
        <Link to='/advertisement'>{i18n.t('matchEditor.stepAds.createNewAd')}</Link>
      </div>
    </div>
  )
}
