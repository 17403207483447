import { Container } from 'unstated-typescript'
import Discovery, { assertContainerHasInitializedDiscovery } from './discovery'
import UserLogin from './userContainer'
import type DiscoveryType from '@soccerwatch/discovery'
import { CityData } from '../types/CityData'
import { getListCities, postCity } from '../api/api-city'

type CityState = {
  loadingData: boolean
  cities: Array<CityData>
  discovery?: typeof DiscoveryType
}

export class CityContainer extends Container<CityState> {
  discovery?: typeof DiscoveryType

  constructor() {
    super()

    this.state = {
      loadingData: true,
      cities: [],
      discovery: undefined
    }

    Discovery.then((d) => {
      this.setState({ discovery: d })
      if (UserLogin.state.loggedIn) {
        this.initialize()
      }
    })
  }

  initialize() {}

  setCityList = async () => {
    assertContainerHasInitializedDiscovery(this)

    await this.setState({ loadingData: true })
    let cityRes = await getListCities()
    await this.setState({
      loadingData: false,
      cities: cityRes
    })
  }

  createCity = async (body: CityData) => {
    assertContainerHasInitializedDiscovery(this)
    const res = await postCity(body)
    const cities = JSON.parse(JSON.stringify(this.state.cities)) as CityData[]
    cities.push(res)
    await this.setState({ cities })
    return res
  }
}

const cityContainer = new CityContainer()
export default cityContainer
