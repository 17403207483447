import React, { useCallback } from 'react'

import i18n from 'i18next'
import HeaderWrapper from '../../helper/PageWrapper'
import addUserIcon from '../../img/icons/addUser.svg'
import callToActionIcon from '../../img/icons/callToAction.svg'
import editIcon from '../../img/icons/edit.svg'
import StaigeBadge from '../../components/styles/Badge'
import StaigeSwitch from '../../components/styles/Switch'
import StaigeButton from '../../components/styles/Button'

import { InstreamAd } from '@soccerwatch/common'
import { useHistory } from 'react-router'
import { AdvertisementContainer } from '../../services/advertisementContainer'
import { InstreamTypes } from '@soccerwatch/common'

import './Sponsors.scss'

export const Sponsors = (props: {
  advertisementContainer: AdvertisementContainer
  adMaterials: Array<InstreamAd>
  contractChanging: boolean
}) => {
  const { loadingData } = props.advertisementContainer.state
  const history = useHistory()

  const goToNewAd = (contentType: InstreamAd['instreamType']) => {
    return useCallback(() => {
      history.push(`/advertisement/new/${contentType}`)
    }, [])
  }
  const goToNewImageAd = goToNewAd(InstreamTypes.IMAGE)

  const { updateAdMaterial } = props.advertisementContainer

  const updateAdMaterialActive = async (ad: InstreamAd) => {
    const editAd = ad
    editAd.active = !ad.active
    await updateAdMaterial(editAd)
  }

  return (
    <HeaderWrapper
      headerTitle={i18n.t('sponsors.headerTitle')}
      quickButton={<StaigeButton icon={addUserIcon} onClick={goToNewImageAd} round />}
      style={{ height: '100%' }}
    >
      <div className={'page-sponsors'}>
        <div className='sponsors-content'>
          <div className='content-btn'>
            <StaigeButton label={i18n.t('sponsors.createButton')} onClick={goToNewImageAd} />
          </div>
          <div className='content-sponsorList'>
            {props.adMaterials.length > 0 && !loadingData && !props.contractChanging ? (
              props.adMaterials.map((material, index) => {
                //@ts-ignore
                const createdDate = new Date(material.SLCreated).toLocaleDateString()

                return (
                  <div key={index} className={`sponsorList-item `}>
                    <div
                      className='item-img'
                      onClick={() => history.push(`/advertisement/edit/${material.RowKey}`)}
                    >
                      <div className='img-wrapper'>
                        <img src={material.mediaInstream} />
                      </div>
                    </div>
                    <div className='item-content'>
                      <div className='content'>
                        <div className='content-createX'>
                          <div
                            className={`create-activeWrapper ${
                              material.active ? 'create-activeWrapperActive' : ''
                            }`}
                          >
                            <div className='activeWrapper-dot' />
                            <div className='activeWrapper-text'>
                              {`${material.active ? 'Aktiv' : 'Inaktiv'}`}
                            </div>
                          </div>
                          <div className='content-create content-createL'>|</div>
                          <div className='content-create'>
                            {i18n.t('sponsors.adCreate') + ' '} {createdDate}
                          </div>
                        </div>
                        <div className='content-name'>{material.name}</div>
                        <div className='content-comment'>{material.comment}</div>
                      </div>

                      <div className='content-banner'>
                        <StaigeBadge
                          active={material.enabledClubs && material.enabledClubs?.length > 0}
                          title={i18n.t('sponsors.badges.clubBased')}
                        />

                        <StaigeBadge
                          active={material.enabledClubs && material.enabledClubs?.length === 0}
                          title={i18n.t('sponsors.badges.gameBased') + ' '}
                          color='rosa'
                        />

                        <div className='banner-containerText'>
                          <div className='containerText-icon'>
                            <img src={callToActionIcon} />
                          </div>
                          <div className='containerText-text'>
                            {material.instreamType === InstreamTypes.IMAGE &&
                              i18n.t('editAdvertisement.labels.loop')}
                            {material.instreamType === InstreamTypes.STATIC &&
                              i18n.t('editAdvertisement.labels.fixed')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='item-action'>
                      <StaigeSwitch
                        label={i18n.t('sponsors.activate')}
                        size='medium'
                        checked={material.active}
                        onClick={() => updateAdMaterialActive(material)}
                      />
                      <StaigeButton
                        style='ghost'
                        icon={editIcon}
                        iconMargin='0 -4px 0 -4px'
                        linkTo={'/advertisement/edit/' + material.RowKey}
                      />
                    </div>
                  </div>
                )
              })
            ) : (
              <div className={'no-ads'}>
                <h3>
                  {i18n.t(
                    loadingData || props.contractChanging
                      ? 'editAdvertisement.adsAreLoading'
                      : 'editAdvertisement.noAdsYet'
                  )}
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </HeaderWrapper>
  )
}
