import React from 'react'
import mobiscroll from '@mobiscroll/react'
import PropTypes, { InferProps, Requireable } from 'prop-types'
import i18n from 'i18next'
import HeaderWrapper from '../../helper/PageWrapper'

import { checkLanguages } from '../helper/checkLanguages/checkLanguages'
import { UserContainer } from '../../services/userContainer'

import './Login.scss'

type LoginPropTypes = InferProps<typeof LoginPage.propTypes>

type LoginPageStateType = {
  languages: string
}

export default class LoginPage extends React.Component<LoginPropTypes, LoginPageStateType> {
  static propTypes = {
    loggedIn: PropTypes.bool,
    loggedOut: (PropTypes.func as Requireable<() => void>).isRequired,
    changeLanguage: (PropTypes.func as Requireable<(language: string) => void>).isRequired,
    userMail: PropTypes.string,
    userContainer: PropTypes.any.isRequired as PropTypes.Validator<UserContainer>
  }

  constructor(props: LoginPropTypes) {
    super(props)

    this.state = {
      languages: checkLanguages()
    }
  }

  private refLogin = React.createRef<HTMLDivElement>()

  componentDidMount() {
    this.refLogin.current?.addEventListener('loggedOut', async () => {
      this.props.loggedOut()
      await mobiscroll.toast({ message: i18n.t('login.toast.logoutSuccessful') })
    })
  }

  handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (this.props.loggedIn) {
      this.props.loggedOut()
      await mobiscroll.toast({ message: i18n.t('login.toast.logoutSuccessful') })
    }
  }

  changeLanguage = (language: string) => {
    this.props.changeLanguage(language)
    this.setState({
      languages: language
    })
  }

  checkLanguage(lang: string) {
    let findMore = lang.search('-')
    if (findMore !== -1) {
      const split = lang.split('-')
      return split[0]
    }
    return lang
  }

  render() {
    return (
      <HeaderWrapper headerTitle={this.props.loggedIn ? 'Account' : 'Login'}>
        <div>
          <div style={{ position: 'relative', color: '#525252', fontSize: '12px', margin: '4px 4px' }}>
            Version: {process.env.REACT_APP_VERSION}
          </div>
          <div style={{ margin: '0px !important' }}>
            <mobiscroll.Button
              className={this.state.languages === 'de' ? 'round small full' : 'round small'}
              onClick={() => this.changeLanguage('de')}
              style={{ marginLeft: '0', width: '50px' }}
            >
              DE
            </mobiscroll.Button>
            <mobiscroll.Button
              className={this.state.languages === 'en' ? 'round small full' : 'round small'}
              onClick={() => this.changeLanguage('en')}
              style={{ marginLeft: '10px', width: '50px' }}
            >
              EN
            </mobiscroll.Button>
          </div>

          <div
            style={{
              height: '60vh',
              marginTop: '20px'
            }}
          >
            <aisw-user logo={false} ref={this.refLogin} language={this.checkLanguage(i18n.language)} />
          </div>
        </div>
      </HeaderWrapper>
    )
  }
}
