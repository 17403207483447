import React from 'react'
import './aiswSpinner.scss'

type AiswSpinnerProps = {
  className?: string
}

export const AiswSpinner = (props: AiswSpinnerProps) => {
  return (
    <div className={`s-spinner ${props.className}`}>
      <div className='s-spinner__left' />
      <div className='s-spinner__right' />
    </div>
  )
}

export default AiswSpinner
