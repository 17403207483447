import React, { useState, useRef, useEffect } from 'react'

import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

export default function App(props: { setPreviewImage: any; image: File | undefined; isGif: boolean }) {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(16 / 9)

  useEffect(() => {
    if (props.image) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
      reader.readAsDataURL(props.image)
    }
  }, [props.image])

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget

      if (props.isGif) {
        setCrop(centerAspectCrop(0, 0, aspect))
      } else {
        setCrop(centerAspectCrop(width, height, aspect))
      }
    }
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        await canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)

        let newPreviewImage = previewCanvasRef.current?.toDataURL()
        if (newPreviewImage && props.setPreviewImage) {
          props.setPreviewImage(String(newPreviewImage))
        }
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  return (
    <div className='App'>
      <div className='Crop-Controls'></div>
      {!!imgSrc && (
        <ReactCrop
          disabled={props.isGif}
          crop={crop}
          onChange={(_, percentCrop) => {
            if (percentCrop.height === 0 && percentCrop.width === 0) {
              return
            }
            setCrop(percentCrop)
          }}
          onComplete={(c) => {
            setCompletedCrop(c)
          }}
        >
          <img
            ref={imgRef}
            alt='Crop me'
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {
        <div>
          {!!completedCrop && (
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
                display: 'none'
              }}
            />
          )}
        </div>
      }
    </div>
  )
}
