import { Container } from 'unstated-typescript'
import Discovery from '@soccerwatch/discovery'
import InitializedDiscovery, { assertContainerHasInitializedDiscovery } from './discovery'
import { Contract, GenericAd, InstreamAd, Role } from '@soccerwatch/common'
import { apiDelete, apiList, apiPost } from '../helper/GlobalHelper'
import { withContainer } from './withContainerHOC'
import { ContractContainer } from './contractContainer'
import { searchAndSortGetCallAs } from './serviceHelper'

export const getApiEndpoint = () => 'https://europe-west3-aisw-ww-prod.cloudfunctions.net/api_additional' //DiscoveryType.API_AD

export type AdvertisementState = {
  loadingData: boolean
  discovery?: typeof Discovery
  adMaterials: Record<string, InstreamAd[]>
}
export class AdvertisementContainer extends Container<AdvertisementState> {
  contractContainer?: ContractContainer
  constructor() {
    super()

    this.state = {
      loadingData: true,
      adMaterials: {}
    }

    InitializedDiscovery.then(async (discovery) => {
      await this.setState({ discovery })
    })
  }

  onBeforeContractChanged = (nextIndex: number, activeContract: () => number) => {
    return new Promise<void>((resolve) => {
      if (nextIndex !== activeContract()) return resolve()
      this.setState({ loadingData: true }, async () => {
        if (nextIndex !== activeContract()) return resolve()
        const contract = this.contractContainer?.getContract(nextIndex)
        if (!contract) {
          console.error(
            '<Advertisement Container> Contract Changed to non existant Contract. Someone seriously f*ed up...'
          )
          return
        }
        if (nextIndex !== activeContract()) return resolve()
        await this.fetchAdMaterialsForContract(contract)
        resolve()
      })
    })
  }

  onContractChanged = () => {}

  initialize(contractContainer: ContractContainer) {
    this.contractContainer = contractContainer
    this.contractContainer.subscribeToContractChanged(
      'AdvertismentContainer',
      this.onContractChanged,
      this.onBeforeContractChanged
    )
    const contract = this.contractContainer.getCurrentContract()
    if (contract) {
      this.fetchAdMaterialsForContract(contract)
    }
  }

  getAdMaterialsForCurrentContract = () => {
    return this.getAdMaterialsForContract()
  }

  getAdMaterialsForContract = (index?: number) => {
    if (!this.contractContainer) {
      if (!this.state.loadingData) {
        console.error('<AdvertisementContainer>: Contract Container not Initialized. This should not happen!')
      }
      return [] as InstreamAd[]
    }
    const contract = this.contractContainer.getContract(index)
    if (!contract) {
      if (!this.contractContainer.state.loadingData) {
        console.error('<AdvertisementContainer>: No Active Contract Set. This should not happen!')
      }
      return [] as InstreamAd[]
    }
    if (!this.state.adMaterials[contract.RowKey]) {
      if (!this.state.loadingData) {
        console.error(
          '<AdvertisementContainer>: Could not find Ad-Data for Contract',
          contract.RowKey,
          'This should not happen.'
        )
      }
      return [] as InstreamAd[]
    }

    return this.state.adMaterials[contract.RowKey] //.concat(legacy)
  }

  fetchAdMaterialsForContract = async (contract: Contract) => {
    assertContainerHasInitializedDiscovery(this)
    await this.setState({ loadingData: true })
    const adMaterials = JSON.parse(JSON.stringify(this.state.adMaterials))
    const contractId = contract.RowKey
    const ads = await apiList<GenericAd>(`${getApiEndpoint()}/genericOfContract/${contractId}`)
    adMaterials[contractId] = ads.sort((a, b) => a.name.localeCompare(b.name))
    this.setState({ adMaterials, loadingData: false })
    return adMaterials
  }

  getAdMaterialById = (id: string) => {
    const contract = this.tryGetCurrentContract()
    if (!contract) {
      return undefined
    }
    const material = this.state.adMaterials[contract.RowKey].find((itm) => itm.RowKey === id)
    if (!material) {
      console.error('<AdvertismentContainer>: Could not find Ad with id', id, 'in Container')
      return undefined
    }
    return material
  }

  tryGetCurrentContract = () => {
    return this.tryGetContract()
  }

  tryGetContract = (index?: number) => {
    if (!this.contractContainer) {
      if (!this.state.loadingData) {
        console.error('<AdvertismentContainer>: Contract Container not Initialized')
      }
      return undefined
    }
    const contract = this.contractContainer.getContract(index)
    if (!contract) {
      if (!this.contractContainer.state.loadingData) {
        console.error('<AdvertismentContainer>: Could not get Contract', index)
      }
      return undefined
    }
    return contract
  }

  createAdMaterial = async (incompleteAdMaterial: Omit<InstreamAd, 'RowKey'>): Promise<InstreamAd | void> => {
    const contract = this.tryGetCurrentContract()
    if (!contract) {
      return
    }
    const createdMaterial = await apiPost<InstreamAd>(`${getApiEndpoint()}/generic/`, incompleteAdMaterial)
    const adMaterials = JSON.parse(JSON.stringify(this.state.adMaterials)) as Record<string, InstreamAd[]>
    adMaterials[contract.RowKey].push(createdMaterial)
    await this.setState({ adMaterials })
    return createdMaterial
  }

  updateAdMaterial = async (newAdMaterial: InstreamAd): Promise<InstreamAd | void> => {
    if (!this.contractContainer) {
      console.error('ContractContainer not initialized. This should not happen')
      return
    }
    const contract = this.tryGetCurrentContract()
    if (!contract) {
      return
    }
    const adMaterials = JSON.parse(JSON.stringify(this.state.adMaterials)) as Record<string, InstreamAd[]>

    const index = adMaterials[contract.RowKey].findIndex(
      (material) => material.RowKey === newAdMaterial.RowKey
    )
    if (!index && index !== 0) {
      console.error('<AdvertismentContainer>:Could not find Updated Material in Data. This should not happen')
      return
    }
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      Role.adManager,
      this.contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    await apiPost<InstreamAd>(
      `${getApiEndpoint()}/genericById/${newAdMaterial.RowKey}/${callAs}`,
      newAdMaterial
    )

    adMaterials[contract.RowKey][index] = newAdMaterial

    await this.setState({ adMaterials })

    return newAdMaterial
  }

  deleteAdMaterial = async (adMaterial: InstreamAd): Promise<void> => {
    if (!this.contractContainer) {
      return console.error('ContractContainer not initialized. This should not happen!')
    }
    const contract = this.tryGetCurrentContract()
    if (!contract) {
      return
    }
    const adMaterials = JSON.parse(JSON.stringify(this.state.adMaterials)) as Record<string, InstreamAd[]>
    const index = adMaterials[contract.RowKey].findIndex((material) => material.RowKey === adMaterial.RowKey)
    if (!index && index !== 0) {
      console.error('<AdvertismentContainer>:Could not find Updated Material in Data. This should not happen')
      return
    }
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      Role.adManager,
      this.contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    await apiDelete(`${getApiEndpoint()}/genericById/${adMaterial.RowKey}${callAs}`)
    adMaterials[contract.RowKey].splice(index, 1)

    await this.setState({
      adMaterials
    })
  }
}

export const advertisementContainerSingleton = new AdvertisementContainer()
export default advertisementContainerSingleton

export const withAdvertisementContainer = withContainer(
  advertisementContainerSingleton,
  'advertisementContainer'
)
