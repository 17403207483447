import React, { Component } from 'react'
import moment from 'moment'
import i18n from '../../../languages/i18n'

import { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'
import { ClubData } from '@soccerwatch/common'

import './waitScreen.scss'

type EventListContainerPropsType = RouteComponentProps & {
  clubs: { home: ClubData; guest: ClubData } | undefined
  videoInfo: any
  videoState: { videoIsLive: boolean; videoIsEnd: boolean }
  score: { home: number; guest: number }
  timerInformation: { timer: string; minutes: number; seconds: number }
}

type EventListContainerStateType = {
  videoStartDate: string | undefined
  duration: moment.Duration | undefined
  countdown: any
}

class EventListContainer extends Component<EventListContainerPropsType, EventListContainerStateType> {
  // Constructor
  constructor(props: EventListContainerPropsType) {
    super(props)
    this.state = {
      videoStartDate: undefined,
      duration: undefined,
      countdown: undefined
    }
  }

  init = () => {
    if (this.props.videoInfo?.expectedStartTime) {
      const dateTime = moment(new Date(this.props.videoInfo.expectedStartTime)).format('DD.MM.YYYY HH:mm')
      const eventTime = new Date(this.props.videoInfo.expectedStartTime).getTime()
      const currentTime = new Date().getTime()
      const diffTime = eventTime - currentTime
      const duration = moment.duration(diffTime, 'milliseconds')

      const countdown = setInterval(() => {
        const eventTime = new Date(this.props.videoInfo.expectedStartTime).getTime()
        const currentTime = new Date().getTime()
        const diffTime = eventTime - currentTime
        const duration = moment.duration(diffTime, 'milliseconds')

        if (diffTime <= 0) {
          clearInterval(this.state.countdown)
          return
        }

        this.setState({
          duration: duration
        })
      }, 1000)

      this.setState({
        videoStartDate: dateTime,
        duration: diffTime <= 0 ? undefined : duration,
        countdown: countdown
      })
    }
  }

  componentDidMount(): void {
    if (this.props.videoInfo?.expectedStartTime) {
      this.init()
    }
  }

  componentDidUpdate(
    prevProps: Readonly<EventListContainerPropsType>,
    prevState: Readonly<EventListContainerStateType>,
    snapshot?: any
  ): void {
    if (prevProps.videoInfo !== this.props.videoInfo || this.props.clubs !== prevProps.clubs) {
      this.init()
    }
  }

  renderLiveKiInfo = () => {
    if (!this.props.videoState.videoIsLive) return

    return <div></div>
  }

  render() {
    const { videoStartDate, duration } = this.state
    const colorHome = this.props.clubs?.home.color || 'rgb(95 111 52 / 25%)'
    const colorGuest = this.props.clubs?.guest.color || 'rgb(95 111 52 / 25%)'
    const style = {
      background:
        'linear-gradient(to right, ' +
        colorHome +
        '60, ' +
        colorGuest +
        '60), no-repeat url("../../../img/bg/player.jpg")'
    }
    const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

    return (
      <div className='waitScreen' style={style}>
        <div className='headerText'>{i18n.t('tagging.waitScreen.welcomeTitle')}</div>
        <div className='content'>
          <div className='content-inner'>
            <div className='clubInfo'>
              <div className='logo'>
                <img src={this.props.clubs?.home.thumbnail} />
              </div>
              <div className='name'>{this.props.clubs?.home.name}</div>
            </div>

            {!this.props.videoState.videoIsLive ? (
              <div className='videoInfo'>
                <div className='startVideoInfo'>{videoStartDate}</div>

                {duration && duration.days() > 0 ? (
                  <div className='videoTimer videoTimerDays'>
                    {duration.days()}{' '}
                    {duration.days() <= 1
                      ? i18n.t('tagging.waitScreen.day')
                      : i18n.t('tagging.waitScreen.days')}
                  </div>
                ) : null}

                <div className='videoTimer'>
                  {duration !== undefined
                    ? zeroPad(duration.hours(), 2) +
                      ':' +
                      zeroPad(duration.minutes(), 2) +
                      ':' +
                      zeroPad(duration.seconds(), 2)
                    : '00:00:00'}
                </div>
              </div>
            ) : (
              <div className='videoInfo'>
                <div className='startVideoInfo'>
                  {zeroPad(this.props.timerInformation.minutes, 2)}:
                  {zeroPad(this.props.timerInformation.seconds, 2)}
                </div>
                <div className='scoreInfo'>
                  {this.props.score.home}-{this.props.score.guest}
                </div>

                <div className='tags'>
                  <div className='ai'>
                    <div className='circle'>
                      <div></div>
                    </div>
                    <div className='text'>{i18n.t('tagging.waitScreen.ai')}</div>
                  </div>
                  <div className='live'>
                    <div className='text'>{i18n.t('tagging.waitScreen.live')}</div>
                  </div>
                </div>
              </div>
            )}
            <div className='clubInfo'>
              <div className='logo'>
                <img src={this.props.clubs?.guest.thumbnail} />
              </div>
              <div className='name'>{this.props.clubs?.guest.name}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(EventListContainer)
