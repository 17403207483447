import React, { Component } from 'react'
import i18n from '../../../languages/i18n'
import mobiscroll from '@mobiscroll/react'
import pauseIcon from '../../../img/icons/pause_circle.svg'
import playIcon from '../../../img/icons/play.svg'
import StaigeButton from '../../../components/styles/Button'

import { checkLanguages } from '../../helper/checkLanguages/checkLanguages'
import { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'
import { EVENTTYPES } from '@soccerwatch/common'
import { EVENTSETTINGS } from './eventSettings'

import './TimeMenu.scss'

type EventContainerPropsType = RouteComponentProps & {
  tags: Array<any>
  liveTagging?: boolean
  postTag: (
    eventType: number,
    extraBody?: any,
    secondCurrentTime?: number,
    removeTimerDisabled?: boolean
  ) => void
  playerCurrentTime: number
  videoType: string
  timerInformation: { timer: string; minutes: number; seconds: number }
  handleStartStopPlayer: (type: 'play' | 'pause') => void
}

type EventContainerStateType = {
  showTimeMenu: boolean
  checkBoxItm: any
  disabledBtn: boolean
  playerMomentTime: number | undefined
  showShotclockMenu: boolean
  time: Array<string>
  edit: Array<boolean>
}

type TimeoutOptions = {
  needStart: boolean
}

class EventContainer extends Component<EventContainerPropsType, EventContainerStateType> {
  checkAlertRef = React.createRef<any>()
  refTimeObj: any = []

  // Constructor
  constructor(props: EventContainerPropsType) {
    super(props)
    this.state = {
      showTimeMenu: false,
      checkBoxItm: undefined,
      disabledBtn: false,
      playerMomentTime: undefined,
      showShotclockMenu: false,
      time: [],
      edit: []
    }
  }

  postTag = async (eventType: number, body?: any) => {
    this.setState({ disabledBtn: true })
    try {
      await this.props.postTag(eventType, body)

      setTimeout(() => {
        this.setState({ disabledBtn: false })
      }, 500)
    } catch (error) {
      mobiscroll.alert({
        title: 'Fehler',
        //@ts-ignore
        message: error?.message
      })
      setTimeout(() => {
        this.setState({ disabledBtn: false })
      }, 500)
    }
  }

  handleshowShotClockMenu = () => {
    this.setState({
      showShotclockMenu: !this.state.showShotclockMenu,
      showTimeMenu: false
    })
  }

  handleShowTimeButtonList = () => {
    this.setState({
      showTimeMenu: !this.state.showTimeMenu,
      showShotclockMenu: false,
      edit: []
    })
  }

  handleTimeAdjustment = (time: string | undefined) => {
    if (time === undefined) {
      return
    }
    const hms = time // your input string
    const a = hms.split(':') // split it at the colons
    const sortTags = this.props.tags
      .filter((obj) => obj.startClockWithSeconds >= 0)
      .sort((a, b) => a.timestamp - b.timestamp)
    const currentTime = this.props.liveTagging ? new Date().getTime() : this.props.playerCurrentTime
    let lastTimeTag: any
    for (let i = 0; i < sortTags.length; i++) {
      if (sortTags[i + 1]) {
        if (sortTags[i].timestamp <= currentTime && sortTags[i + 1].timestamp >= currentTime) {
          lastTimeTag = sortTags[i]
        }
      } else if (sortTags.length === i + 1) {
        if (sortTags[i].timestamp <= currentTime) {
          lastTimeTag = sortTags[i]
        }
      }
    }

    if (lastTimeTag) {
      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
      const lastTagTime = currentTime - lastTimeTag.timestamp
      const lock = lastTimeTag.startClockWithSeconds - lastTagTime
      const body = { RowKey: lastTimeTag.RowKey, timestamp: lastTimeTag.timestamp - (lock - seconds) }
      this.props.postTag(lastTimeTag.eventType, body, undefined, true)
    }
  }

  showSetTagCheck = (itm: any) => {
    const currentTime = this.props.liveTagging ? new Date().getTime() : this.props.playerCurrentTime
    this.checkAlertRef.current.instance.show()
    this.setState({
      checkBoxItm: itm,
      playerMomentTime: currentTime,
      showTimeMenu: false
    })
  }

  renderQuickPlayPause = (
    timer: { timer: string; minutes: number; seconds: number },
    timeStartStop: any,
    timeoutOptions: TimeoutOptions,
    disable: boolean
  ) => {
    const time = timer.minutes + ':' + String(timer.seconds).padStart(2, '0')

    if (timeStartStop === undefined || disable) return

    return (
      <div className={`time-setting ${timeoutOptions.needStart && 'time-settingWarn'}`}>
        <div className='setting-header'>
          <div className='header-title'>{i18n.t('tagging.eventContainer.timeMenu.timer')}</div>
          <span className='mbsc-ic mbsc-ic-ion-ios7-information-outline' />
        </div>
        <div className='setting-timeInput'>
          <div className='timeInput-input'>
            <div className='input-time'>{time}</div>
          </div>
          <a
            // style={itm.start.set ? { cursor: 'default', color: '#555355' } : {}}
            onClick={() => this.postTag(timeStartStop.eventType.id, timeStartStop.eventType)}
          >
            {!timeStartStop.stopType ? (
              <span className='mbsc-ic mbsc-ic-play2' />
            ) : (
              <span className='mbsc-ic mbsc-ic-pause' />
            )}
          </a>
        </div>

        {this.props.liveTagging && (
          <div className={`quick-startStop ${!timeStartStop.stopType && 'quick-startStop-play'}`}>
            <StaigeButton
              // label='Zeit Pausieren'
              label={
                !timeStartStop.stopType
                  ? i18n.t('tagging.eventContainer.timeMenu.quickStart')
                  : i18n.t('tagging.eventContainer.timeMenu.quickEnd')
              }
              icon={!timeStartStop.stopType ? playIcon : pauseIcon}
              size='large'
              iconMargin='0px -10px 0px 6px'
              round
              iconRight
              disabled={this.state.disabledBtn}
              onClick={() => this.postTag(timeStartStop.eventType.id, timeStartStop.eventType)}
            />
          </div>
        )}
      </div>
    )
  }

  render() {
    const name = checkLanguages() === 'de' ? 'name' : 'nameEn'

    const startStopTags = EVENTSETTINGS[this.props.videoType].eventTimeBeta
      .map((itm, index) => {
        return {
          title: itm[name],
          start: { event: itm.eventTypes.start, set: false },
          stop: { event: itm.eventTypes.end, set: false }
        }
      })
      .map((events) => {
        const tags = this.props.tags
        events.start = { ...events.start, set: tags.find((tag) => tag.eventType === events.start.event.id) }
        events.stop = {
          ...events.stop,
          set: tags.find((tag) => events.stop.event && tag.eventType === events.stop.event.id)
        }
        return events
      })

    let inPlay = false // true
    const startStopButton = startStopTags.find((a) => {
      // only for clockReverse
      if (a.start.event.clockReverse) {
        inPlay = false
        return (
          a.start.set === undefined &&
          this.props.timerInformation.minutes === 0 &&
          this.props.timerInformation.seconds === 0
        )
      }
      // for start & stop
      return a.start.set === undefined || a.stop.set === undefined
    })

    let startStopButtonyx: any
    if (startStopButton && startStopButton.start.set === undefined) {
      startStopButtonyx = { event: { ...startStopButton.start }, title: startStopButton.title, start: true }
    } else if (
      startStopButton &&
      startStopButton.stop.event !== undefined &&
      startStopButton.stop.set === undefined
    ) {
      startStopButtonyx = { event: { ...startStopButton.stop }, title: startStopButton.title, start: false }
      inPlay = false
    }

    // remap config & timeTagsAddition
    const tags: Array<any> = []
    this.props.tags.map((res) => {
      const findEvent = EVENTTYPES.filter((event) => event.eventType === res.eventType)[0]
      tags.push({
        ...res,
        _startClockWithSeconds: findEvent?.startClockWithSeconds,
        _startClock: findEvent?.startClock,
        _stopClock: findEvent?.stopClock
      })
    })

    const sortTags = tags // this.props.tags
      .filter(
        (obj) =>
          obj.startClock ||
          obj.stopClock ||
          obj.startClockWithSeconds !== undefined ||
          obj._startClock ||
          obj._stopClock ||
          obj._startClockWithSeconds !== undefined
      )
      .sort((a, b) => a.timestamp - b.timestamp)
    const currentTime = this.props.liveTagging ? new Date().getTime() : this.props.playerCurrentTime

    let lastTimeTag
    for (let i = 0; i < sortTags.length; i++) {
      if (sortTags[i + 1]) {
        if (sortTags[i].timestamp <= currentTime && sortTags[i + 1].timestamp >= currentTime) {
          lastTimeTag = sortTags[i]
        }
      } else if (sortTags.length === i + 1) {
        if (sortTags[i].timestamp <= currentTime) {
          lastTimeTag = sortTags[i]
        }
      }
    }

    // render start / stop Timer
    const startOrStop =
      lastTimeTag !== undefined ? (lastTimeTag.stopClock ? 'startTimer' : 'stopTimer') : 'startTimer'

    const eventTimeStartStop: any = EVENTSETTINGS[this.props.videoType]

    let timeStartStop: any = undefined

    if (lastTimeTag === undefined) {
      // render nothing
    } else {
      // render start / stop icon
      if (eventTimeStartStop.eventOnlyStartStopTimer) {
        timeStartStop = eventTimeStartStop.eventOnlyStartStopTimer[startOrStop]
      }
    }

    const allowTimeout = Boolean(EVENTSETTINGS[this.props.videoType].eventTimeOut)
    let timeoutOptions: TimeoutOptions = {
      needStart: false
    }

    if (allowTimeout && !inPlay) {
      if (timeStartStop && timeStartStop.eventType.startClock) {
        timeoutOptions.needStart = true
        if (startStopButtonyx) {
          startStopButtonyx.event.event = {
            id: EVENTSETTINGS[this.props.videoType].eventTimeOut?.end.eventType.id,
            startClock: true
          }
        } else {
          startStopButtonyx = {
            event: {
              event: {
                id: EVENTSETTINGS[this.props.videoType].eventTimeOut?.end.eventType.id,
                startClock: true
              }
            },
            title: 'Timeout'
          }
        }
      }
    }

    return (
      <div>
        <div className='wrapper-quickTime'>
          {this.renderQuickPlayPause(this.props.timerInformation, timeStartStop, timeoutOptions, inPlay)}
          <div
            className={`quickTime-container ${
              timeoutOptions.needStart && !inPlay && 'quickTime-containerWarn'
            }`}
            onClick={this.handleShowTimeButtonList}
          >
            <div className='container-timerArt'>
              {startStopButtonyx !== undefined ? (
                <>
                  <div className='timerArt-title'>{startStopButtonyx.title}:</div>
                  <div className='timerArt-button'>
                    <mobiscroll.Button
                      className={'round small'}
                      onClick={(event) => {
                        this.postTag(startStopButtonyx.event.event.id, startStopButtonyx.event.event)
                        event.stopPropagation()
                      }}
                      disabled={this.state.disabledBtn}
                    >
                      {startStopButtonyx.start
                        ? i18n.t('tagging.eventContainer.timeMenu.start')
                        : timeoutOptions.needStart
                        ? i18n.t('tagging.eventContainer.timeMenu.endTimeout')
                        : i18n.t('tagging.eventContainer.timeMenu.end')}
                    </mobiscroll.Button>
                  </div>
                </>
              ) : null}
            </div>
            <div>
              {this.state.showTimeMenu ? (
                <div className='mbsc-ic mbsc-ic-fa-angle-up' />
              ) : (
                <div className='mbsc-ic mbsc-ic-fa-angle-down' />
              )}
            </div>
          </div>
        </div>

        {this.state.showTimeMenu ? (
          <div className='container-box'>
            <div className='box-title'>{i18n.t('tagging.eventContainer.timeMenu.playTime')}</div>
            {startStopTags.map((itm, index) => {
              if (itm.start.event.startClockWithSeconds === undefined) {
                return
              }

              return (
                <div className='box-time' key={index}>
                  <div className='time-setting'>
                    <div className='setting-header'>
                      <div className='header-title'>{i18n.t('tagging.eventContainer.timeMenu.timer')}</div>
                      <span className='mbsc-ic mbsc-ic-ion-ios7-information-outline' />
                    </div>
                    <div className='setting-timeInput'>
                      <div className='timeInput-input'>
                        <input
                          style={itm.start.set ? { cursor: 'default', color: '#555355' } : {}}
                          onChange={(e) => {
                            let value = e.target.value
                            if (value.length === 0) {
                              const time = this.state.time
                              time[index] = '0'
                              this.setState({ time })
                              return
                            }

                            value = String(parseInt(value))

                            if (Number(value) <= 999) {
                              const time = this.state.time
                              time[index] = value
                              this.setState({ time })
                            }
                          }}
                          type={'number'}
                          value={
                            this.state.time[index] ||
                            (itm.start.event.startClockWithSeconds !== undefined &&
                              itm.start.event.startClockWithSeconds / 60) ||
                            0
                          }
                          // style={this.state.time[index] && this.state.time[index].length < 2 ? { width: 14 } : this.state.time[index] && this.state.time[index].length < 3 ? { width: 24 } : { width: 34 }}
                          max={999}
                          ref={(ref) => (this.refTimeObj[index] = ref)}
                          disabled={!this.state.edit[index]}
                        />
                        <div
                          className='timeInput-info'
                          style={itm.start.set ? { cursor: 'default', color: '#555355' } : {}}
                        >
                          Min
                        </div>
                      </div>

                      <a
                        style={itm.start.set ? { cursor: 'default', color: '#555355' } : {}}
                        onClick={() => {
                          if (itm.start.set) return
                          const edit = this.state.edit
                          edit[index] = !edit[index]
                          this.setState({ edit })

                          if (this.state.edit[index]) {
                            this.refTimeObj[index].focus()
                            setTimeout(() => this.refTimeObj[index].focus(), 100)
                          }
                        }}
                      >
                        {this.state.edit[index] ? (
                          <span className='mbsc-ic mbsc-ic-fa-check-circle-o active' />
                        ) : (
                          <span className='mbsc-ic mbsc-ic-material-edit' />
                        )}
                      </a>

                      {/*<span className='mbsc-ic mbsc-ic-play2' />*/}
                    </div>
                  </div>
                  <div className='time-container'>
                    <div className='title'>{itm.title}</div>
                    <div className='buttons'>
                      <mobiscroll.Button
                        className={!itm.start.set ? 'round small' : 'round small full'}
                        disabled={itm.start.set}
                        style={{ width: '100%' }}
                        onClick={() => {
                          const body = {
                            ...itm.start.event,
                            startClockWithSeconds: this.state.time[index]
                              ? this.state.time && Number(this.state.time[index]) * 60
                              : itm.start.event.startClockWithSeconds
                          }
                          this.postTag(itm.start.event.id, body)
                          this.handleShowTimeButtonList()
                        }}
                      >
                        {i18n.t('tagging.eventContainer.timeMenu.start')}
                      </mobiscroll.Button>
                      {itm.stop.event !== undefined ? (
                        <>
                          <div className='midline' />
                          <mobiscroll.Button
                            className={!itm.stop.set ? 'round small' : 'round small full'}
                            disabled={itm.stop.set || !itm.start.set}
                            style={{ width: '100%' }}
                            onClick={() => {
                              if (itm.stop.event !== undefined) {
                                this.postTag(itm.stop.event.id, itm.stop.event)
                              }
                              this.handleShowTimeButtonList()
                            }}
                          >
                            {i18n.t('tagging.eventContainer.timeMenu.end')}
                          </mobiscroll.Button>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    )
  }
}

export default withRouter(EventContainer)
