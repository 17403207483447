import React, { ChangeEvent } from 'react'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import * as mobiscrollV5 from '@mobiscroll/react5'

import './filteredSelect.scss'
import AiswSpinner from '../../helper/aiswSpinner/aiswSpinner'
import { apiGet, Wait } from '../../../helper/GlobalHelper'

type FilteredSelectProps<Item> = {
  id?: string
  inputId?: string
  label: string
  subLabel?: string
  value?: Item
  className?: string
  urlParameters?: string
  disabled?: boolean
  valueProp?: keyof Item
  labelProp?: keyof Item
  labelSuffixProp?: keyof Item
  labelSuffixDelimiter?: string
  showSuffixInValue?: boolean
  disableSearch?: boolean
  disableEntryCreation?: boolean
  defaultSelection?: Item[]
  newItemLabel?: string
  keepOpenOnNewEntry?: boolean
  allowNoneChoice?: boolean
  noneChoiceLabel?: string
  filterResultsFunction?: (result: Item[]) => Item[]
  onNewEntryNeeded: () => void
  onChange: (value: Item) => void
  placeholder?: string
}
type FilteredSelectState<Item> = {
  items: Item[]
  open: boolean
  loading: boolean
  filter: string
}

type NEW_ITEM = {
  NEW_ITEM: boolean
}

type NONE_CHOICE = {
  NONE_CHOICE: boolean
}

export default class FilteredSelect<Item> extends React.Component<
  FilteredSelectProps<Item>,
  FilteredSelectState<Item>
> {
  valueProp: keyof Item
  labelProp: keyof Item
  lastChange = 0
  constructor(props: FilteredSelectProps<Item>) {
    super(props)
    this.state = {
      items: [],
      open: false,
      loading: false,
      filter: ''
    }

    this.valueProp = this.props.valueProp ?? ('RowKey' as keyof Item)
    this.labelProp = this.props.labelProp ?? ('name' as keyof Item)
  }

  componentDidMount() {}

  componentDidUpdate(prevProps: Readonly<FilteredSelectProps<Item>>) {
    if (prevProps.valueProp !== this.props.valueProp) {
      this.valueProp = this.props.valueProp ?? ('RowKey' as keyof Item)
    }
    if (prevProps.labelProp !== this.props.labelProp) {
      this.labelProp = this.props.labelProp ?? ('name' as keyof Item)
    }
  }

  getNewItemSignifier() {
    const label = this.props.newItemLabel ?? i18n.t('filteredSelect.noMatch')
    return {
      [this.valueProp]: '%%NEW_ITEM%%',
      [this.labelProp]: label,
      NEW_ITEM: true
    }
  }

  getNoneChoiceSignifier() {
    const label = this.props.noneChoiceLabel ?? i18n.t('filteredSelect.noneChoice')
    return {
      [this.valueProp]: '%%NONE_CHOICE%%',
      [this.labelProp]: label,
      NONE_CHOICE: true
    }
  }

  onFilterChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const filter = e.currentTarget.value
    this.setState({ filter })
    if (filter === '') {
      return
    }
    const lastChange = this.lastChange
    await Wait(300)
    if (lastChange !== this.lastChange) {
      return
    }
    this.lastChange = new Date().getTime()
    this.setState({ loading: true })
    const url = `https://europe-west3-aisw-ww-prod.cloudfunctions.net/api_search/search?query=${filter}${this.props.urlParameters}`
    let items = await apiGet<any>(url)
      .then((res) => {
        return res.hits.hits.map((hit: any) => {
          return hit._source
        })
      })
      .catch((err) => {
        if (err.response.status !== 404) {
          console.error(err)
        }
        return [] as Item[]
      })

    if (!Array.isArray(items)) {
      items = [] as Item[]
    }
    if (!this.props.disableEntryCreation) {
      // @ts-ignore
      items.push(this.getNewItemSignifier())
    }
    if (this.props.allowNoneChoice) {
      // @ts-ignore
      items.unshift(this.getNoneChoiceSignifier())
    }
    if (this.props.filterResultsFunction) {
      items = this.props.filterResultsFunction(items)
    }
    this.setState({ items, loading: false })
  }

  onValueChange = (item: Item | NEW_ITEM | NONE_CHOICE) => {
    if ((item as NEW_ITEM).NEW_ITEM) {
      this.props.onNewEntryNeeded()
      if (!this.props.keepOpenOnNewEntry) {
        this.closeModal()
      }
      return
    }
    if ((item as NONE_CHOICE).NONE_CHOICE) {
      this.props.onChange({} as Item)
    }
    this.props.onChange(item as Item)
    this.closeModal()
  }

  onMainInputClick = () => {
    if (!this.props.disabled) {
      this.openModal()
    }
  }

  openModal = () => {
    this.setState({ open: true })
  }

  closeModal = () => {
    this.setState({ open: false, filter: '' })
  }

  getSuffix = (itm: Item) => {
    // @ts-ignore
    if (this.props.labelSuffixProp && !itm.NEW_ITEM) {
      const labelSuffixDelimiter = this.props.labelSuffixDelimiter ?? ''
      return `${labelSuffixDelimiter}${itm[this.props.labelSuffixProp]}`
    }
    return ''
  }

  asSelectionComponent = (itm: Item) => {
    return (
      <div key={itm[this.valueProp] as unknown as string}>
        {
          // @ts-ignore
          itm[this.valueProp] === '%%NEW_ITEM%%' && <hr />
        }
        <div
          onClick={() => {
            this.onValueChange(itm)
          }}
        >
          <p className='option'>
            {itm[this.labelProp]}
            {this.getSuffix(itm)}
          </p>
        </div>
      </div>
    )
  }

  getSelection = () => {
    if (this.state.filter !== '') {
      return this.state.items.map(this.asSelectionComponent)
    } else if (this.props.defaultSelection) {
      const selection = JSON.parse(JSON.stringify(this.props.defaultSelection)) as Item[]
      if (this.props.disableSearch) {
        if (!this.props.disableEntryCreation) {
          // @ts-ignore
          selection.push(this.getNewItemSignifier())
        }
      }
      // @ts-ignore
      this.props.allowNoneChoice && selection.unshift(this.getNoneChoiceSignifier())
      return selection.map(this.asSelectionComponent)
    }
  }

  render() {
    const { placeholder } = this.props
    let showValue = this.props.value
      ? this.props.value[this.labelProp]
      : this.props.allowNoneChoice
      ? i18n.t('filteredSelect.noneChoice')
      : placeholder
      ? placeholder
      : i18n.t('filteredSelect.pleaseSelect')
    if (this.props.value && this.props.showSuffixInValue) {
      showValue += this.getSuffix(this.props.value)
    }

    return (
      <div id={this.props.id} className={`filteredSelect ${this.props.className}`}>
        <mobiscroll.Input
          id={this.props.inputId}
          value={showValue as string}
          onClick={this.onMainInputClick}
          disabled={this.props.disabled}
        >
          {this.props.label}
        </mobiscroll.Input>
        <mobiscrollV5.Popup
          isOpen={this.state.open}
          onClose={this.closeModal}
          theme='ios'
          themeVariant='dark'
        >
          {!this.props.disableSearch && (
            <>
              <div className='filterSelect-content'>
                <span className='headline'>{this.props.label}</span>
                <br />
                {this.props.subLabel && <span className='subLabel'>{this.props.subLabel}</span>}
                <mobiscroll.Input
                  className='lessMargin'
                  value={this.state.filter}
                  onChange={this.onFilterChange}
                >
                  Suche
                </mobiscroll.Input>
                {this.state.loading && <AiswSpinner />}
              </div>
            </>
          )}
          <div>{this.getSelection()}</div>
        </mobiscrollV5.Popup>
      </div>
    )
  }
}
