import React, { RefObject } from 'react'
import staigeLogo from '../img/logo/staige-logo.svg'
import staigeLogoText from '../img/logo/staige-logo-text.svg'
import iconCalendar from '../img/icons/menu/calendar.svg'
import iconWindow from '../img/icons/menu/window.svg'
import iconGear from '../img/icons/menu/gear.svg'
import iconCamera from '../img/icons/menu/camera.svg'
import iconHelp from '../img/icons/menu/help.svg'
import iconDots from '../img/icons/menu/dots.svg'
import iconContracts from '../img/icons/menu/contracts.svg'
import i18n from '../languages/i18n'
import AiswSpinner from '../pages/helper/aiswSpinner/aiswSpinner'
import downIcon from '../img/icons/chevron_down.svg'

import { Link } from 'react-router-dom'
import { ContractContainer } from '../services/contractContainer'
import { ClubContainer } from '../services/clubContainer'
import { RouteNames } from '../pages/menu/Menu'

import './Sidebar.scss'

type SidebarPropTypes = {
  contractContainer: ContractContainer
  clubContainer: ClubContainer
  routeFilter: string[]
  onContractChange: any
}

type SidebarStateTypes = {
  clubLogo: string
  contractName: string
  openDropdownContract: boolean
  openStaigeApps: boolean
}

export default class Sidebar extends React.Component<SidebarPropTypes, SidebarStateTypes> {
  private refStaigeApps: RefObject<HTMLDivElement>

  constructor(props: SidebarPropTypes) {
    super(props)

    this.state = {
      clubLogo: '',
      contractName: '',
      openDropdownContract: false,
      openStaigeApps: false
    }

    this.refStaigeApps = React.createRef()
  }

  componentDidMount(): void {
    const contract = this.props.contractContainer.getCurrentContract()
    const clubs = this.props.clubContainer.getClubListForCurrentContract()
    let clubLogo = ''

    if (contract && contract.clubIds.length > 1) {
      clubLogo = iconContracts
    } else {
      clubLogo = clubs[0].thumbnail ?? ''
    }

    this.setState({
      clubLogo: clubLogo,
      contractName: contract ? contract.name : ''
    })

    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(): void {
    const contract = this.props.contractContainer.getCurrentContract()
    const clubs = this.props.clubContainer.getClubListForCurrentContract()
    let clubLogo = ''

    if (contract && contract.clubIds.length > 1) {
      clubLogo = iconContracts
    } else {
      clubLogo = clubs[0].thumbnail ?? ''
    }

    if (this.state.clubLogo !== clubLogo) {
      this.setState({
        clubLogo: clubLogo
      })
    }

    if (contract && contract.name !== this.state.contractName) {
      this.setState({
        contractName: contract.name
      })
    }
  }

  renderMenuItem = (icon: string, title: string, href: string, disabled?: boolean) => {
    let active = false
    if (href === '/' || href === '/recordingplan') {
      active = location.pathname === '/' || location.pathname === '/recordingplan'
    } else {
      active = location.pathname.includes(href)
    }

    return (
      <div className='sidebar-menu-item'>
        <div className={`item-highlight ${active ? 'item-highlight-active' : null}`}></div>
        <Link to={href} style={active ? {} : { cursor: 'default' }}>
          <button disabled={disabled} className={`item-wrap ${active ? 'itme-wrap-active' : null}`}>
            <img src={icon} className={`${active ? 'imgActive' : null}`} />
            <div className='wrap-text'>{title}</div>
          </button>
        </Link>
      </div>
    )
  }

  renderMenuItemA = (icon: string, title: string, href: string, disabled?: boolean) => {
    let active = false
    if (href === '/' || href === '/recordingplan') {
      active = location.pathname === '/' || location.pathname === '/recordingplan'
    } else {
      active = location.pathname.includes(href)
    }

    return (
      <div className='sidebar-menu-item'>
        <div className={`item-highlight ${active ? 'item-highlight-active' : null}`}></div>
        <a
          href='https://staige.com/faq/'
          target='_blank'
          rel='noopener noreferrer'
          style={active ? {} : { cursor: 'default' }}
        >
          <button disabled={disabled} className={`item-wrap ${active ? 'itme-wrap-active' : null}`}>
            <img src={icon} className={`${active ? 'imgActive' : null}`} />
            <div className='wrap-text'>{title}</div>
          </button>
        </a>
      </div>
    )
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.refStaigeApps.current && !this.refStaigeApps.current.contains(event.target as Node)) {
      this.setState({ openStaigeApps: false })
    }
  }

  renderMenuItemDropdown = (icon: string, title: string, disabled?: boolean) => {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', gap: 8, width: '100%' }}
        ref={this.refStaigeApps}
      >
        {this.state.openStaigeApps && (
          <div className='sidebar-menu-dropdown'>
            <a
              className='dropdown-item'
              href='https://staige.coach'
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'none' }}
            >
              <div className='item-title'>Staige Coach</div>
            </a>
          </div>
        )}
        <div className='sidebar-menu-item'>
          <div className={`item-highlight`}></div>
          <div style={{ width: '100%' }}>
            <button disabled={disabled} className={`item-wrap `} onClick={this.handleStaigeApps}>
              <img src={icon} />
              <div className='wrap-text'>{title}</div>
            </button>
          </div>
        </div>
      </div>
    )
  }

  handleStaigeApps = () => {
    this.setState({
      openStaigeApps: !this.state.openStaigeApps
    })
  }

  renderDropdown = () => {
    if (this.state.openDropdownContract) {
      const contractList = this.props.contractContainer.state.contracts
      return (
        <div className='contract-list'>
          {contractList.map((contract, index) => {
            let clubLogo = ''
            if (contract.clubIds.length > 1) {
              clubLogo = iconContracts
            } else {
              const club = this.props.clubContainer
                .getClubList()
                .find((club) => club.RowKey === contract.clubIds[0])
              clubLogo = club
                ? club.thumbnail
                : `https://storage.googleapis.com/sw-sc-de-assets/clubLogo/club-${contract.clubIds[0]}.png`
            }

            return (
              <div
                className='list-item'
                key={index}
                onClick={() => {
                  this.props.onContractChange(contract.RowKey)
                  this.setState({
                    openDropdownContract: false
                  })
                }}
              >
                <div className='item-logo'>
                  <img src={clubLogo} />
                </div>
                <div className='item-title' title={contract.name}>
                  {contract.name}
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  }

  render() {
    const { routeFilter } = this.props
    const contractList = this.props.contractContainer.state.contracts
    const contractContainerShow = contractList.length > 1

    return (
      <div className='grid-sidebar'>
        <div className='sidebar-logo'>
          <img src={staigeLogo} />
          <img src={staigeLogoText} />
        </div>

        <div
          className='sidebar-contract'
          style={!contractContainerShow ? { padding: '0px 0px 6px 0px' } : { display: 'none' }}
        ></div>
        <div className='sidebar-contract' style={contractContainerShow ? {} : { display: 'none' }}>
          <div
            className={
              contractList.length > 1 ? 'contract-switch' : 'contract-switch contract-switch-disabled'
            }
            style={this.state.openDropdownContract ? { background: '#363636' } : {}}
            onClick={() => {
              contractList.length > 1
                ? this.setState({ openDropdownContract: !this.state.openDropdownContract })
                : null
            }}
          >
            {this.props.contractContainer.state.loadingData && <AiswSpinner />}
            {!this.props.contractContainer.state.loadingData && (
              <>
                <div className='switch-logo'>
                  <img src={this.state.clubLogo} />
                </div>
                <div className='switch-dropdown'>
                  <div
                    className={`dropdown-title ${
                      this.state.openDropdownContract ? 'dropdown-titleActive' : ''
                    }`}
                  >
                    Contract
                    <img src={downIcon} />
                  </div>
                  <div className='dropdown-subTitle'>{this.state.contractName}</div>
                </div>
              </>
            )}
          </div>
          {this.renderDropdown()}
        </div>

        <div className='sidebar-wrap'>
          <div className='wrap-mainMenu'>
            {this.renderMenuItem(
              iconCalendar,
              i18n.t('sidebar.calendar'),
              '/recordingplan',
              routeFilter && !routeFilter.includes(RouteNames.RecordingPlan)
            )}
            {this.renderMenuItem(
              iconWindow,
              i18n.t('sidebar.sponsors'),
              '/advertisement',
              routeFilter && !routeFilter.includes(RouteNames.Advertisement)
            )}
            {this.renderMenuItem(
              iconCamera,
              i18n.t('sidebar.cameras'),
              '/cameramonitor',
              routeFilter && !routeFilter.includes(RouteNames.CameraMonitor)
            )}
            {this.renderMenuItem(
              iconGear,
              i18n.t('sidebar.administration'),
              '/administration',
              routeFilter && !routeFilter.includes(RouteNames.Administration)
            )}
          </div>
          <div className='wrap-secondMenu'>
            {this.renderMenuItemDropdown(iconDots, i18n.t('sidebar.staigeApps'))}
            {this.renderMenuItemA(iconHelp, i18n.t('sidebar.faq'), 'https://staige.com/faq/', false)}
          </div>
          <div className='app-version'>Ver. {process.env.REACT_APP_VERSION}</div>
        </div>
      </div>
    )
  }
}
