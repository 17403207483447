import React from 'react'
import PageWrapper from '../../../../helper/PageWrapper'
import checkIcon from '../../../../img/icons/check.svg'

import './AboUpgrade.scss'

type AboUpgradePageProps = {
  test?: unknown
}

type AboUpgradeStateType = {
  test?: unknown
}

export default class AboUpgradePage extends React.Component<AboUpgradePageProps, AboUpgradeStateType> {
  constructor(props: AboUpgradePageProps) {
    super(props)
    this.state = {}
  }

  renderBenefitBarItem(text: string) {
    return (
      <div className='list-item'>
        <div className='item-icon'>
          <img src={checkIcon} />
        </div>
        <div className='item-text'>{text}</div>
      </div>
    )
  }

  renderBottomBar() {
    return <div style={{ height: '38px' }}></div>
  }

  render() {
    return (
      <PageWrapper
        headerTitle={`Zurück zu Aboverwaltung`}
        linkTo={`/administration/subscriptionmanagement`}
        simpleTopbar
        bottomBar={this.renderBottomBar}
      >
        <div className='page-aboUpgrade'>
          <div className='content-wrap'>
            <div className='wrap-content'>
              <div className='content-header'>
                <div className='header-title'>Abo Upgrade</div>
                <div className='header-subTitle'>Pro LTE</div>
              </div>

              <div className='content-body'>
                <div className='body-benefitBar'>
                  <div className='benefitBar-list'>
                    {this.renderBenefitBarItem('Livestreaming')}
                    {this.renderBenefitBarItem('Sponsoring-Tool')}
                    {this.renderBenefitBarItem('10 Spiele/Monat')}
                    {this.renderBenefitBarItem('Staige Coach')}
                  </div>
                </div>

                <div className='body-stripe'>
                  <div className='stripe-title'>Stripe...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    )
  }
}
