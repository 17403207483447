import React from 'react'

import './Switch.scss'

type staigeSwitchPropTypes = {
  handleClick?: (value: boolean) => void
  onClick?: () => void
  stopPropagation?: boolean
  size?: 'small' | 'medium'
  checked?: boolean
  label?: string
  disabled?: boolean
}

type staigeSwitchStateTypes = {
  checked: boolean
}

export default class StaigeSwitch extends React.Component<staigeSwitchPropTypes, staigeSwitchStateTypes> {
  constructor(props: staigeSwitchPropTypes) {
    super(props)

    this.state = {
      checked: false
    }
  }

  componentDidMount(): void {
    if (this.props.checked !== undefined) {
      this.setState({
        checked: this.props.checked
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<staigeSwitchPropTypes>): void {
    if (this.props.checked !== undefined && prevProps !== undefined) {
      if (this.props.checked !== prevProps.checked) {
        this.setState({
          checked: this.props.checked
        })
      }
    }
  }

  handleCheck = () => {
    const { handleClick } = this.props
    if (handleClick) {
      handleClick(!this.state.checked)
    }
    this.setState({
      checked: !this.state.checked
    })
  }

  render() {
    const { size, stopPropagation, onClick, label, disabled } = this.props
    const { checked } = this.state

    return (
      <div className={`staige-switch staige-switch-${size} ${disabled ? 'staige-switchDisabled' : ''}`}>
        {label && <div className='switch-label'>{label}</div>}

        <div
          className='switch-chckbox'
          onClick={(event) => {
            if (disabled) return
            if (stopPropagation) {
              event.stopPropagation()
              event.nativeEvent.stopImmediatePropagation()
            }

            if (onClick) {
              onClick()
            }
            this.handleCheck()
          }}
        >
          <input type='checkbox' checked={checked} disabled={disabled} />
          <span className={`slider round ${disabled ? 'sliderDisabled' : ''}`}></span>
        </div>
      </div>
    )
  }
}
