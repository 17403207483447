import React, { Component } from 'react'
import PageWrapper from '../../helper/PageWrapper'
import camK1 from '../../img/k1.png'
import camK1_K2 from '../../img/k2_.png'
import camK2M from '../../img/k2m.png'
import camIp from '../../img/ip.png'
import camK2X from '../../img/k2x.png'
import i18n from '../../languages/i18n'
import AiswSpinner from '../helper/aiswSpinner/aiswSpinner'
import plusIcon from '../../img/icons/plus.svg'
import editIcon from '../../img/icons/edit.svg'
import deleteIcon from '../../img/icons/delete.svg'
import StaigeButton from '../../components/styles/Button'
import StaigeBadge from '../../components/styles/Badge'
import mobiscroll from '@mobiscroll/react'
import StaigeModal from '../../components/styles/Modal'

import contractContainer, { ContractContainer } from '../../services/contractContainer'
import { CameraData, Role } from '@soccerwatch/common'
import { CameraContainer } from '../../services/cameraContainer'
import { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'
import { searchAndSortGetCallAs } from '../../services/serviceHelper'
import { deleteCameraCornerPoints, getCameraCornerPointsAll, postCameraSpeedTest } from '../../api/api-camera'
import { isAxiosError } from '../../helper/UtilityFunctions'

import './Camera.scss'

type fieldmasks = Array<{
  fieldName: string
  fieldCorners: number[]
}>

type CameraMonitorStateType = {
  camera: CameraData | undefined
  loadingData: boolean
  speedTestIsRunning: boolean
  speedTestFailed: boolean
  cameraNetworkSpeed?: number
  loadingFields: boolean
  fieldmasks: fieldmasks
  loadingDelete: Array<string>
}

type CameraMonitorPropsType = RouteComponentProps & {
  id?: number | null
  cameraContainer: CameraContainer
  contractContainer: ContractContainer
}

class CameraMonitor extends Component<CameraMonitorPropsType, CameraMonitorStateType> {
  // This is actually a Ref<mobiscroll.PopupComponent>. However,
  // mobiscroll's type definitions are incorrect and are missing
  // the instance property, which is used in this component.
  // Thus, the any.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  popup = React.createRef<any>()

  // Constructor
  constructor(props: CameraMonitorPropsType) {
    super(props)
    this.state = {
      camera: undefined,
      loadingData: true,
      speedTestIsRunning: false,
      cameraNetworkSpeed: undefined,
      speedTestFailed: false,
      loadingFields: true,
      fieldmasks: [],
      loadingDelete: []
    }
  }

  private refConfirmDeleteModal = React.createRef<any>()

  getCurrentCamera = (id: string) => {
    return this.props.cameraContainer
      .getCameraListForCurrentContract()
      .filter((cam: CameraData) => cam.RowKey === id)[0]
  }

  componentDidMount() {
    this.getCornerPoints(String(this.props.id))
    this.setState({
      camera: this.getCurrentCamera(String(this.props.id)),
      loadingData: this.props.cameraContainer.state.loadingData
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps: CameraMonitorPropsType) {
    if (
      this.props.cameraContainer.state.loadingData !== this.state.loadingData &&
      this.props.contractContainer.state.loadingData !== this.state.loadingData
    ) {
      this.setState({
        loadingData: this.props.cameraContainer.state.loadingData
      })
    }

    const cam = this.getCurrentCamera(String(this.props.id))
    if (JSON.stringify(cam) !== JSON.stringify(this.state.camera)) {
      this.setState({
        camera: cam
      })
    }
  }

  getCornerPoints = async (camId: string) => {
    try {
      const callAs = searchAndSortGetCallAs([
        Role.admin,
        contractContainer.getHighestRelevantRoleForCurrentContract()
      ])
      const res = await getCameraCornerPointsAll(camId, callAs)
      if (res.status === 200) {
        const fieldmasks: fieldmasks = []
        res.data.forEach((dataset) => {
          fieldmasks.push({ fieldName: dataset.fieldName, fieldCorners: dataset.Corners.corners })
        })

        this.setState({ fieldmasks: fieldmasks, loadingFields: false })
        return
      }
      console.warn('Get Cornerpoints for', camId, ' Failed with Status', res.status)
      this.setState({ loadingFields: false })
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        console.warn('Get Cornerpoints for', camId, ' Failed with Status', err.response?.status)
      } else {
        console.warn('An unknown error occurred:', err)
      }
      this.setState({ loadingFields: false })
    }
  }

  showPopup = () => {
    if (!this.state.speedTestIsRunning) {
      this.startSpeedTest()
    }

    this.popup.current?.instance.show()
  }

  updateSpeedTest = async (
    cameraID: number,
    onFinished: (uploadSpeed: number) => void,
    onError: (data: unknown) => void
  ) => {
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    const data = await postCameraSpeedTest(cameraID, callAs)

    if (data.success && typeof data.detail !== 'string') {
      if (data.detail.speedtest === 'finished' && data.detail.upload_speed_mbit) {
        onFinished(data.detail.upload_speed_mbit)
      } else {
        setTimeout(() => this.updateSpeedTest(cameraID, onFinished, onError), 3000)
      }
    } else {
      onError(data)
    }
  }

  executeSpeedTest = (cameraID: number): Promise<number> => {
    return new Promise((resolve, reject) => {
      this.updateSpeedTest(cameraID, resolve, reject)
    })
  }

  startSpeedTest = async () => {
    this.setState({
      speedTestIsRunning: true
    })

    try {
      const speed = await this.executeSpeedTest(Number(this.props.id))
      this.setState({
        speedTestIsRunning: false,
        cameraNetworkSpeed: speed
      })
    } catch (e) {
      console.error(e)
      this.setState({ speedTestIsRunning: false, speedTestFailed: true })
    }
  }

  getCamerImg = (camera?: CameraData) => {
    if (camera === undefined) {
      return <img src={camK2M} />
    }

    let cameraImg
    switch (camera.hardwarePlatform) {
      case 'ip':
      case 'qube':
        cameraImg = <img src={camIp} />
        break
      case 'k1':
        cameraImg = <img src={camK1} />
        break
      case 'k2m':
        cameraImg = <img src={camK2M} />
        break
      case 'k2x':
        cameraImg = <img src={camK2X} />
        break
      default:
        cameraImg = <img src={camK1_K2} />
        break
    }
    return cameraImg
  }

  renderFieldMaskItem(def: boolean, name: string) {
    const defaultItem = def
    return (
      <div key={name} className={`list-fieldmaskItem ${defaultItem ? 'list-fieldmaskItemDefault' : ''}`}>
        {this.state.loadingDelete.find((n) => n === name) ? (
          <div className='fieldmaskItem-delete'>
            <AiswSpinner />
          </div>
        ) : null}

        <div className='fieldmaskItem-content'>
          <div className='content-header'>
            {defaultItem ? (
              <>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                >
                  <mask id='mask0_1237_149767' maskUnits='userSpaceOnUse' x='0' y='0' width='16' height='16'>
                    <rect width='16' height='16' fill='#D9D9D9' />
                  </mask>
                  <g mask='url(#mask0_1237_149767)'>
                    <path
                      d='M7.99935 15.3335L3.19935 11.7335C3.03268 11.6113 2.90213 11.4557 2.80768 11.2668C2.71324 11.0779 2.66602 10.8779 2.66602 10.6668V2.66683C2.66602 2.30016 2.79657 1.98627 3.05768 1.72516C3.31879 1.46405 3.63268 1.3335 3.99935 1.3335H11.9993C12.366 1.3335 12.6799 1.46405 12.941 1.72516C13.2021 1.98627 13.3327 2.30016 13.3327 2.66683V10.6668C13.3327 10.8779 13.2855 11.0779 13.191 11.2668C13.0966 11.4557 12.966 11.6113 12.7993 11.7335L7.99935 15.3335ZM7.99935 13.6668L11.9993 10.6668V2.66683H3.99935V10.6668L7.99935 13.6668ZM7.29935 10.0002L11.066 6.2335L10.1327 5.26683L7.29935 8.10016L5.89935 6.70016L4.93268 7.6335L7.29935 10.0002Z'
                      fill='#FF3517'
                    />
                  </g>
                </svg>
                <div className='header-defaultText'>{i18n.t('camera.fieldmaskDefault')}</div>
              </>
            ) : (
              <div className='header-normalText'>{i18n.t('camera.fieldmaskOptinal')}</div>
            )}
          </div>
          <div className='content-title'>{name}</div>
        </div>
        <div className='fieldmaskItem-action'>
          <StaigeButton
            style='ghost'
            icon={editIcon}
            iconMargin='0 -4px 0 -4px'
            linkTo={`/cameramonitor/liveview/${this.props.id}/${defaultItem ? 'default' : name}`}
          />
          {defaultItem ? null : (
            <StaigeButton
              style='ghost'
              icon={deleteIcon}
              iconMargin='0 -4px 0 -4px'
              onClick={() => this.refConfirmDeleteModal.current.handleOpenModal(name)}
            />
          )}
        </div>
      </div>
    )
  }

  deleteField = async (fieldName?: string) => {
    if (fieldName === undefined) return
    if (fieldName === 'default') {
      return
    }
    this.setState({ loadingDelete: [...this.state.loadingDelete, fieldName] })
    const callAs = searchAndSortGetCallAs([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    const res = await deleteCameraCornerPoints(String(this.props.id), fieldName, callAs)
    if (res.status !== 200) {
      const newLoadingDelete = this.state.loadingDelete.filter((e) => e !== fieldName)
      this.setState({
        loadingDelete: newLoadingDelete
      })
      console.error('Could not Delete Field:', res)
      return
    }

    const newLoadingDelete = this.state.loadingDelete.filter((e) => e !== fieldName)
    const fieldmasksRemove = this.state.fieldmasks.filter((field) => field.fieldName !== fieldName)
    this.setState({
      loadingDelete: newLoadingDelete,
      fieldmasks: fieldmasksRemove
    })
  }

  render() {
    if (this.state.loadingData) {
      return (
        <PageWrapper
          headerTitle={`${i18n.t('camera.headerTitleLoad')} ${this.props.id}`}
          linkTo='/cameramonitor'
        >
          <div style={{ width: '100%', height: '100%' }}>
            <AiswSpinner />
          </div>
        </PageWrapper>
      )
    }

    if (!this.state.loadingData && this.state.camera === undefined) {
      return (
        <PageWrapper
          headerTitle={`${i18n.t('camera.headerTitleLoad')} ${this.props.id}`}
          linkTo='/cameramonitor'
        >
          <div className='page-cameraErr'>
            {i18n.t('camera.err')} <br />
            {i18n.t('camera.errText')}
          </div>
        </PageWrapper>
      )
    }
    const currentContract = this.props.contractContainer.getContract()
    return (
      <PageWrapper
        headerTitle={`${i18n.t('camera.headerTitle')} ${this.state.camera?.hardwarePlatform} - ${
          this.state.camera?.RowKey
        }`}
        linkTo='/cameramonitor'
      >
        <StaigeModal
          ref={this.refConfirmDeleteModal}
          onConfirm={this.deleteField}
          confirmText={i18n.t('general.delete') + ' ' + i18n.t('general.confirm')}
          title={i18n.t('camera.deleteModal.title')}
          text={i18n.t('camera.deleteModal.text')}
        />
        <div className='page-camera'>
          <div className='camera-container'>
            <div className='container-action'>
              <StaigeButton label='Speedtest' style='ghost' onClick={this.showPopup} />
            </div>
            <div className='container-content'>
              <div className='content-column01'>
                <div className='column01-top'>
                  <div className='top-title'>{i18n.t('camera.fieldmask')}</div>
                  <StaigeButton
                    label={i18n.t('camera.addFieldmaskBtn')}
                    style='ghost'
                    icon={plusIcon}
                    iconMargin='0 0 0 -4px'
                    linkTo={`/cameramonitor/liveview/new/${this.props.id}`}
                  />
                </div>

                <div className='column01-list'>
                  {this.renderFieldMaskItem(true, 'Standard')}

                  <div className='list-list'>
                    {!this.state.loadingFields ? (
                      this.state.fieldmasks
                        .filter((field) => field.fieldName !== 'default')
                        .map((field) => {
                          return this.renderFieldMaskItem(false, field.fieldName)
                        })
                    ) : (
                      <div style={{ width: '100%', margin: '40px 0' }}>
                        <AiswSpinner />
                      </div>
                    )}
                  </div>
                </div>
                {currentContract && currentContract.mayDeactivateAi && (
                  <div className='column01-list'>
                    <div className='column01-top'>
                      <div className='top-title'>{i18n.t('camera.homePosition')}</div>
                    </div>
                    <div className='column01-list'>
                      <div className='list-fieldmaskItem'>
                        <div className='fieldmaskItem-content'>
                          {/* <div className='content-header'>
                        </div> */}
                          <div className='content-title'>
                            {this.state.camera?.homePositionName ?? 'Home Position'}
                          </div>
                        </div>
                        <div className='fieldmaskItem-action'>
                          <StaigeButton
                            style='ghost'
                            icon={editIcon}
                            iconMargin='0 -4px 0 -4px'
                            linkTo={`/cameramonitor/homeposition/${this.props.id}`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className='content-column02'>
                <div className='column02-camera'>
                  <div className='camera-topInfo'>
                    <div className='topInfo-text'>{this.state.camera?.hardwarePlatform}</div>
                    <div className='topInfo-text'>ID {this.state.camera?.RowKey}</div>
                  </div>
                  <div className='camera-field'>
                    {this.state.camera?.address ?? 'Adresse nicht vorhanden'}
                  </div>
                  <div className='camera-img'>
                    <div className='img-background'>
                      <svg
                        width='327'
                        height='102'
                        viewBox='0 0 327 102'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <mask
                          id='mask0_1608_1492'
                          maskUnits='userSpaceOnUse'
                          x='0'
                          y='0'
                          width='327'
                          height='102'
                        >
                          <rect y='0.517212' width='327' height='101.483' rx='3.75862' fill='#F6F6F6' />
                        </mask>
                        <g mask='url(#mask0_1608_1492)'>
                          <path
                            d='M65.7759 51.0201L22.2832 8.03448L9.39655 21.0912L39.8235 51.1394L9.69485 81.6049L22.7605 94.4828L65.7759 51.0201Z'
                            fill='#151315'
                          />
                          <path
                            d='M114.638 51.0201L71.1453 8.03448L58.2586 21.0912L88.6855 51.1394L58.5569 81.6049L71.6226 94.4828L114.638 51.0201Z'
                            fill='#151315'
                          />
                          <path
                            d='M163.5 51.0201L120.007 8.03448L107.121 21.0912L137.548 51.1394L107.419 81.6049L120.485 94.4828L163.5 51.0201Z'
                            fill='#151315'
                          />
                          <path
                            d='M261.224 51.0201L304.717 8.03448L317.603 21.0912L287.177 51.1394L317.305 81.6049L304.239 94.4828L261.224 51.0201Z'
                            fill='#151315'
                          />
                          <path
                            d='M212.362 51.0201L255.855 8.03448L268.741 21.0912L238.314 51.1394L268.443 81.6049L255.377 94.4828L212.362 51.0201Z'
                            fill='#151315'
                          />
                          <path
                            d='M163.5 51.0201L206.993 8.03448L219.879 21.0912L189.452 51.1394L219.581 81.6049L206.515 94.4828L163.5 51.0201Z'
                            fill='#151315'
                          />
                        </g>
                      </svg>
                    </div>
                    {this.getCamerImg(this.state.camera)}
                  </div>
                  <div className='camera-info'>
                    <div className='info-item'>
                      <div className='item-title'>{i18n.t('camera.cameraInfoTitle')}</div>
                      {this.state.camera?.reachable ? (
                        <StaigeBadge active title='online' color='aqua' />
                      ) : (
                        <StaigeBadge active title='offline' />
                      )}
                    </div>

                    <div className='info-item'>
                      <div className='item-title'>Stitching</div>

                      {this.state.camera?.stitching === 'calibrated' ? (
                        <StaigeBadge active title={i18n.t('camera.errorFree')} color='aqua' />
                      ) : (
                        <StaigeBadge active title={i18n.t('camera.check')} />
                      )}
                    </div>
                  </div>
                  {this.state.camera?.stitching === 'calibrated' ? null : (
                    <div className='camera-quest'>{i18n.t('camera.camQuest')}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <mobiscroll.Popup ref={this.popup} display='center' buttons={[]}>
          <div className='mbsc-align-center mbsc-padding'>
            <div style={{ fontSize: '20px' }}>{i18n.t('cameraMonito.speedTest.title')}</div>
            <div style={{ margin: '20px 0 0', fontSize: '48px' }}>
              {this.state.speedTestIsRunning ? (
                <div style={{ display: 'inline-block' }}>
                  <AiswSpinner />
                </div>
              ) : (
                <div>
                  <div>{this.state.cameraNetworkSpeed ?? i18n.t('cameraMonito.speedTest.unknown')}</div>
                  <div style={{ fontSize: '28px' }}>{i18n.t('cameraMonito.speedTest.mbps')}</div>

                  {this.state.cameraNetworkSpeed &&
                  !this.state.speedTestIsRunning &&
                  this.state.cameraNetworkSpeed > 10 ? (
                    <div style={{ fontSize: '12px', marginTop: '36px' }}>
                      {i18n.t('cameraMonito.speedTest.infoText')}
                    </div>
                  ) : (
                    <div style={{ fontSize: '12px', marginTop: '36px' }}>
                      {i18n.t('cameraMonito.speedTest.infoTextNot')}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </mobiscroll.Popup>
      </PageWrapper>
    )
  }
}

export default withRouter(CameraMonitor)
