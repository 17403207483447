import Discovery, { assertContainerHasInitializedDiscovery } from './discovery'
import { VideoContainer } from './videoContainerContracts'
import { UserContainer } from './userContainer'
import { Container } from 'unstated-typescript'
import { CameraData, CameraLicense, LicenseType, Role } from '@soccerwatch/common'
import DiscoveryType from '@soccerwatch/discovery'
import { searchAndSortGetCallAs } from './serviceHelper'
import { withContainer } from './withContainerHOC'
import { ContractContainer } from './contractContainer'
import { getCameraCornerPointsAll, getCameraSystem } from '../api/api-camera'

type CameraState = {
  loadingData: boolean
  cameras: Record<string, CameraData>
  discovery?: typeof DiscoveryType
  field_names: Record<string, string[]>
  nonContractCamList: string[]
}

export class CameraContainer extends Container<CameraState> {
  contractContainer?: ContractContainer
  userContainer?: UserContainer
  videoContainer?: VideoContainer
  constructor() {
    super()

    this.state = {
      loadingData: true,
      cameras: {},
      discovery: undefined,
      field_names: {},
      nonContractCamList: []
    }

    Discovery.then((d) => {
      this.setState({ discovery: d })
    })
  }
  // Recieves the initialized ContractContainer from userContainer
  async initialize(
    contractContainer: ContractContainer,
    userContainer: UserContainer,
    videoContainer: VideoContainer
  ) {
    this.videoContainer = videoContainer
    this.contractContainer = contractContainer
    this.userContainer = userContainer
    this.contractContainer.subscribeToContractChanged(
      'CameraContainer',
      this.onContractChanged,
      this.onBeforeContractChanged
    )
    if (this.contractContainer.getHighestRelevantRoleForCurrentContract() === Role.adManager) {
      return
    }
    await this.fetchCameraDataForContract()
    await this.videoContainer.loadInitialData()
  }

  onBeforeContractChanged = (nextIndex: number, activeContract: () => number) => {
    return new Promise<void>((resolve) => {
      if (nextIndex !== activeContract()) return resolve()
      const role = this.contractContainer!.getHighestRelevantRoleForCurrentContract()
      if (role === Role.clubTagger || role === Role.adManager) {
        return resolve()
      }
      this.setState({ loadingData: true }, async () => {
        if (nextIndex !== activeContract()) return resolve()
        await this.fetchCameraDataForContract(nextIndex)
        if (nextIndex !== activeContract()) return resolve()
        this.videoContainer?.loadInitialData()
        resolve()
      })
    })
  }

  onContractChanged = () => {}

  getCameraList() {
    return Object.keys(this.state.cameras).map((key) => this.state.cameras[key])
  }

  getCameraListForCurrentContract() {
    // Calling this without index returns the List for the currently Active contract
    return this.getCameraListForContract()
  }

  getCameraListForContract(index?: number) {
    if (!this.contractContainer) {
      console.warn(
        '<CameraContainer> Could not get CameraList for current Contract: ContractContainer not Initialized'
      )
      return []
    }
    const currentContract = this.contractContainer.getContract(index)
    if (!currentContract) {
      console.warn('<CameraContainer> Could not get CameraList for current Contract: No Contracts Available')
      return []
    }
    const cameras = currentContract.cameraIds.reduce((cams, camId) => {
      if (this.state.cameras[camId]) {
        cams.push(this.state.cameras[camId])
      } else {
        if (!this.state.loadingData) {
          console.error(`<CameraContainer> CameraId ${camId} not found in State. This should not happen`)
        }
      }
      return cams
    }, [] as CameraData[])

    return cameras
  }

  getCameraRecordForCurrentContract() {
    // Calling this without index returns the Record for the currently Active contract
    return this.getCameraRecordForContract()
  }

  getCameraRecordForContract(index?: number) {
    if (!this.contractContainer) {
      if (!this.state.loadingData) {
        console.warn(
          '<CameraContainer> Could not get CameraList for current Contract: ContractContainer not Initialized'
        )
      }
      return {}
    }
    const role = this.contractContainer.getHighestRelevantRoleForCurrentContract()
    if (role === Role.clubTagger || role === Role.adManager) {
      return {}
    }
    const currentContract = this.contractContainer.getContract(index)
    if (!currentContract) {
      console.warn('<CameraContainer> Could not get CameraList for current Contract: No Contracts Available')
      return {}
    }
    const cameras = currentContract.cameraIds.reduce((cams, camId) => {
      if (this.state.cameras[camId] && !cams[camId]) {
        cams[camId] = this.state.cameras[camId]
      } else {
        if (!this.state.loadingData) {
          console.error(`<CameraContainer> CameraId ${camId} not found in State. This should not happen`)
        }
      }
      return cams
    }, {} as Record<string, CameraData>)

    return cameras
  }

  fetchCameraDataForNonContractLicences = async () => {
    // Get Cameras for "Non-Contract"-Licences
    await this.setState({ loadingData: true })
    const requiredCameras: string[] = []
    if (this.userContainer) {
      const licences = this.userContainer.state.licences
      for (let i in licences) {
        const license = licences[i]
        if (license.type === LicenseType.CameraLicense && !license.contractId) {
          const camLicense = license as CameraLicense
          if (!camLicense.swcsId && !camLicense.data.swcsId) {
            console.log('<CameraContainer> Errorlicense - Missing swcsId:', camLicense)
          }
          if (!camLicense.swcsId) {
            camLicense.swcsId = camLicense.data.swcsId
          }
          if (!requiredCameras.includes(camLicense.swcsId) && !this.state.cameras[camLicense.swcsId]) {
            requiredCameras.push(camLicense.swcsId)
          }
        }
      }
      const nonContractCameras = await this.getCamerasFromList(
        requiredCameras,
        searchAndSortGetCallAs([Role.cameraController])
      )
      const currentCameras = JSON.parse(JSON.stringify(this.state.cameras)) as Record<string, CameraData>

      await this.setState({
        cameras: { ...currentCameras, ...nonContractCameras },
        loadingData: false,
        nonContractCamList: requiredCameras
      })
    } else {
      console.error('Could not fetch CameraData without initialized UserContainer. This should not happen')
    }
  }

  async fetchCameraDataForContract(index?: number) {
    if (!this.contractContainer) {
      console.error(
        '<CameraContainer>Could not fetch CameraData without initialized ContractContainer. This should not happen'
      )
      return
    }

    const role = this.contractContainer.getHighestRelevantRoleForCurrentContract()
    if (role === Role.clubTagger || role === Role.adManager || role === Role.trainer) {
      return this.setState({ loadingData: false })
    }
    await this.setState({ loadingData: true })
    const requiredCameras: string[] = []
    const contract = this.contractContainer.getContract(index)
    if (contract) {
      contract.cameraIds.forEach((id) => {
        if (!requiredCameras.includes(id) && !this.state.cameras[id]) {
          requiredCameras.push(id)
        }
      })
    }
    const callAs = searchAndSortGetCallAs([this.contractContainer.getHighestRelevantRoleForContract(index)])
    const contractCameras = await this.getCamerasFromList(requiredCameras, callAs)
    const currentCameras = JSON.parse(JSON.stringify(this.state.cameras)) as Record<string, CameraData>
    const cameras = { ...contractCameras, ...currentCameras }
    await this.setState({ cameras, loadingData: false })
  }

  async getCamerasFromList(cameraList: string[], callAs?: string) {
    assertContainerHasInitializedDiscovery(this)
    callAs = callAs !== undefined ? callAs : ''
    if (!this.contractContainer) {
      return console.error('Contract Container not initialized. This should not happen!')
    }

    const _callAs =
      callAs ?? searchAndSortGetCallAs([this.contractContainer.getHighestRelevantRoleForCurrentContract()])

    const cameraPromises = cameraList
      .filter((cameraId) => !this.state.cameras[cameraId])
      .map(async (cameraId) => {
        let camera = await getCameraSystem(cameraId, _callAs)
        return { cameraId: cameraId, camera }
      })

    const cameraResults = await Promise.all(cameraPromises)

    const data: Record<string, CameraData> = {}
    cameraResults.forEach(({ cameraId, camera }) => {
      if (camera) {
        data[cameraId] = camera
      }
    })

    return data
  }

  getFieldNamesForCamera = async (cameraID: string) => {
    if (!cameraID || cameraID === '' || isNaN(Number(cameraID))) {
      console.error(
        `<CameraService:getFieldNamesForCamers>: Was called with empty or invalid Id "${cameraID}" by:`
      )
      return []
    }
    const ownsCamera = this.state.cameras[cameraID]
    // log error if requested corners are not for a camera owned by the user
    if (!ownsCamera) {
      console.error(
        `<CameraService:getFieldNamesForCamers>: Requested CameraId: ${cameraID} does not belong to User`
      )
      return []
    }
    if (!this.state.field_names[cameraID]) {
      const fields = await this.getFieldNamesFromApi(cameraID)
      const field_names = JSON.parse(JSON.stringify(this.state.field_names))
      field_names[cameraID] = fields
      await this.setState({ field_names })
    }
    return this.state.field_names[cameraID]
  }

  getFieldNamesFromApi = async (cameraId: string): Promise<string[]> => {
    assertContainerHasInitializedDiscovery(this)
    try {
      const res = await getCameraCornerPointsAll(cameraId)
      const fieldNames = res.data.map((set) => set.fieldName)
      return fieldNames
    } catch (err) {
      // TODO: Maybe Handle this somehow, if not 404
      console.error(err)
    }
    return []
  }

  setSingleCamera = async (cameraId: string) => {
    assertContainerHasInitializedDiscovery(this)
    if (!this.contractContainer) {
      return console.error('Contract Container not initialized. This should not happen')
    }
    await this.setState({ loadingData: true })
    const callAs = searchAndSortGetCallAs([this.contractContainer.getHighestRelevantRoleForCurrentContract()])
    let response
    try {
      response = await getCameraSystem(cameraId, callAs)
    } catch (err) {
      return console.error('Could not fetch Camera', err)
    }
    const cameras = JSON.parse(JSON.stringify(this.state.cameras))
    cameras[cameraId] = response
    await this.setState({ cameras, loadingData: false })
  }
}

const cameraContainer = new CameraContainer()
export default cameraContainer

export const withCameraContainer = withContainer(cameraContainer, 'cameraContainer')
