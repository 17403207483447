import React from 'react'
import AiswSpinner from '../helper/aiswSpinner/aiswSpinner'

import { UserContainer } from '../../services/userContainer'

type LoginWithTokenPageProps = {
  userContainer: UserContainer
  token: string
}

export default class LoginWithTokenPage extends React.Component<LoginWithTokenPageProps, unknown> {
  componentDidMount(): void {
    this.props.userContainer.signInWithToken(this.props.token)
  }
  render() {
    return (
      <div style={{ height: '100vh' }}>
        <AiswSpinner />
      </div>
    )
  }
}
