import React, { Component } from 'react'
import moment from 'moment'

import { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'
import { ClubData, Tag } from '@soccerwatch/common'
import { EVENTSETTINGS } from './eventSettings'

import './ClubInfo.scss'

type TaggingClubInfoPropsType = RouteComponentProps & {
  clubs:
    | {
        home: ClubData
        guest: ClubData
      }
    | undefined
  score: { home: number; guest: number }
  videoInfo: any
  scrollTop: boolean
  timerInformation: { timer: string; minutes: number; seconds: number } | undefined
  videoType: string
  tags: Array<Tag>
}

type TaggingClubInfoStateType = {
  allowTimeout: boolean
  homeTimeouts: Array<boolean>
  guestTimeouts: Array<boolean>
}

class ClubInfo extends Component<TaggingClubInfoPropsType, TaggingClubInfoStateType> {
  constructor(props: TaggingClubInfoPropsType) {
    super(props)
    this.state = {
      allowTimeout: false,
      homeTimeouts: [],
      guestTimeouts: []
    }
  }

  componentDidMount(): void {
    this.checkTimeouts()
  }

  componentDidUpdate(
    prevProps: Readonly<TaggingClubInfoPropsType>,
    prevState: Readonly<TaggingClubInfoStateType>,
    snapshot?: any
  ): void {
    if (this.props.tags !== prevProps.tags) {
      this.checkTimeouts()
    }
  }

  checkTimeouts = () => {
    const allowTimeout = Boolean(EVENTSETTINGS[this.props.videoType].eventTimeOut)
    let maxTimeouts: 0
    let homeTimeouts: Array<boolean> = []
    let guestTimeouts: Array<boolean> = []
    if (allowTimeout) {
      //@ts-ignore
      const eventTimeOut = EVENTSETTINGS[this.props.videoType].eventTimeOut.start
      //@ts-ignore
      maxTimeouts = EVENTSETTINGS[this.props.videoType].eventTimeOut?.start.maxTimeouts
      let tags: Array<Tag> = JSON.parse(JSON.stringify(this.props.tags)).filter(
        (tag: Tag) =>
          tag.eventType === eventTimeOut.eventTypes[0] || tag.eventType === eventTimeOut.eventTypes[1]
      )

      tags.forEach((tag) => {
        if (tag.eventType === eventTimeOut.eventTypes[0]) {
          homeTimeouts.push(true)
        }
        if (tag.eventType === eventTimeOut.eventTypes[1]) {
          guestTimeouts.push(true)
        }
      })

      for (let step = 0; step < maxTimeouts; step++) {
        if (!homeTimeouts[step]) {
          homeTimeouts.push(false)
        }
        if (!guestTimeouts[step]) {
          guestTimeouts.push(false)
        }
      }

      this.setState({
        allowTimeout: true,
        homeTimeouts: homeTimeouts,
        guestTimeouts: guestTimeouts
      })
    }
  }

  renderTimeout = (timeouts: Array<boolean>, home?: boolean) => {
    if (this.state.allowTimeout) {
      return (
        <div className={`dot-container ${home ? 'dot-containerLeft' : 'dot-containerRight'}`}>
          {timeouts.map((timeout, i) => {
            return <div key={i} className={`dot ${timeout && 'dot-active'}`}></div>
          })}
        </div>
      )
    }
  }

  render() {
    const { clubs, score, videoInfo } = this.props
    let checkVideoLive = false
    let dateTime = ''

    if (videoInfo?.expectedStartTime) {
      checkVideoLive = moment().isBetween(
        videoInfo.expectedStartTime,
        moment(videoInfo.expectedStartTime).add('minutes', videoInfo.durationMin)
      )
      dateTime = moment(new Date(videoInfo.expectedStartTime)).format('DD.MM.YYYY HH:mm')
    }

    const classProp = this.props.scrollTop ? ' clubScoreInfo-containerXXX' : ' cxcxcx'

    return (
      <>
        <div className={'clubScoreInfo-container' + classProp}>
          <div className='container-side'>
            {this.renderTimeout(this.state.homeTimeouts, true)}
            <div className='logo'>
              <img src={clubs?.home.thumbnail} />
            </div>
            <div className='name'>{clubs?.home.name}</div>
          </div>
          <div className='container-mid'>
            <div className='dateTimestamp'>
              {!this.props.timerInformation ? dateTime : this.props.timerInformation.timer}
            </div>
            <div className='score'>
              {score.home}-{score.guest}
            </div>
            <div className='state'>{checkVideoLive ? 'LIVE' : 'BEENDET'}</div>
          </div>
          <div className='container-side'>
            {this.renderTimeout(this.state.guestTimeouts, false)}
            <div className='logo'>
              <img src={clubs?.guest.thumbnail} />
            </div>
            <div className='name'>{clubs?.guest.name}</div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(ClubInfo)
