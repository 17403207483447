import Discovery, { assertContainerHasInitializedDiscovery } from './discovery'
import UserLogin from './userContainer'
import { Container } from 'unstated-typescript'
import DiscoveryType from '@soccerwatch/discovery'
import { Paywall, FreeTicket } from '@soccerwatch/common'
import { getPaywallManage } from '../api/api-additional'

type CameraState = {
  loadingData: boolean
  paywallRawData: Array<Paywall>
  paywallFreeTickets: Array<FreeTicket>
  discovery?: typeof DiscoveryType
}

export class PaywallContainer extends Container<CameraState> {
  discovery?: typeof DiscoveryType
  private currentMatchId?: number

  constructor() {
    super()

    this.state = {
      loadingData: false,
      paywallRawData: [],
      paywallFreeTickets: [],
      discovery: undefined
    }

    Discovery.then((d) => {
      this.setState({ discovery: d })
      if (UserLogin.state.loggedIn) {
        this.initialize()
      }
    })
  }

  initialize() {}

  refreshData = () => {
    if (this.currentMatchId) {
      return this.setPayWallPackagesByMatchId(this.currentMatchId)
    }
  }

  setPayWallPackagesByMatchId = async (id: number) => {
    this.currentMatchId = id

    assertContainerHasInitializedDiscovery(this)

    await this.setState({ loadingData: true })
    let response = await getPaywallManage(id)

    let freeTickets = response.filter((itm) => {
      if (itm.type === 'free') {
        if (itm.list.length > 0) {
          return itm
        }
      }
    })

    await this.setState({
      loadingData: false,
      paywallRawData: response,
      paywallFreeTickets: freeTickets[0] && freeTickets[0].list ? freeTickets[0].list : []
    })

    return response
  }
}

const paywallContainer = new PaywallContainer()
export default paywallContainer
