import React, { Component } from 'react'
import EventTap from './EventTap'
import mobiscroll from '@mobiscroll/react'

import { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'
import { AiTag, ClubData, EVENTTYPES, Role, Tag } from '@soccerwatch/common'

import './EventList.scss'
import { Popup } from '@mobiscroll/react5'
import i18n from '../../../languages/i18n'
import { searchAndSortGetRoles } from '../../../services/serviceHelper'
import contractContainer from '../../../services/contractContainer'
import { deleteTagById } from '../../../api/api-tag'

type EventListContainerPropsType = RouteComponentProps & {
  tags: Array<any>
  liveTagging?: boolean
  playerCurrentTime: number
  videoType: string
  clubs:
    | {
        home: ClubData
        guest: ClubData
      }
    | undefined
  handleSeekTo: (time: number) => void
  postTag: (body: any, matchId: string, tagId?: string, removeTimerDisabled?: boolean) => void
  reloadTags: () => void
}

type EventListContainerStateType = {
  timeEvents: boolean
  filterInfo: string | undefined
  deletePopupIsOpen: boolean
  deleteTagInfo: Tag | undefined
}

class EventListContainer extends Component<EventListContainerPropsType, EventListContainerStateType> {
  // Constructor
  constructor(props: EventListContainerPropsType) {
    super(props)
    this.state = {
      timeEvents: false,
      filterInfo: undefined,
      deletePopupIsOpen: false,
      deleteTagInfo: undefined
    }
  }

  handleTimeEventMenu = (value: boolean) => {
    this.setState({
      timeEvents: value
    })
  }

  filterCondition = (tag: any) => {
    return (
      tag.startClock ||
      tag.stopClock ||
      tag.startClockWithSeconds !== undefined ||
      tag._startClock ||
      tag._stopClock ||
      tag._startClockWithSeconds !== undefined
    )
  }

  sortTagsTimeOrNormal = (originalTags: Array<Tag | AiTag>) => {
    const tags: Array<any> = []
    originalTags.forEach((res) => {
      const findEvent = EVENTTYPES.filter((event) => event.eventType === res.eventType)[0]
      tags.push({
        ...res,
        _startClockWithSeconds: findEvent?.startClockWithSeconds,
        _startClock: findEvent?.startClock,
        _stopClock: findEvent?.stopClock
      })
    })

    const sortTimeTags = tags // this.props.tags
      .filter((obj) => this.filterCondition(obj))
      .sort((a, b) => a.timestamp - b.timestamp)

    const sortNormalTags = tags // this.props.tags
      .filter((obj) => !this.filterCondition(obj))
      .sort((a, b) => a.timestamp - b.timestamp)
    return { timeTags: sortTimeTags, normalTags: sortNormalTags }
  }

  handleQuickButton = (value: string | undefined) => {
    this.setState({
      filterInfo: value
    })
  }

  handleDeletePopup = (tag: Tag | undefined) => {
    this.setState({
      deletePopupIsOpen: !this.state.deletePopupIsOpen,
      deleteTagInfo: tag
    })
  }

  deleteTag = async (tag: Tag) => {
    // for callAs
    const callAs = searchAndSortGetRoles([
      Role.admin,
      contractContainer.getHighestRelevantRoleForCurrentContract()
    ])
    //

    return deleteTagById(tag.PartitionKey, tag.RowKey, callAs).then((res) => {
      this.props.reloadTags()
    })
  }

  handleDeleteTag = (tag: Tag) => {
    this.deleteTag(tag)
    this.setState({
      deletePopupIsOpen: false,
      deleteTagInfo: undefined
    })
  }

  render() {
    const { timeEvents, filterInfo } = this.state
    let { timeTags, normalTags } = this.sortTagsTimeOrNormal(this.props.tags)

    normalTags = normalTags.filter((itm) => {
      if (filterInfo === undefined) return true
      if (filterInfo === 'Home' && itm.club === 'Home') return true
      if (filterInfo === 'Guest' && itm.club === 'Guest') return true
      return false
    })

    return (
      <div className='eventList-container'>
        <Popup
          isOpen={this.state.deletePopupIsOpen}
          themeVariant={'dark'}
          buttons={[
            {
              text: i18n.t('general.no'),
              handler: () => this.handleDeletePopup(undefined)
            },
            {
              text: i18n.t('general.yes'),
              handler: () =>
                this.state.deleteTagInfo ? this.handleDeleteTag(this.state.deleteTagInfo) : null
            }
          ]}
        >
          <div style={{ padding: '20px 0' }}>
            <div
              style={{ display: 'flex', justifyContent: 'center', paddingBottom: '18px', fontSize: '22px' }}
            >
              {this.state.deleteTagInfo?.name}
            </div>
            <div>{i18n.t('tagging.tagList.deleteWarningText')}</div>
          </div>
        </Popup>

        <div className='container-eventMenu'>
          <div>
            <a
              onClick={() => (timeEvents ? this.handleTimeEventMenu(false) : null)}
              className={!timeEvents ? 'active' : ''}
            >
              {i18n.t('tagging.tagList.eventListMenu.gameTime')}
              {/*<span>{normalTags.length}</span>*/}
            </a>
          </div>
          <div>
            <a
              onClick={() => (!timeEvents ? this.handleTimeEventMenu(true) : null)}
              className={timeEvents ? 'active' : ''}
            >
              {i18n.t('tagging.tagList.eventListMenu.timeEvents')}
              {/*<span>{timeTags.length}</span>*/}
            </a>
          </div>
        </div>
        <div className='container-eventList'>
          <div className='eventList-wrapper'>
            {timeEvents ? (
              <>
                <div className='eventList-box'>
                  {timeTags.map((tag, index) => {
                    let img
                    if (tag.club === 'Home') {
                      img = this.props.clubs?.home.thumbnail
                    } else if (tag.club === 'Guest') img = this.props.clubs?.guest.thumbnail

                    return (
                      <EventTap
                        key={index}
                        tag={tag}
                        iconSrc={img}
                        handleSeekTo={this.props.handleSeekTo}
                        playerCurrentTime={this.props.playerCurrentTime}
                        postTag={this.props.postTag}
                        handleDeletePopup={this.handleDeletePopup}
                      />
                    )
                  })}
                </div>
              </>
            ) : (
              <>
                <div className='aa'>
                  <div className='ssss'>
                    <mobiscroll.Button
                      className={filterInfo === undefined ? 'round small full' : 'round small'}
                      onClick={() => this.handleQuickButton(undefined)}
                      style={{ minWidth: 70 }}
                    >
                      {i18n.t('tagging.tagList.eventListMenu.all')}
                    </mobiscroll.Button>
                    <mobiscroll.Button
                      className={filterInfo === 'Home' ? 'round small full' : 'round small'}
                      onClick={() => this.handleQuickButton('Home')}
                      style={{ minWidth: 70 }}
                    >
                      {i18n.t('tagging.tagList.eventListMenu.home')}
                    </mobiscroll.Button>
                    <mobiscroll.Button
                      className={filterInfo === 'Guest' ? 'round small full' : 'round small'}
                      onClick={() => this.handleQuickButton('Guest')}
                      style={{ minWidth: 70 }}
                    >
                      {i18n.t('tagging.tagList.eventListMenu.guest')}
                    </mobiscroll.Button>
                  </div>
                </div>
                <div className='eventList-box'>
                  {normalTags.map((tag, index) => {
                    let img
                    if (tag.club === 'Home') {
                      img = this.props.clubs?.home.thumbnail
                    } else if (tag.club === 'Guest') img = this.props.clubs?.guest.thumbnail

                    return (
                      <>
                        <EventTap
                          key={index}
                          tag={tag}
                          iconSrc={img}
                          handleSeekTo={this.props.handleSeekTo}
                          playerCurrentTime={this.props.playerCurrentTime}
                          postTag={this.props.postTag}
                          handleDeletePopup={this.handleDeletePopup}
                        />
                      </>
                    )
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(EventListContainer)
