export default class TimeMachine {
  date: Date

  constructor(date?: Date) {
    this.date = date || new Date()
  }

  add(value: number, type: 'min' | 'hours') {
    const number = type === 'min' ? value * 60000 : value * 60 * 60 * 1000
    this.date = new Date(this.date.getTime() + number)
    return this.date
  }

  subtract(value: number, type: 'min' | 'hours') {
    const number = type === 'min' ? value * 60000 : value * 60 * 60 * 1000
    this.date = new Date(this.date.getTime() - number)
    return this.date
  }
}
