import React, { useEffect, useState } from 'react'
import i18n from 'i18next'

import { useChangeHandlers } from '../EditSponsors'
import { InstreamAd, Role } from '@soccerwatch/common'
import { ContractContainer } from '../../../services/contractContainer'
import { Select, localeDe } from '@mobiscroll/react5'
import { ClubContainer } from '../../../services/clubContainer'
import { checkLanguages } from '../../helper/checkLanguages/checkLanguages'

type GeneralAdInfoEditorProps = {
  advertisement: InstreamAd
  contractContainer: ContractContainer
  clubContainer: ClubContainer
  onChange: (newAd: InstreamAd) => void
}

export const BasedType = (props: GeneralAdInfoEditorProps) => {
  const { setArrayProperty } = useChangeHandlers(
    {
      ...props.advertisement
    },
    props.onChange
  )

  const setClubs = setArrayProperty('enabledClubs')

  const [matchBasedAllow, setMatchBasedAllow] = useState<boolean>(() => {
    const highestRelevantRoles = props.contractContainer.getHighestRelevantRolesForCurrentContract()
    if (
      highestRelevantRoles.includes(Role.contractOwner) ||
      highestRelevantRoles.includes(Role.cameraController)
    ) {
      return true
    }
    return false
  })
  const [matchBased, setMatchBased] = useState<boolean>(matchBasedAllow)
  const [tempClubs, setTempClubs] = useState<Array<string> | undefined>(undefined)

  useEffect(() => {
    if (props.advertisement.enabledClubs && props.advertisement.enabledClubs.length > 0) {
      setMatchBased(false)
    }
  }, [])

  useEffect(() => {
    if (matchBased) {
      setTempClubs(props.advertisement.enabledClubs)
      setClubs({ value: [] })
    } else {
      if (tempClubs) {
        setClubs({ value: tempClubs })
      }
    }
  }, [matchBased])
  //

  return (
    <div className='bottom-container'>
      <div className='container-title'>{i18n.t('sponsors.basedType.title')}</div>

      <div className='container-based'>
        {matchBasedAllow && (
          <div
            className={`container-basedItem ${matchBased ? 'container-basedItemActive' : ''}`}
            onClick={() => setMatchBased(true)}
          >
            <div className='basedItem-main'>
              <div className='main-content'>
                <div className='content-a'>{i18n.t('sponsors.basedType.gameBased')}</div>
                <div className='content-b'>{i18n.t('sponsors.basedType.gameBasedInfo')}</div>
              </div>
              <div className='main-checkbox'>
                <div className='checkbox-active' />
              </div>
            </div>
            <div className='basedItem-bottom'></div>
          </div>
        )}

        <div
          className={`container-basedItem ${!matchBased ? 'container-basedItemActive' : ''}`}
          onClick={() => setMatchBased(false)}
        >
          <div className='basedItem-main'>
            <div className='main-content'>
              <div className='content-a'>{i18n.t('sponsors.basedType.clubBased')}</div>
              <div className='content-b'>{i18n.t('sponsors.basedType.clubBasedInfo')}</div>
            </div>
            <div className='main-checkbox'>
              <div className='checkbox-active' />
            </div>
          </div>
          <div className='basedItem-bottom'>
            <Select
              locale={checkLanguages() === 'de' ? localeDe : 'undefined'}
              label={'Verein'}
              display='center'
              itemHeight={60}
              themeVariant='dark'
              theme='ios'
              data={props.clubContainer.getClubListForCurrentContract().map((res) => {
                return { value: res.RowKey, text: res.name }
              })}
              selectMultiple
              value={props.advertisement.enabledClubs}
              onChange={setClubs}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

BasedType.defaultProps = {}
