import React, { useCallback } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import RecordingPlan from '../recordingPlan/RecordingPlan'
import { Subscribe } from 'unstated-typescript'
import VideoContainer from '../../services/videoContainerContracts'
import CameraContainer from '../../services/cameraContainer'
import ClubContainer from '../../services/clubContainer'
import TeamContainer from '../../services/teamContainer'
import PaywallContainer from '../../services/paywallContainer'
import MatchEditor from '../recordingPlan/Overviews/MatchEditor'
import Recording from '../recordingPlan/Recording'
import UserContainer from '../../services/userContainer'
import CityContainer from '../../services/cityContainer'
import AdvertisementContainer from '../../services/advertisementContainer'
import CompetitionContainer from '../../services/competitionContainer'
import contractContainer from '../../services/contractContainer'
import { RouteNames } from '../menu/Menu'

export type RoutingPlanRoutesProps = {
  routeFilter: string[]
  reroutePath: string
}

const MatchEditorComponent = (props: any) => {
  const location = useLocation()
  return <MatchEditor goBack={location.state} {...props} /> // your component
}

const RecordingComponent = (props: any) => {
  const location = useLocation()
  return <Recording goBack={location.state} {...props} /> // your component
}

export const RecordingPlanRoutes = (props: RoutingPlanRoutesProps) => {
  if (props.routeFilter && !props.routeFilter.includes(RouteNames.RecordingPlan)) {
    return <Redirect to={props.reroutePath} />
  }
  const history = useHistory()
  const match = useRouteMatch()
  const onCloseOverlay = useCallback(() => {
    history.push('/recordingplan')
  }, [])

  return (
    <Switch>
      <Subscribe
        to={[
          ClubContainer,
          CameraContainer,
          VideoContainer,
          PaywallContainer,
          UserContainer,
          TeamContainer,
          CityContainer,
          CompetitionContainer,
          AdvertisementContainer
        ]}
      >
        {(
          clubContainer,
          cameraContainer,
          videoContainer,
          paywallContainer,
          userContainer,
          teamContainer,
          cityContainer,
          competitionContainer,
          advertisementContainer
        ) => (
          <>
            <Route path={`${match.path}/new`} exact>
              <MatchEditorComponent
                competitionContainer={competitionContainer}
                videoContainer={videoContainer}
                teamContainer={teamContainer}
                cityContainer={cityContainer}
                clubContainer={clubContainer}
                contractContainer={contractContainer}
                contractsChanging={contractContainer.state.loadingData}
                allDataLoaded={
                  !contractContainer.state.loadingData &&
                  !teamContainer.state.loadingData &&
                  !clubContainer.state.loadingData
                }
                cameras={cameraContainer.getCameraRecordForCurrentContract()}
                videoData={videoContainer.getVideosForCurrentContract()}
                editId={undefined}
                onCloseOverlay={onCloseOverlay}
                reloadData={videoContainer.reloadVideoFullList}
                reloadVideo={videoContainer.setVideoById}
                clubs={clubContainer.state.clubs}
                paywallContainer={paywallContainer}
                userContainer={userContainer}
                history={history}
              />
            </Route>
            <Route
              path={`${match.path}/new/:start/:end`}
              exact
              render={({ match }) => (
                <MatchEditor
                  competitionContainer={competitionContainer}
                  videoContainer={videoContainer}
                  teamContainer={teamContainer}
                  cityContainer={cityContainer}
                  clubContainer={clubContainer}
                  contractContainer={contractContainer}
                  contractsChanging={contractContainer.state.loadingData}
                  allDataLoaded={
                    !contractContainer.state.loadingData &&
                    !teamContainer.state.loadingData &&
                    !clubContainer.state.loadingData
                  }
                  cameras={cameraContainer.state.cameras}
                  videoData={videoContainer.getVideosForCurrentContract()}
                  editId={undefined}
                  onCloseOverlay={onCloseOverlay}
                  reloadData={videoContainer.reloadVideoFullList}
                  reloadVideo={videoContainer.setVideoById}
                  clubs={clubContainer.state.clubs}
                  paywallContainer={paywallContainer}
                  createDate={match.params}
                  userContainer={userContainer}
                  history={history}
                />
              )}
            />
            <Route
              path={`${match.path}/video/:id`}
              exact
              render={({ match }) => (
                <RecordingComponent
                  id={parseInt(match.params.id ?? '')}
                  videoContainer={videoContainer}
                  clubContainer={clubContainer}
                  cameraContainer={cameraContainer}
                  paywallContainer={paywallContainer}
                  userContainer={userContainer}
                  teamContainer={teamContainer}
                  cityContainer={cityContainer}
                  competitionContainer={competitionContainer}
                  contractContainer={contractContainer}
                  allDataLoaded={
                    !contractContainer.state.loadingData &&
                    !teamContainer.state.loadingData &&
                    !clubContainer.state.loadingData
                  }
                  advertisementContainer={advertisementContainer}
                />
              )}
            />

            <Route
              path={`${match.path}/edit/:editId`}
              exact
              render={({ match }) => (
                <MatchEditorComponent
                  competitionContainer={competitionContainer}
                  teamContainer={teamContainer}
                  videoContainer={videoContainer}
                  clubContainer={clubContainer}
                  cityContainer={cityContainer}
                  contractContainer={contractContainer}
                  contractsChanging={contractContainer.state.loadingData}
                  allDataLoaded={
                    !contractContainer.state.loadingData &&
                    !teamContainer.state.loadingData &&
                    !clubContainer.state.loadingData
                  }
                  cameras={cameraContainer.getCameraRecordForCurrentContract()}
                  videoData={videoContainer.getVideosForCurrentContract()}
                  editId={match.params.editId}
                  onCloseOverlay={onCloseOverlay}
                  reloadData={videoContainer.reloadVideoFullList}
                  reloadVideo={videoContainer.setVideoById}
                  clubs={clubContainer.state.clubs}
                  paywallContainer={paywallContainer}
                  userContainer={userContainer}
                  history={history}
                />
              )}
            />
            <Route path={`${match.path}`} exact>
              <RecordingPlan
                cameraContainer={cameraContainer}
                videoContainer={videoContainer}
                contractContainer={contractContainer}
                clubContainer={clubContainer}
                userContainer={userContainer}
                teamContainer={TeamContainer}
              />
            </Route>
          </>
        )}
      </Subscribe>
    </Switch>
  )
}
