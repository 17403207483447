import * as Discovery from '@soccerwatch/discovery'
import * as Helper from '../helper/GlobalHelper'

export const postSignedUrlGeneric = async (body: {
  folderName: string
  fileName: string
  contentType: string
}) => {
  return await Helper.apiPost<{ signedUrl: string }>(
    `${Discovery.API_MEDIAUPLOAD}/signedUrl/genricUpload/`,
    body
  )
}
