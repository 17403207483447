import React, { ChangeEvent } from 'react'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import * as mobiscrollV5 from '@mobiscroll/react5'
import { checkLanguages } from '../../helper/checkLanguages/checkLanguages'
import { ClubContainer } from '../../../services/clubContainer'
import { FileInput } from '../../../helper/FileInput'
import Jimp from 'jimp'

import './clubForm.scss'
import { CityContainer } from '../../../services/cityContainer'
import CityForm from '../cityForm/cityForm'
import FilteredSelect from '../../helper/filteredSelect/filteredSelect'
import { CityData } from '../../../types/CityData'
import { ClubData } from '@soccerwatch/common'
import AiswSpinner from '../../helper/aiswSpinner/aiswSpinner'

type ClubFormProps = {
  id?: string
  disabled?: boolean
  clubContainer: ClubContainer
  cityContainer: CityContainer
  open: boolean
  onClose?: (club?: ClubData) => void
}
type ClubFormState = {
  name: string
  loading: boolean
  searchName: string
  color: string
  thumbnail: string
  league: string
  zip: string
  latitude?: string
  longitude?: string
  city?: CityData
  cityId: string
  location: string
  hasSystem: boolean
  hasCategory: boolean
  commercialReady: boolean
  imagePreview?: string
  cityFormOpen: boolean
}

const EmptyState = {
  loading: false,
  name: '',
  searchName: '',
  color: '',
  league: '',
  zip: '',
  thumbnail: '',
  latitude: undefined,
  longitude: undefined,
  city: undefined,
  cityId: '',
  location: '',
  hasCategory: false,
  hasSystem: false,
  commercialReady: false,
  imagePreview: '',
  cityFormOpen: false
}

export default class ClubForm extends React.Component<ClubFormProps, ClubFormState> {
  constructor(props: ClubFormProps) {
    super(props)
    this.state = { ...EmptyState }
  }

  componentDidMount() {}

  closeModal = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  handleTextPropertyChanged = (key: keyof ClubFormState) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    this.setState({ ...this.state, [key]: value })
  }

  handleColorChanged = (event: { valueText?: string | undefined }) => {
    const color = event.valueText
    if (color) {
      this.setState({ color })
    }
  }

  handleCancleClick = () => {
    this.setState(EmptyState)
    this.closeModal()
  }
  validateInput = () => {
    return (
      this.state.name !== '' &&
      this.state.zip !== '' &&
      this.state.thumbnail !== '' &&
      this.state.city !== undefined &&
      this.state.location !== ''
    )
  }

  handleSaveClick = async () => {
    if (this.validateInput()) {
      // TODO: Save ClubData
      this.setState({ loading: true })
      // Save Image to sw-sc-de-shared  Bucket
      // Set ImageUrl to thumbnailUrl
      // Update ClubData
      const body = {
        name: this.state.name,
        color: this.state.color,
        zip: this.state.zip,
        thumbNailRaw: this.state.thumbnail,
        city: this.state.city?.name,
        // @ts-ignore
        cityId: this.state.city?.RowKey ?? this.state.city?.id,
        location: this.state.location
      }

      const newClub = await this.props.clubContainer.createClub(body)
      this.props.onClose && this.props.onClose(newClub)
      this.setState(EmptyState)
    }
  }

  handleImageContentChanged = async (content?: string) => {
    if (content) {
      const imageData = content.substring(content.indexOf('base64,') + 'base64,'.length)
      const image = await Jimp.read(Buffer.from(imageData, 'base64'))
      const thumb = await this.applyFilter(image, 'contain', 300, 300)
      this.setState({ imagePreview: thumb, thumbnail: thumb })
    } else {
      console.error('Image Change but no Content could be found. This should not happen')
    }
  }

  async applyFilter(image: Jimp, methode: string, width: number, height: number) {
    const ratio = image.bitmap.width / image.bitmap.height
    const desiredRatio = width / height

    if (methode === 'auto') {
      methode = desiredRatio / ratio > 1.0 ? 'cover' : 'contain'
    }
    if (methode === 'cover') {
      image = await image.cover(width || 400, height || 400)
    } else {
      image = await image.contain(
        width || 400,
        height || 400,
        Jimp.HORIZONTAL_ALIGN_CENTER | Jimp.VERTICAL_ALIGN_MIDDLE
      )
    }
    const rawData = await image.getBase64Async(Jimp.MIME_PNG)

    return rawData
  }

  openCityForm = () => {
    this.setState({ cityFormOpen: true })
  }

  closeCityForm = (city?: CityData) => {
    if (city) {
      this.setState({ city, cityFormOpen: false })
    } else {
      this.setState({ cityFormOpen: false })
    }
  }

  onChangeCity = (city: CityData) => {
    if (city) {
      // @ts-ignore
      const cityId = city.RowKey || city.id
      this.setState({
        city: city,
        cityId: cityId
      })
    } else {
      console.error('Selected City has no ID. Something went horribly Wrong O_o...')
    }
  }

  render() {
    const lang = checkLanguages()
    return (
      <div className='form clubForm'>
        <mobiscrollV5.Popup
          isOpen={this.props.open}
          onClose={this.closeModal}
          theme='ios'
          themeVariant='dark'
        >
          <div style={{ margin: '0 -16px' }}>
            <mobiscroll.FormGroupTitle>
              <h2 style={{ marginTop: 0 }}>{i18n.t('forms.club.title')}</h2>
            </mobiscroll.FormGroupTitle>
            <div className='mbsc-grid mbsc-no-padding' style={{ marginTop: '-24px' }}>
              <div className='mbsc-row'>
                <div className='mbsc-col mbsc-col-6 left'>
                  <mobiscroll.Input
                    onChange={this.handleTextPropertyChanged('name')}
                    type='text'
                    placeholder={i18n.t('general.name')}
                    lang={lang}
                    value={this.state.name}
                    disabled={this.state.loading}
                  >
                    {i18n.t('general.name')}
                  </mobiscroll.Input>

                  <mobiscroll.Input
                    onChange={this.handleTextPropertyChanged('zip')}
                    type='text'
                    placeholder={i18n.t('forms.club.zip')}
                    lang={lang}
                    value={this.state.zip}
                    disabled={this.state.loading}
                  >
                    {i18n.t('forms.club.zip')}
                  </mobiscroll.Input>
                </div>
                <div className='mbsc-col mbsc-col-6 right'>
                  <FilteredSelect
                    urlParameters={`&kind=skyhook-beta-cityall&fuzzy=true&match=name`}
                    label={i18n.t('forms.club.city')}
                    onChange={this.onChangeCity}
                    value={this.state.city}
                    disabled={this.state.loading}
                    onNewEntryNeeded={this.openCityForm}
                    newItemLabel={i18n.t('forms.city.newCity')}
                  />
                  <mobiscroll.Input
                    onChange={this.handleTextPropertyChanged('location')}
                    type='text'
                    placeholder={i18n.t('forms.club.locationPlaceholder')}
                    lang={lang}
                    value={this.state.location}
                    disabled={this.state.loading}
                  >
                    {i18n.t('forms.club.location')}
                  </mobiscroll.Input>

                  <div className='mbsc-col ' style={{ marginTop: -5 }}>
                    <FileInput
                      accept={'image/*'}
                      disabled={this.state.loading}
                      onFileContentChange={this.handleImageContentChanged}
                    >
                      {`${i18n.t('forms.club.thumbnail')}`}
                    </FileInput>
                  </div>

                  {this.state.imagePreview !== '' && (
                    <div className='mbsc-col' style={{ textAlign: 'center' }}>
                      <img className='clubThumb' src={this.state.imagePreview}></img>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <mobiscroll.FormGroup style={{ marginBottom: '10px' }}>
              <div className='mbsc-grid'>
                <div className='mbsc-row'>
                  <div className='mbsc-col mbsc-col-xl-6' style={{ paddingRight: '0.3em' }}>
                    <mobiscroll.Button
                      onClick={this.handleCancleClick}
                      className={'round blue fullWidth'}
                      disabled={this.state.loading}
                      style={{ height: '36px', padding: '0', lineHeight: '0' }}
                    >
                      {i18n.t('general.cancel')}
                    </mobiscroll.Button>
                  </div>
                  <div className='mbsc-col mbsc-col-xl-6' style={{ paddingLeft: '0.3em' }}>
                    <mobiscroll.Button
                      onClick={this.handleSaveClick}
                      className={'round blue fullWidth'}
                      disabled={this.state.loading || !this.validateInput()}
                      style={{ height: '36px', padding: '0', lineHeight: '0' }}
                    >
                      {this.state.loading ? (
                        <div style={{ height: '18px', width: '18px', marginLeft: '46%' }}>
                          <AiswSpinner />
                        </div>
                      ) : (
                        i18n.t('general.save')
                      )}
                    </mobiscroll.Button>
                  </div>
                </div>
              </div>
            </mobiscroll.FormGroup>
          </div>
        </mobiscrollV5.Popup>
        <CityForm
          cityService={this.props.cityContainer}
          open={this.state.cityFormOpen}
          onClose={this.closeCityForm}
        />
      </div>
    )
  }
}
