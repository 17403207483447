import React, { Component } from 'react'

import { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'
import { AiTag, ClubData, Tag } from '@soccerwatch/common'
import { checkLanguages } from '../../helper/checkLanguages/checkLanguages'

import './EventTap.scss'

type EventListContainerPropsType = RouteComponentProps & {
  tag: Tag | AiTag
  handleSeekTo: (time: number) => void
  playerCurrentTime: number
  club?: ClubData
  iconSrc?: string
  postTag: (body: any, matchId: string, tagId?: string, removeTimerDisabled?: boolean) => void
  handleDeletePopup: (tag: Tag | undefined) => void
  className?: string
  renderButton?: any
}

type EventListContainerStateType = {
  timeEvents: boolean
}

class EventListContainer extends Component<EventListContainerPropsType, EventListContainerStateType> {
  // Constructor
  constructor(props: EventListContainerPropsType) {
    super(props)
    this.state = {
      timeEvents: true
    }
  }

  render() {
    const { tag } = this.props
    // @ts-ignore
    const name = checkLanguages() === 'de' ? tag.name : tag.nameEn
    const active =
      tag.timestamp <= this.props.playerCurrentTime && tag.timestamp >= this.props.playerCurrentTime - 2

    const totalSeconds = Math.floor(tag.timestamp)
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60
    const formattedSeconds = seconds.toString().padStart(2, '0')
    const formattedTime = `${minutes}:${formattedSeconds}`

    return (
      <div
        className={
          !active
            ? `container-eventTap ${this.props.className}`
            : `container-eventTap container-eventTapActive ${this.props.className} ${this.props.className}Active`
        }
        onClick={() => this.props.handleSeekTo(tag.timestamp)}
      >
        {this.props.iconSrc ? (
          <div className='eventTap-icon'>
            <img src={this.props.iconSrc} />
          </div>
        ) : null}

        <div className='eventTap-info'>
          <div className='info-time'>{formattedTime}</div>
          <div className='info-name'>{name}</div>
        </div>
        <div className='eventTap-quickBtn'>
          {this.props.renderButton ? (
            this.props.renderButton()
          ) : (
            <>
              <div
                className='smallIcon-button'
                onClick={(e) => {
                  this.props.postTag({ level: tag.level === 1 ? 0 : 1 }, tag.PartitionKey, tag.RowKey)
                  e.stopPropagation()
                }}
              >
                {tag.level === 1 ? (
                  <span className='mbsc-ic mbsc-ic-material-star' />
                ) : (
                  <span className='mbsc-ic mbsc-ic-material-star-border' />
                )}
              </div>
              {/*<div className='smallIcon-button'  onClick={(e) => e.stopPropagation()}>
                <span className='mbsc-ic mbsc-ic-fa-edit' />
              </div>*/}
              <div
                className='smallIcon-button smallIcon-buttonWarn'
                onClick={(e) => {
                  this.props.handleDeletePopup(tag)
                  e.stopPropagation()
                }}
              >
                <span className='mbsc-ic mbsc-ic-fa-trash-o' />
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(EventListContainer)
