import React, { useState } from 'react'
import { ReactNodeLike } from 'prop-types'
import mobiscroll from '@mobiscroll/react'

type FileInputProps = {
  accept: string
  validate?: (file?: File, fileContent?: string) => Promise<string | undefined>
  onFileChange?: (file?: File) => void
  onFileContentChange?: (fileContent?: string) => void
  children?: ReactNodeLike
  placeholder?: string
  disabled?: boolean
  file?: File
}

export const FileInput = (props: FileInputProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const file = evt.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async function (readerEvent) {
        const content = readerEvent.target?.result
        const newErrorMessage = await props.validate?.(file, content?.toString())
        if (newErrorMessage === undefined) {
          props.onFileChange?.(file)
          props.onFileContentChange?.(content?.toString())
        }

        setErrorMessage(newErrorMessage)
      }
    } else {
      props.onFileChange?.(file)
      props.onFileContentChange?.(undefined)
    }
  }

  return (
    <div className='img-upload' style={{ padding: '0 1em' }}>
      <mobiscroll.Input
        type={'file'}
        disabled={props.disabled}
        value={props.file?.name ?? ''}
        onChange={onChange}
        accept={props.accept ?? undefined}
        valid={errorMessage === undefined}
        errorMessage={errorMessage}
        placeholder={props.placeholder}
        style={{ margin: '20px 20px' }}
      >
        {props.children}
      </mobiscroll.Input>
    </div>
  )
}
