import React from 'react'
import mobiscroll, { Select } from '@mobiscroll/react'
import AiswSpinner from '../pages/helper/aiswSpinner/aiswSpinner'
import iconCalendar from '../img/icons/menu/calendar.svg'
import iconWindow from '../img/icons/menu/window.svg'
import iconGear from '../img/icons/menu/gear.svg'
import iconCamera from '../img/icons/menu/camera.svg'
import iconContracts from '../img/icons/menu/contracts.svg'

import { ContractContainer } from '../services/contractContainer'
import { RouteNames, menuFilter } from '../pages/menu/Menu'

import './Navbar.scss'
import { ClubContainer } from '../services/clubContainer'

type NavBarPropTypes = {
  onContractChange: any
  contractContainer: ContractContainer
  routeFilter: string[]
  clubContainer: ClubContainer
}

type NavBarStateTypes = {
  rerender: boolean
  rerenderState: boolean
}

export default class NavBar extends React.Component<NavBarPropTypes, NavBarStateTypes> {
  constructor(props: NavBarPropTypes) {
    super(props)

    this.state = {
      rerender: false,
      rerenderState: false
    }
  }

  private refSelect = React.createRef<mobiscroll.PopupComponent>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps: NavBarPropTypes) {
    const roleFilter = menuFilter[this.props.contractContainer.getHighestRelevantRoleForCurrentContract()]
    const navSize = roleFilter === undefined ? 4 : roleFilter.length

    if (navSize !== 4 && navSize !== undefined && !this.state.rerender) {
      this.setState({ rerender: true, rerenderState: true })
      setTimeout(() => {
        this.setState({ rerenderState: false })
      }, 10)
    }
  }

  render() {
    const { routeFilter } = this.props
    const currentContract = this.props.contractContainer.getContract()
    const lockContractSelect = this.props.contractContainer.state.lockContractSelect
    const contractList = this.props.contractContainer.state.contracts
    const contractCointainerShow = contractList.length > 1

    const roleFilter = routeFilter
    let navSize = contractCointainerShow ? 5 : 4

    // rerender nav: the value is only changed for 10ms so that the component renders
    if (this.state.rerenderState) {
      return null
    }

    let clubLogo = ''
    let moreClubs = false
    if (currentContract && currentContract.clubIds.length > 1) {
      clubLogo = iconContracts
      moreClubs = true
    } else {
      this.props.clubContainer.getClubListForCurrentContract().map<void>((club, index) => {
        if (index === 0) {
          clubLogo = club.thumbnail
        }
      })
    }

    return (
      <div className='navbar'>
        <div
          className='navbar-contractInfo'
          style={moreClubs || !contractCointainerShow ? { display: 'none' } : {}}
        >
          CONTRACT:
          <Select
            filter={this.props.contractContainer.state.contracts.length > 5}
            disabled={lockContractSelect}
            value={currentContract?.RowKey}
            onSet={this.props.onContractChange}
            display='bottom'
            ref={this.refSelect}
            animate={'slideup'}
            label='Wähle ein Contract aus'
            showLabel
            cssClass='contractSelect'
          >
            {this.props.contractContainer.state.contracts.map((c, i) => (
              <option key={c.RowKey} value={c.RowKey}>
                {c.name}
              </option>
            ))}
          </Select>
        </div>

        <mobiscroll.BottomNav className='navbar-bottom' layout={navSize} type='bottom' key={navSize}>
          <mobiscroll.NavItem
            disabled={roleFilter && !roleFilter.includes(RouteNames.RecordingPlan)}
            to='/recordingplan'
            exact
          >
            <img src={iconCalendar} style={{ height: '24px', margin: '-6px' }} />
          </mobiscroll.NavItem>
          <mobiscroll.NavItem
            disabled={roleFilter && !roleFilter.includes(RouteNames.Advertisement)}
            to='/advertisement'
            exact
          >
            <img src={iconWindow} style={{ height: '24px', margin: '-6px' }} />
          </mobiscroll.NavItem>

          {contractCointainerShow ? (
            <mobiscroll.NavItem
              onClick={() => {
                if (this.refSelect.current && this.props.contractContainer.state.contracts.length > 1) {
                  this.refSelect.current.instance.show()
                }
              }}
            >
              {this.props.contractContainer.state.loadingData && (
                <div style={{ alignSelf: 'center' }}>
                  <AiswSpinner />
                </div>
              )}
              {!this.props.contractContainer.state.loadingData && (
                <img
                  src={clubLogo}
                  style={{ height: '32px', margin: '-6px', width: '32px', alignSelf: 'center' }}
                />
              )}
            </mobiscroll.NavItem>
          ) : null}

          <mobiscroll.NavItem
            disabled={roleFilter && !roleFilter.includes(RouteNames.CameraMonitor)}
            to='/cameramonitor'
            exact
          >
            <img src={iconCamera} style={{ height: '24px', margin: '-6px' }} />
          </mobiscroll.NavItem>
          <mobiscroll.NavItem
            disabled={roleFilter && !roleFilter.includes(RouteNames.Administration)}
            to={'/administration'}
            exact
          >
            <img src={iconGear} style={{ height: '24px', margin: '-6px' }} />
          </mobiscroll.NavItem>
        </mobiscroll.BottomNav>
      </div>
    )
  }
}
