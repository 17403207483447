import React from 'react'
import './circleLoader.scss'

type circleLoaderProps = {
  className?: string
  spinning: boolean
}

export const CircleLoader = (props: circleLoaderProps) => {
  return (
    <div
      className={`circle-loader ${props.className ? props.className : ''} ${
        props.spinning ? '' : 'load-complete'
      }`}
    >
      <div className='checkmark draw' style={{ display: props.spinning ? 'none' : 'block' }} />
    </div>
  )
}

export default CircleLoader
